(function () {
    'use strict';

    class FlowsOnboardingWizardTemplateReadyController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope
            ,$injector
            ,UsersManager
            ,ModalService
            ,$translate
            ,Enums
            ,$state
            ,FlowService
            ,OnboardingService
            ,UiPersistenceService
            ,DeviceService
            ,UIUtils
            ,AbTestService
            ,IntentService
            ,moment
            ) {
            super($scope, $injector);
            this.__objectType = 'FlowsOnboardingWizardTemplateReadyController';

            this.UsersManager = UsersManager;

            this.ModalService = ModalService;
            this.$translate = $translate;
            this.Enums = Enums;
            this.$state = $state;
            this.FlowService = FlowService;
            this.OnboardingService = OnboardingService;
            this.UiPersistenceService = UiPersistenceService;
            this.DeviceService = DeviceService;
            this.UIUtils = UIUtils;
            this.AbTestService = AbTestService;
            this.user = UsersManager.getCurrUser();
            this.abTestIntentInfraVariation = this.user.getExistingAbTestVariation(this.Enums.ABTests.intentInfra);
            this.IntentService = IntentService;
            this.moment = moment;

            this.isContractOnboarding = this.IntentService.shouldGetContractOnboarding();
            this.userIntent = IntentService.getAccountIntent();
            this.isWithIntentAndInVariant = this.isContractOnboarding; 
        }

        gotoSelectedFlow() {
            // I wanted mweb and desktop to call the same function
            this.onSubmitStep({ step: this.currentStep });
        }

        $onInit() {
            this.isDoneButtonClicked = false;
            this.user = this.UsersManager.getCurrUser();
            this.$scope.$on('submitOnboardingStep', this.onComplete.bind(this));
            this._selectedFlowTemplateImageUrl = '';
            this.isMobile = this.DeviceService.nxSmallBreakpoint();

            this.title = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._TITLE_${this.isMobile ? 'MWEB_' : ''}`);
            this.subtitle = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._SUB_TITLE_${this.isMobile ? 'MWEB_' : ''}`);
            this.ctaText = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._CTA_TEXT_`);

            if (this.isContractOnboarding && (this.isBlankTemplate || this.getDataFromUIPersistence('isBlankTemplate'))) {
                this.title = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._TITLE_BLANK_${this.isMobile ? 'MWEB_' : ''}`);
                this.subtitle = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._SUB_TITLE_BLANK_${this.isMobile ? 'MWEB_' : ''}`);
            }

            this.t1ActivationABCVariant = this.OnboardingService.getT1ActivationABCVariant();
            this.isAbcVariantControl = this.t1ActivationABCVariant === 'control' || !this.t1ActivationABCVariant;

            this.setFlowInstanceId(this.flowInstanceId || this.getDataFromUIPersistence('flowInstanceId'));
            this.setFlowImageUrl(this.selectedFlowTemplateImageUrl || this.getDataFromUIPersistence('selectedFlowTemplateImageUrl'));
            this.setSelectedFlowTemplateId(this.selectedFlowTemplateId  || this.getDataFromUIPersistence('selectedFlowTemplateId'));

            this.unwatchIsDoneButtonClicked = this.$scope.$watch('$ctrl.isDoneButtonClicked', (isClicked, wasClicked) => {
                if (isClicked && !wasClicked && this._flowInstanceId) {
                    // this handle edge case where isDoneButtonClicked changed to true AFTER _flowInstanceId was set.
                    // in that case complete step on $onChanges won't happen. so this covers that extreme edge case.
                    this.completeStep();
                }
            });

            this.$scope.$on('$destroy', () => {
                this.unwatchIsDoneButtonClicked();
            });

        }
        
        getDataFromUIPersistence(key) {
            let persistKey = this.Enums.flowsOnboardingWizardSteps.templates;
            
            switch (this.t1ActivationABCVariant) {
                case 'skip_template':
                    persistKey = this.Enums.flowsOnboardingWizardSteps.services
                    break;
                case 'legacy_like':
                    persistKey = this.Enums.flowsOnboardingWizardSteps.legacyServices
                    break;
                default:
                    break;
            }
 
            const storedData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.flowsOnboarding, {});
            return storedData && storedData[persistKey] && storedData[persistKey][key]
        }

        setSelectedFlowTemplateId(id) {
            this._selectedFlowTemplateId = id;
        }

        setFlowInstanceId(id) {
            this._flowInstanceId = id;
        }

        setFlowImageUrl(url) {
            if (this.UIUtils.isUrl(url)) {
                this._selectedFlowTemplateImageUrl = this.UIUtils.getCloudinaryPathFromCloudinaryUrl(url);
            } else {
                this._selectedFlowTemplateImageUrl = url;
            }
        }

        $onChanges(changes) {
            if (changes.flowInstanceId && changes.flowInstanceId.currentValue) {
                this.setFlowInstanceId(changes.flowInstanceId.currentValue)
                if (this.isDoneButtonClicked) {
                    this.completeStep();
                }
            }

            if (changes.selectedFlowTemplateImageUrl && changes.selectedFlowTemplateImageUrl.currentValue) {
                this.setFlowImageUrl(changes.selectedFlowTemplateImageUrl.currentValue);
            }

            if (changes.selectedFlowTemplateId && changes.selectedFlowTemplateId.currentValue) {
                this.setSelectedFlowTemplateId(changes.selectedFlowTemplateId.currentValue);
            }
            if (this.isContractOnboarding && !!changes.isBlankTemplate) {
                if (!!changes.isBlankTemplate.currentValue) {
                    this.title = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._TITLE_BLANK_${this.isMobile ? 'MWEB_' : ''}`);
                    this.subtitle = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._SUB_TITLE_BLANK_${this.isMobile ? 'MWEB_' : ''}`);
                } else {
                    this.title = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._TITLE_${this.isMobile ? 'MWEB_' : ''}`);
                    this.subtitle = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._SUB_TITLE_${this.isMobile ? 'MWEB_' : ''}`);
                }
            }
        }

        completeStep() {
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.flowsOnboarding, null);

            var trialStartedDate = this.moment(this.user.getTrial().start_date.split('T')[0]);
            var startABTestDate = this.moment('2022-12-14').utc();

            var goToPage = this.IntentService.shouldGetContractOnboarding() ? 'contract' : undefined;
            if (this.isMobile) {
                this.FlowService.goToPreview(this._flowInstanceId);
            } else if (this.IntentService.isInvoiceIntentBehavior() && startABTestDate.diff(trialStartedDate, 'days') < 1) {
                this.FlowService.goToFlow(this._flowInstanceId, null, null, { builderTourIntentVariation: 'intent_builder', isFromOnboarding: true });
            } else {
                this.FlowService.goToFlow(this._flowInstanceId, null, null, { isFromOnboarding: true, page: goToPage });
            }

            this.OnboardingService.completeOnboardingStep(this.currentStep);
        }

        onComplete(event, currStep) {
            if (currStep.key === this.thisStepKey) {

                if (!this._flowInstanceId) {
                    // if flowInstanceId was not set yet
                    // show loader and do nothing
                    // $onChanges will call completeStep once ready
                    this.isDoneButtonClicked = true;

                    // this is here to disable the cta button on mweb after click
                    this.isStepValid = false;
                } else {
                    this.completeStep();
                }
            }
        }



    }

    Components.FlowsOnboardingWizardTemplateReady = {
        controller: FlowsOnboardingWizardTemplateReadyController,
        bindings: {
            currentStep: '<',
            thisStepKey: '@',
            flowInstanceId: '<',
            isStepValid: '=',
            selectedFlowTemplateImageUrl: '<',
            isBlankTemplate: '<',
            stepTranslationMap: '<',
            selectedFlowTemplateId: '<',
            onSubmitStep: '&',
            onboardingType: '<',
            onboardingAbTest: '<',
        },
        templateUrl: 'angular/app/modules/core/features/onboarding/flows_onboarding/flows_onboarding_wizard/steps/template_ready/flows_onboarding_wizard_template_ready.html',

    };
}());
