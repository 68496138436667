(function () {
    'use strict';

    Services.FlowsBetaUserTypeService = class FlowsBetaUserTypeService {
        // @ngInject
        constructor(UsersManager, FeaturesService, StatsigService, WebsocketHelperService, Enums, $q, PubSubService) {
            this.hasFlows = true;
            this.hasFiles = false;
            this.hasOnlyFlows = true;
            this.hasOnlyFiles = false;
            this.hasNewFlowExp = false;
            this.hasFilesAndFlows = false;

            this.UsersManager = UsersManager;
            this.FeaturesService = FeaturesService;
            this.StatsigService = StatsigService;
            this.WebsocketHelperService = WebsocketHelperService;
            this.Enums = Enums;
            this.$q = $q;
            this.refreshFlowBetaUserType();
            this.WebsocketHelperService.registerToRoom(this.UsersManager.getCurrUser()._id + ".splits_updated", this.splitsUpdated.bind(this));
            PubSubService.ventMyBitchUp(this);
            this.UsersManager.on('loggingIn', this.refreshFlowBetaUserType.bind(this));
            this.UsersManager.on('loginLess', this.refreshFlowBetaUserType.bind(this));
            this.UsersManager.on('resetPsw', this.refreshFlowBetaUserType.bind(this));
            this.UsersManager.on(this.Enums.PubSubTriggers.startedTrial, this.refreshFlowBetaUserType.bind(this));
            this.UsersManager.on('loggingOut', this.onLogoutCleanup.bind(this));
            if (this.UsersManager.isLoggedIn()) {
                this.setIsPublicFilesUser();
            }
        }

        refreshFlowBetaUserType() {
            const currUser = this.UsersManager.getCurrUser();

            this.hasFlows = currUser.isFlagEnabled('enable_flow_flag');
            this.hasFiles = !currUser.isFlagEnabled('block_files_flag');
            this.hasOnlyFlows = !this.hasFiles;
            this.hasNewFlowExp = this.hasFlows;
            this.hasFilesAndFlows = this.hasFlows && this.hasFiles;
            this.hasOnlyFiles = !this.hasFlows && this.hasFiles;
        }

        onLogoutCleanup() {
            this.hasFlows = false;
            this.hasFiles = false;
            this.hasOnlyFlows = false;
            this.hasNewFlowExp = false;
            this.hasFilesAndFlows = false;
            this.hasOnlyFiles = false;
        }

        splitsUpdated() {
            this.UsersManager.forceFetchCurrUser().then(function success() {
                this.refreshFlowBetaUserType();
            }.bind(this));
        }

        setIsPublicFilesUser() {
            const user = this.UsersManager.getCurrUser();
            if (!user.isVendor()) {
                return false;
            }

            return this.hasFlows;

        }
    };
})();
