(function () {
    'use strict';

    // @ngInject
    function NewAutomationsControllerCtor(
        $scope,
        $injector,
        $state,
        Enums,
        AppStates,
        StatsigService
    ) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'NewAutomationsController';
        this.AppStates = AppStates;
        this.$state = $state;
        this.StatsigService = StatsigService;
        this.Enums = Enums;

        this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.newAutomations,  'new_experience', false)
            .then(function (variant) {
                if (!variant) {
                    this.$state.go(AppStates.root_core_navigation_automations);
                }
            }.bind(this));
    }

    Controllers.NewAutomationsController = Class(Controllers.BaseController, {
        constructor: NewAutomationsControllerCtor,
    });
}());
