(function () {
    "use strict";

    const FINANCE_APP_IFRAME_ID = "finance-app-iframe";

    // @ngInject
    function FinanceAppServiceCtor(AppConfigService, RepositoryService, Constants, $window, moment, FinanceAppManager, ModalService, PopupMessageService, SplashScreenService, LoginTwoFactorAuthService, $q, $state, AppStates, FeaturesService, StatsigService, $injector) {
        this.AppConfigService = AppConfigService;
        this.RepositoryService = RepositoryService;
        this.Constants = Constants;
        this.$window = $window;
        this.moment = moment;
        this.FinanceAppManager = FinanceAppManager;
        this.ModalService = ModalService;
        this.PopupMessageService = PopupMessageService;
        this.SplashScreenService = SplashScreenService;
        this.LoginTwoFactorAuthService = LoginTwoFactorAuthService;
        this.$q = $q;
        this.$state = $state;
        this.AppStates = AppStates;
        this.FeaturesService = FeaturesService;
        this.StatsigService = StatsigService;
        this.$injector = $injector;

        this.modalListener = null;
    }

    Services.FinanceAppService = Class(() => {

        return {
            constructor: FinanceAppServiceCtor,

            isFinanceAppEnabledWithoutUser() {
                const UsersManager = this.$injector.get('UsersManager');
                const promise = new Promise((resolve, reject) => {
                    const user = UsersManager.getCurrUser();

                    if (!user) {
                        user.on('success', () => {
                            this.isFinanceAppEnabled(user).then((value) => {  
                                resolve(value);
                             }, () => {
                                reject();
                             });
                        }, this);
                    } else {
                        this.isFinanceAppEnabled(user).then((value) => {  
                            resolve(value);
                         }, () => {
                            reject();
                         });
                    }
                });

                return promise;
                
            },

            isFinanceAppEnabled(user) {
                if (!this._getUrl()) {
                    return Promise.resolve(false);
                }
                return this._isUserEligible(user);
            },

            getIframeUrl() {
                return `${this._getUrl()}/auth/token`;
            },

            createLoginToken: function (user) {
                return this.FinanceAppManager.createLoginToken();
            },

            _isUserEligible(user) {
                if (!user || user.isBookkeeperMode() || !user.isCompanyOwner()) {
                    return Promise.resolve(false);
                }
                if(user.__financeAppEnabled) {
                    return Promise.resolve(true);
                }
                return this.StatsigService.isGateEnabled('finance_app').then((res) => {
                    if (res) {
                        user.__financeAppEnabled = true;
                        return true;
                    }
                    return false;
                }).catch(() => {
                    return false;
                });
            },

            loginToFinanceAsync: function (tokenPromise, financeAppLoadPromise, user) {
                Promise.all([tokenPromise, financeAppLoadPromise]).then((values) => {
                    const token = values[0].data.authentication_token;
                    this.loginFinanceApp(user, token);
                }).catch(() => {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNEXPECTED_');
                });
            },

            loadFinanceApp(user) {
                const financeAppLoadPromise = this._listenToEvent('finance-app-rendered');
                const tokenPromise = this.createLoginToken(user);

                this.loginToFinanceAsync(tokenPromise, financeAppLoadPromise, user);
                this.listenToModal();
            },

            loginFinanceApp(user, token) {
                if (token) {
                    this._postLoginMessage(user, token);
                } else {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNEXPECTED_');
                }
            },

            getIframeId() {
                return FINANCE_APP_IFRAME_ID;
            },

            goToFinanceApp() {
                this.$state.go(this.AppStates.root_core_navigation_finance);
            },

            _getUrl() {
                return this.AppConfigService.appConfiguration().finance_app_url;
            },

            _listenToEvent(eventName) {
                const defer = this.$q.defer();
                this.$window.addEventListener('message', (message) => {
                    if (message.data.event === eventName) {
                        defer.resolve();
                    }
                });
                return defer.promise;
            },

            listenToSignOut() {
                return this._listenToEvent('finance-app-sign-out');
            },

            listenToModal() {
                if (this.modalListener === null) {
                    this.modalListener = (message) => {
                        if (message.data.event === 'finance-app-modal') {
                            const financeSection = this.$window.document.querySelector('section.finance');
                            financeSection.classList.toggle('btf', message.data.payload.isOpen);
                        }
                    };
                    this.$window.addEventListener('message', this.modalListener);
                }
            },

            _postLoginMessage(user, authToken) {
                this._postMessageToIframe('hb-finance-login', {
                    token: authToken,
                    userId: user._id,
                    companyId: user.company._id,
                    admin: user.isAdminLogin(),
                });
            },

            _postMessageToIframe(event, payload) {
                const iframe = angular.element(`#${FINANCE_APP_IFRAME_ID}`)[0];
                iframe.contentWindow.postMessage({
                    event: event,
                    payload: payload
                }, '*');
            }
        };
    });
}());

