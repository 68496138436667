(function () {
    'use strict';

    Services.OtamStatusService = class OtamStatusService {
        // @ngInject
        constructor(UsersManager, FeaturesService, UiPersistenceService, moment, FlowsBetaUserTypeService,
                    ToastService, PendingTasksManager, PopupMessageService, $state, AppStates, AppConfigService) {
            this.UsersManager = UsersManager;
            this.PendingTasksManager = PendingTasksManager;
            this.PopupMessageService = PopupMessageService;
            this.ToastService = ToastService;
            this.rolloutFeatures = FeaturesService.rolloutFeatures;
            this.UiPersistenceService = UiPersistenceService;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
            this.moment = moment;
            this.$state = $state;
            this.AppStates = AppStates;
            this.AppConfigService = AppConfigService;
        }

        initializeAfterLogin() {
            this.currUser = this.UsersManager.getCurrUser();

            const testEnv = this.AppConfigService.isTest() || this.AppConfigService.isE2E();
            this.hasOtamVariation = this.currUser.account && this.currUser.account.otam_group && !testEnv;
            this.isListenerOn = false;
            this.isMigrationEnabled = this.currUser.account && this.currUser.account.otam_group && !testEnv;
        }

        migrateUserToFlows() {
            this.currUser.triggerOtamMigratedToFlows();
            this.stage = 'duringMigration';
            this.lastFile = '';
            this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.otamMigrateUser,
                {user_id: this.currUser._id}).then(
                this.onCompleteMigration.bind(this),
                (error) => {
                    this.AnalyticsService.trackError(this, 'failed to migrate user to flows', error);
                    this.dismiss();
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'Upgrade failed. Please refresh the page and try again.', null, 'ok');
                },
                (progress) => {
                    this.onMigrationUpdate(progress);
                });
        }

        listenToMigration(){
            if(this.isMigrationEnabled && (!this.FlowsBetaUserTypeService.hasFlows || this.stage === 'duringMigration') && !this.isListenerOn){
                this.isListenerOn = true;
                this.PendingTasksManager.joinPendingTask(
                    'otam_migrate_user',
                    this.onCompleteMigration.bind(this),
                    this.onMigrationUpdate.bind(this),
                    this.showInProgressToast.bind(this),
                );
            }
        }

        isTeamMember(){
            return !this.currUser.isCompanyOwner();
        }

        updateInProgressToast(text){
            this.ToastService.updateToast(text, this.ToastId);
        }

            onMigrationUpdate(progress) {
            this.stage = 'duringMigration';
            if (progress) {
                if (progress.last_file) {
                    this.lastFile = progress.last_file;
                }

                this.migrationsCount = this.migrationsCount || progress.migrations_count;
                this.filesCount = this.filesCount || progress.files_count;
                const completionPercent = progress.finished_migrations_count / this.migrationsCount;

                this.completedFilesCount = Math.floor(completionPercent * this.filesCount);

                this.updateInProgressToast(this.completedFilesCount + ' of '+ this.filesCount + ' copied...');
            }
        }

        showInProgressToast(){
            this.stage = 'duringMigration';
            if(!this.ToastId){
                this.ToastId = this.ToastService.showInfo({
                    title: `Copying your file templates into the new smart file builder`,
                    iconCssClass: 'icon loading loading_hb loader-xs left-spinner',
                    contentTranslation: `copying...`,
                    dismissButton: false,
                    dismissOnTimeout: false
                });
            }
        }

        isMidMigration(){
            return this.stage === 'duringMigration';
        }

        closeInProgressToast(){
            if(this.ToastId){
                this.ToastService.dismiss(this.ToastId);
            }
        }

        onCompleteMigration(){
            var self = this;
            this.closeInProgressToast();
            this.stage = 'finishMigration';
            var message = 'Successfully copied all ' + this.filesCount + ' of your templates. You can now view and edit your templates with the new smart file builder.'
            this.PopupMessageService.showAlertWithTitle(this.PopupMessageService.severityTypes.success, message, 'Coping complete', ()=>{
                self.UsersManager.forceFetchCurrUser().then(() => {
                    self.FlowsBetaUserTypeService.refreshFlowBetaUserType();
                    if(self.$state.current.name === self.AppStates.root_core_navigation_my_templates){
                        self.$state.reload();
                    }
                });}, 'FREQUENT_BUTTONS._GOT_IT_',' hb-popup-bright-success');
        }

        isMigrated(){
            return this.FlowsBetaUserTypeService.hasFiles && this.FlowsBetaUserTypeService.hasFlows;
        }

        isMigrationEnabledExp() {
            this.currUser = this.UsersManager.getCurrUser();
            const testEnv = this.AppConfigService.isTest() || this.AppConfigService.isE2E();
            this.isMigrationEnabled = this.currUser.account && this.currUser.account.otam_group && !testEnv;
            const hasOnlyFiles = this.FlowsBetaUserTypeService && !this.FlowsBetaUserTypeService.hasFlows;
            return (hasOnlyFiles && this.isMigrationEnabled && this.stage !== 'duringMigration');
        }

        shouldSeePreOtamModal(){
            return this.isMigrationEnabledExp() && this.shouldSeeElement('migrationModalOpen' ,1);
        }

        shouldSeeElement(name, count = 5){
            var otamData = this.UiPersistenceService.getUiPersistence(
                this.UiPersistenceService.keys.otamMigration,
                {}
            );
            return !otamData[name] || typeof(otamData[name]) === 'boolean' || otamData[name] < count;
        }

        markElementAsSeen(name) {
            this.userData = this.UiPersistenceService.getUiPersistence(
                this.UiPersistenceService.keys.otamMigration,
                {}
            );
            var isFieldExist = this.userData.hasOwnProperty(
                name
            );
            if (!isFieldExist || (isFieldExist && typeof(this.userData[name]) === "boolean")){
                this.userData[name] = 0;
            }
            this.userData[name] = this.userData[name] + 1;
            this.UiPersistenceService.setUiPersistence(
                this.UiPersistenceService.keys.otamMigration,
                this.userData
            );
        }
    };
})();
