(function () {
    "use strict";

    // @ngInject
    function SelectEmailProviderControllerCtor($scope, $injector, $modalInstance, $filter, moment, AnalyticsService, PopupMessageService,
                                               UserService, UsersManager, $sce, $window, $log, $timeout, integrationType) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SelectEmailProviderController';
        this.modalInstance = $modalInstance;

        this.moment = moment;
        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.UserService = UserService;
        this.UsersManager = UsersManager;
        this.$log = $log;
        this.$sce = $sce;
        this.$window = $window;
        this.$timeout = $timeout;
        this.gettingEmailAddress = true;
        this.requestedProviderName = null;
        this.integrationType = integrationType;
        this.isEmailIntegration = integrationType === 'email';
        this.isCalendarIntegration = integrationType === 'calendar';

        this.modalTitle = this.isEmailIntegration ? "Email integration" : "Connect your calendar";
        this.emailInputPlaceholder = this.isEmailIntegration ? "Enter email address" : "Enter your iCloud or Outlook email";

        if (this.isEmailIntegration) {
            this.integrationDescription = "Connect an email address to your HoneyBook account.";
            this.helpCenterBaseUrl = "https://help.honeybook.com/en/articles/2308785-integrating-your-email-provider-with-honeybook";
        } else {
            this.integrationSubTitle = "Save time & stay organized";
            this.integrationDescription = "Connect a Google, iCloud, or Outlook calendar to your HoneyBook calendar.";
            this.helpCenterBaseUrl = "https://help.honeybook.com/en/articles/2209101-syncing-your-external-calendar-to-honeybook";
        }

        this.integrationTypes = {
            MANDRILL: 'mandrill',
            GMAIL: 'gmail',
            NYLAS: 'nylas'
        };
    }

    Controllers.SelectEmailProviderController = Class(Controllers.BaseController, {

        constructor: SelectEmailProviderControllerCtor,

        close: function cancel() {
            this.modalInstance.dismiss();
        },

        checkEmailAddressProvider: function checkEmailAddressProvider() {
            if (!this.requestedEmailAddress) {
                return;
            }

            this.errorMessage = null;
            this.showDesignatedPasswordHelp = false;
            this.loadingProvider = true;
            this.addingEmailIntegration = false;
            this.requestedProviderName = null;

            this.UsersManager.getEmailProviderFromNylas(this.requestedEmailAddress, this.integrationType).then(
                function success(resp) {

                    if (resp && resp.data && resp.data.provider_name) {
                        this.requestedProviderName = resp.data.provider_name;
                        this.requestedProviderFriendlyName = this.setProviderFriendlyName(this.requestedProviderName);
                    }
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.email_integration_received_provider, {requested_email: this.requestedEmailAddress, provider_name: this.requestedProviderName});
                    this.gettingEmailAddress = false;
                    this.startSequencePerProvider();
                }.bind(this),
                function error(res) {
                    if (res && res.data && res.data.error_type && res.data.error_type === 'HBUserError') {
                        this.errorMessage = res.data.error_message;
                    } else {
                        this.$log.error(res.data.error_message);
                        this.errorMessage = 'ACCOUNT.EMAIL_INTEGRATION.ERRORS._PROVIDER_ERROR_';
                    }
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.email_integration_failed_provider, error, {requested_email: this.requestedEmailAddress});
                }.bind(this))
                .catch(function saveContactError(resp) {

                }.bind(this))
                .finally(function finallyCleaning() {
                this.loadingProvider = false;
            }.bind(this));
        },

        startNewPasswordRequiredSequence: function startNewPasswordRequiredSequence() {
            this.setHelpCenterLinkUrl();
            this.modalTitle = "App Password";
            this.showDesignatedPasswordHelp = true;
        },

        setHelpCenterLinkUrl: function setHelpCenterLinkUrl() {
            this.appPasswordHelpCenterUrl = this.helpCenterBaseUrl;
            switch (this.requestedProviderName.toLowerCase()) {
                case 'yahoo':
                    this.appPasswordHelpCenterUrl = this.helpCenterBaseUrl + (this.isEmailIntegration ? "#h_f1cbd791dc" : "");
                    break;
                case 'outlook':
                case 'eas':
                    this.appPasswordHelpCenterUrl = this.helpCenterBaseUrl + (this.isEmailIntegration ? "#h_f704011136" : "#h_ca3d5c5ab0");
                    break;
                case 'icloud':
                    this.appPasswordHelpCenterUrl = this.helpCenterBaseUrl + (this.isEmailIntegration ? "#h_84c550f152" : "#h_48cbe635c9");
                    break;
                case 'aol':
                    this.appPasswordHelpCenterUrl = this.helpCenterBaseUrl + (this.isEmailIntegration ? "#h_aaa6019736" : "");
                    break;
                default:
                    this.appPasswordHelpCenterUrl = this.helpCenterBaseUrl;
                    break;
            }
        },

        setProviderFriendlyName: function setProviderFriendlyName(providerName) {
            switch (providerName.toLowerCase()) {
                case 'gmail':
                    return 'Gmail';
                case 'yahoo':
                    return 'Yahoo';
                case 'eas':
                    return 'Office365';
                case 'outlook':
                    return 'Outlook';
                case 'icloud':
                    return 'iCloud';
                case 'aol':
                    return 'AOL';
                default:
                    return providerName.charAt(0).toUpperCase() + providerName.slice(1);
            }
        },

        startSequencePerProvider: function startSequencePerProvider() {
            if (!this.requestedProviderName) {
                // For now, manually allow members to integrate unknown providers
                this.startNylasEmailIntegration();
            }
            else if (this.requestedProviderName.indexOf("gmail") !== -1) {
                this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning, 'The requested email address was identified as Google account. Continue with Google integration?',
                    function clickPrimaryCTA() {
                        this.addGoogleEmailIntegration();
                    }.bind(this),
                    function clickSecondaryCTA() {
                        this.requestedProviderName = null;
                        this.requestedProviderName = "unknown";
                        this.designatedPasswordHelpText = "iCloud, Microsoft and 2FA enabled accounts are required to generate a new app password to connect their account to HoneyBook.";
                        this.startNewPasswordRequiredSequence();
                    }.bind(this).bind(this),
                    "Google",
                    "Not Google"
                );
            } else if (this.requestedProviderName.indexOf("yahoo") !== -1 || this.requestedProviderName.indexOf("aol") !== -1 || this.requestedProviderName.indexOf("icloud") !== -1) {
                this.designatedPasswordHelpText = this.requestedProviderFriendlyName + " users are required to generate a new app password to connect their account to HoneyBook.";
                this.startNewPasswordRequiredSequence();
            } else {
                this.startNylasEmailIntegration();
            }
        },

        startNylasEmailIntegration: function startNylasEmailIntegration() {
            this.showDesignatedPasswordHelp = false;

            if (!this.addingEmailIntegration) {
                this.modalTitle = "Redirecting...";
                this.showRedirectMessage = true;

                this.$timeout(function(){
                    this.showRedirectMessage = false;
                    this.addingEmailIntegration = true;
                    this.UserService.connectToNylasAccount(this.$window.location.href, this.integrationType, this.requestedProviderName, this.requestedEmailAddress);
                }.bind(this), 3000);
            }
        },

        addGoogleEmailIntegration: function addGoogleEmailIntegration($event) {
            if (!this.adddingGoogleIntegration) {
                this.gettingEmailAddress = false;
                this.adddingGoogleIntegration = true;

                this.$timeout(function(){
                    this.UserService.connectToAGoogleAccount(null, this.integrationTypes.GMAIL);
                }.bind(this), 500);
            }
        },

        addGoogleCalendarIntegration: function addGoogleCalendarIntegration() {
            this.modalInstance.close({isGoogle: true});
        }
    });
}());

