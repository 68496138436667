(function () {
    "use strict";

    // @ngInject
    function CompanySettingsQuickbooksControllerCtor($scope, $injector,StatsigService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanySettingsQuickbooksController';
        this.isNewDashboardEnabled =  false;

        this.StatsigService = StatsigService;
        this.isNewDashboardEnabled = this.StatsigService.isGateEnabled('quickbooks_remastered').then(function (result){
            this.isNewDashboardEnabled = result;
        }.bind(this));
    }


    Controllers.CompanySettingsQuickbooksController = Class(Controllers.BaseController, {

        constructor: CompanySettingsQuickbooksControllerCtor,

    });
}());
