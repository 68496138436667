(function () {
    "use strict";

    Directives.CompanyBrandingDirective =  function CompanyBrandingDirective() {

        // @ngInject
        function CompanyBrandingDirectiveControllerCtor($scope, $injector, AnalyticsService,
            ModalService, UsersManager, CompaniesManager, UiPersistenceService,
            OnboardingService, ImageService, ngToast, $timeout, $element,
            $sce, _, AppStates, CompanyService, FeaturesService, FlowsBetaUserTypeService, StatsigService) {

            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'CompanyBrandingDirectiveController';

            this.CompaniesManager = CompaniesManager;
            this.UsersManager = UsersManager;
            this.CompanyService = CompanyService;
            this.AnalyticsService = AnalyticsService;
            this.ModalService = ModalService;
            this.UiPersistenceService = UiPersistenceService;
            this.OnboardingService = OnboardingService;
            this.ngToast = ngToast;
            this.$timeout = $timeout;
            this.$sce = $sce;
            this._ = _;
            this.AppStates = AppStates;
            this.$scope = $scope;
            this.ImageService = ImageService;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
            this.$element = $element;
            this.isColorPickerOpen = false;
            this.colorPickerClassName = 'brand-hb-color-picker';
            this.isLogoDeletable = false
            this.isIconDeletable = false

            this.user = UsersManager.getCurrUser();
            this.company = CompaniesManager.getCurrCompany();

            AnalyticsService.reportEvent('ViewBranding');

            this.showBrandColorTooltip = this.isColorTooLight(this.company.brand_color);

            this.previewImage = {
                file: '//res.cloudinary.com/honeybook/image/upload/v1553611525/honeybook_cdn/company_settings/branding/visual_widget/brand-preview-new.svg',
            };

            this.showBrandingPopover = false;
            this.showAdditionalImagesPopover = false;

            this.featuredImage = this.company.getDefaultCoverImage();

            if (this.company.cover_images) {

                this.coverImages = this.company.cover_images;
                this.imageryList = this.coverImages.slice(-4).reverse();

                if (!this.featuredImage) {
                    this.featuredImage = this.getFeatureImage(this.coverImages);
                }
            }

            this.isCompanyLoaded = false;
            if (this.company.wasFullyFetched()) {
                this.gotCompany();
            }
            this.register(this.company, 'success', this.gotCompany);
        }

        var CompanyBrandingDirectiveController = Class(Controllers.BaseController, {
            constructor: CompanyBrandingDirectiveControllerCtor,

            gotCompany: function gotCompany() {
                this.isCompanyLoaded = true;
                this.companyTheme = this.company.company_flow_theme;
                this.company.description = this._.unescape(this.company.description);
                this.company.about = this._.unescape(this.company.about);
            },

            /**
             * Saves the changes done in the company model to the server
             */
            saveChanges: function saveChanges(force) {
                var self = this;

                var validations = this.$scope.overviewForm;
                if ((force || validations.$dirty) && validations.$valid) {
                    var promise = this.CompaniesManager.saveCompanyFields(this.company, null, this.useMalkutPath);
                    promise.then(function success(){
                        // Clear validations
                        validations.$setPristine();
                        this.showBrandColorTooltip = this.isColorTooLight(this.company.brand_color);

                        var analyticsArgs = {
                            company: self.company._id
                        };

                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.company_updated, analyticsArgs);
                    }.bind(this));
                }
            },
            onDeleteCompanyLogos: function onDeleteCompanyLogos(ev,logoType){
                ev.stopPropagation()
                this.CompaniesManager.deleteCompanyIconOrLogo(this.company,logoType)
            },
            shoudlShowTrashIcon: function shoudlShowTrashIcon(logoType){
                this.isIconDeletable = (this.company.icon_image.url !== "https://res.cloudinary.com/honeybook/image/upload/v1447330842/honeybook_cdn/assets_users_production/companies/53a66c933237c59a08001fcd/icons/2014-07-30-10-44-34-163.jpg" && logoType === "company-icon")
                this.isLogoDeletable = (this.company.logo_image.url !== "https://res.cloudinary.com/honeybook/image/upload/v1447332405/honeybook_cdn/assets_users_production/companies/53a66c933237c59a08001fcd/logos/2014-07-30-10-44-33-055.jpg" && logoType === "company-logo")
            },
            changeLogoPhoto: function changeLogoPhoto() {
                this.ModalService.openUploadLogoPhotoModal(this.company);
            },

            changeIconPhoto: function changeIconPhoto() {
                this.ImageService.uploadCompanyIcon(this.company);
            },

            changeDefaultHeaderPhoto: function changeDefaultHeaderPhoto() {
                var defaultImageId = this.company.default_cover_image_id;

                var options = {
                    selectedImage: this.featuredImage,
                    imageDisplayRatio: 3,
                    showPreviewWhenCroppingDisplay: true,
                    skipSavingToServer: false,
                    title: 'MEDIA_MODAL.TITLE._EDIT_COVER_HEADER_',
                    model: ''
                };

                this.ImageService.chooseImage(options).then(
                    function success(newImage) {
                        if (defaultImageId !== newImage._id) {
                            this.CompaniesManager.setDefaultCoverImage(this.company, newImage._id);
                            // immediately update the company model
                            this.company.default_cover_image_id = newImage._id;
                            this.featuredImage = newImage;
                            this.CompanyService.triggerCompanyBrandingUpdated();
                        }
                        this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.company_cover_photo_changed, {});
                    }.bind(this)
                );
            },

            inputFieldColor: function inputFieldColor(color) {
                this.company.brand_color = color;
                //todo choose a lighter color for hovering

                //todo determine if we need to set the text_color here based on contrast problems

                this.saveChanges(true);
                this.AnalyticsService.track(this, 'color choose', {color_for: 'field color'});
            },

            fullWidthPickerOpened: function fullWidthPickerOpened() {
                this.setIsColorPickerOpen(true);
                this.pickerOpenedFieldColor();
            },

            pickerOpenedFieldColor: function pickerOpenedFieldColor() {
                this.AnalyticsService.track(this, 'open color picker', {color_for: 'field color'});
            },

            dismissBrandColorTooltip: function dismissBrandColorTooltip() {
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.brandColorTooltip, {dontShow: true});
            },

            isColorTooLight: function isColorTooLight(color) {
                if (!color) {
                    return false;
                }
                var c = color.substring(1);      // strip #
                var rgb = parseInt(c, 16);   // convert rrggbb to decimal
                var r = (rgb >> 16) & 0xff;  // extract red
                var g = (rgb >>  8) & 0xff;  // extract green
                var b = (rgb >>  0) & 0xff;  // extract blue

                var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

                if (luma > 222) {
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.brandColorTooltip, {dontShow: false});
                    return true;
                } else {
                    return false;
                }
            },

            openBrandingInspiration: function openBrandingInspiration() {
                this.ModalService.openBrandingInspirationModal();
            },

            visitBrandingGuide: function visitBrandingGuide() {
            },

            getFeatureImage: function getFeatureImage(images) {
                var coverSizeImages = images.filter(function (image) {
                   return image.width > 900;
                });

                return coverSizeImages[coverSizeImages.length -1 ];
            },

            isDefaultSecondaryLogo: function isDefaultSecondaryLogo() {
                // Note on names:
                // company_service       company_branding_directive
                // Icon             =    Logo
                // Logo             =    Secondary logo
                return this.CompanyService.isDefaultCompanyLogo(this.company);
            },

            setIsColorPickerOpen: function setIsColorPickerOpen(isOpen) {
                this.isColorPickerOpen = isOpen;
            },

            toggleColorPicker: function toggleColorPicker() {
                this.setIsColorPickerOpen(!this.isColorPickerOpen);
                if (this.isColorPickerOpen) {
                    this.$timeout(function() {
                        this.$element.find('.cfColorPicker.' + this.colorPickerClassName).spectrum('toggle');
                    }.bind(this))
                }
            }

        });

        return {
            scope:{},
            templateUrl : 'angular/app/modules/core/features/settings/company/branding/company_branding_directive_template.html',
            controller : CompanyBrandingDirectiveController,
            controllerAs : 'companySettingsOverviewVm',
            bindToController : true
        };

    };
}());
