(function () {
    "use strict";

    // @ngInject
    function SettingsMobileControllerCtor(
        $scope,
        $state,
        OnboardingService,
        SetupGuideService,
        UsersManager,
        $injector,
        AuthService,
        AppStates,
        moment,
        Enums,
        AnalyticsService,
        FinanceAppService,
        StatsigService
    ) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SettingsMobileController';

        this.moment = moment;
        this.AuthService = AuthService;
        this.AppStates = AppStates;
        this.UsersManager = UsersManager;
        this.AnalyticsService = AnalyticsService;
        this.$state = $state;
        this.OnboardingService = OnboardingService;
        this.SetupGuideService = SetupGuideService;
        this.Enums = Enums;
        this.StatsigService = StatsigService;

        this.user = this.UsersManager.getCurrUser();
        this.setSubscribedUserChanges();

        this.onboardingCardPersistence = this.SetupGuideService.getPersistenceHomeOnboardingCard();
        this.hasClickedCompleteMwebWizard = this.onboardingCardPersistence.hasClickedCompleteMwebWizard;
        
        this.navigationMenuItems = [
            {linkTitleKey: 'My Account', stateToGoto: AppStates.root_core_navigation_settings_account, icon: 'avatar-directive'}
        ];

        // add routes for vendors only
        if(this.user.isVendor()) {

            this.navigationMenuItems.push({
                linkTitleKey: 'Company Settings', stateToGoto: AppStates.root_core_navigation_settings_company_mobile, icon: 'icon-hb-nx-buildings'
            });

            FinanceAppService.isFinanceAppEnabled(this.user).then((isEligible) => {
                if (isEligible) {
                    this.navigationMenuItems.push({
                        linkTitleKey: 'HoneyBook Finance',
                        onClick: () => {
                            FinanceAppService.goToFinanceApp();
                        },
                        icon: 'icon-hb-nx-bank'
                    });
                }
            });

            if(this.user.hasMultipleCompanies()) {

                this.navigationMenuItems.push({
                    linkTitleKey: 'Switch company', stateToGoto: AppStates.root_core_navigation_brands, icon: 'icon-hb-nx-logout'
                });

            } else if (this.user.isAccountOwner()) {

                this.navigationMenuItems.push({
                    linkTitleKey: 'Add a new company',
                    stateToGoto: AppStates.root_core_navigation_brands,
                    icon: 'icon-hb-nx-plus',
                    isDisabled: !this.user.companyHasSubscription()
                });
            }
        }
    }


    Controllers.SettingsMobileController = Class(Controllers.BaseController, {

        constructor: SettingsMobileControllerCtor,

        setSubscribedUserChanges: function setupUserFlyoutActions() {
            this.shouldBeAbleToUpgrade = this.user.shouldBeAbleToUpgrade();
            this.isSetupGuideLinkVisible = this.SetupGuideService.isSetupGuideActiveForSubscribedUsers();
        },

        handleClickUpgradeNow: function handleClickUpgradeNow() {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.action, {
                unique: 'membership page',
                source: 'mweb profile settings'
            });
            this.$state.go(this.AppStates.root_core_navigation_settings_company_subscription);
        },

        logout: function logout() {
            this.AuthService.logout();
        }
    });
}());
