(function () {
    "use strict";

    Controllers.NewTwoFactorAuthModalController = class NewTwoFactorAuthModalController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $modalInstance, user) {
            super($scope, $injector);
            this.__objectType = 'NewTwoFactorAuthModalController';
            this.$modalInstance = $modalInstance;
            this.user = user;
        }

        onVerificationSuccess(resp) {
            this.$modalInstance.close(resp);
        }

        dismiss() {
            this.$modalInstance.dismiss();
        }
    };

}());
