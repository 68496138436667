(function () {
    'use strict';

    // @ngInject
    function AccountsManagerServiceCtor(APIService, ModelFactory, $q, Routes, Enums, UsersManager, CompaniesManager, RepositoryService, _, ModelUtils, FeaturesService, PubSubService, FeatureRestrictionService, AnalyticsService) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        this.Routes = Routes;
        this.Enums = Enums;
        this.UsersManager = UsersManager;
        this.CompaniesManager = CompaniesManager;
        this.RepositoryService = RepositoryService;
        this.ModelUtils = ModelUtils;
        this.FeaturesService = FeaturesService;
        this.FeatureRestrictionService = FeatureRestrictionService;
        this.AnalyticsService = AnalyticsService;
        this._ = _;
        this.$q = $q;

        let company = CompaniesManager.getCurrCompany();
        
        this._registerCompanySuccess(company);
        this.fetchFeaturePlan();

        PubSubService.ventMyBitchUp(this);
    }

    Services.AccountsManager = Class(Services.BaseModelManager, {
        constructor: AccountsManagerServiceCtor,

        getAvailablePricingPlans(accountId, promoCode) {
            let url = this.Routes.v2_get_available_pricing_plans_path(accountId);
            let data = {
                promo_code: promoCode,
            };
            return this.apiFetch(url, data);
        },

        getPricingPlanForAccount(accountId) {
            let url = this.Routes.v2_get_pricing_plan_for_account_path(accountId);
            return this.apiFetch(url);
        },

        createPricingPlan(accountId, pricingPlanId, promoCodeId ,paymentMethod) {
            let url = this.Routes.v2_create_pricing_plan_path(accountId);
            if (paymentMethod) {
                paymentMethod._type = this.Enums.PaymentMethodTypes.creditCard;
            }

            let promise = this.apiCreate(url, {pricing_plan_id: pricingPlanId, promo_code_id: promoCodeId, payment_method: paymentMethod});

            promise.then((res) => { this._savePaymentMethod(res); });

            return promise;
        },

        cancelPricingPlan(accountId, reasons, otherReason, extraInfo) {
            const user = this.UsersManager.getCurrUser();
            const data = {
                cancellation_reasons: reasons
            };

            if (otherReason) {
                data.other_reason = otherReason;
            }

            if (extraInfo) {
                data.extra_info = extraInfo;
            }

            let url = this.Routes.v2_cancel_subscription_path(accountId);
            return this.apiUpdate(url, data, user, true);
        },

        terminateSubscription(accountId, reasons, otherReason, extraInfo) {
            const user = this.UsersManager.getCurrUser();
            const data = {
                cancellation_reasons: reasons
            };

            if (otherReason) {
                data.other_reason = otherReason;
            }

            if (extraInfo) {
                data.extra_info = extraInfo;
            }

            let url = this.Routes.v2_terminate_subscription_path(accountId);
            return this.apiDelete(url, data, user, true);
        },

        pausePricingPlan(accountId, reasons, otherReason, extraInfo, selectedPauseDuration) {
            const user = this.UsersManager.getCurrUser();
            let url;
            if (user.getSubscription()) {
                url = this.Routes.v2_pause_subscription_path(accountId);
            }

            const data = {
                remaining_cycles: selectedPauseDuration
            };

            if(reasons) {
                data.cancellation_reasons = reasons;
            }

            if (otherReason) {
                data.other_reason = otherReason;
            }

            if (extraInfo) {
                data.extra_info = extraInfo;
            }

            var promise = this.apiUpdate(url, data);
            promise.then(function (resp) {
                var userData = {account: resp.data};
                user.mixinFrom(userData);
            });

            return promise;
        },

        resumePricingPlan() {
            const user = this.UsersManager.getCurrUser();
            let url = this.Routes.v2_resume_subscription_path(user.company.account_id);
            return this.apiUpdate(url);
        },


        changePaymentMethod(accountId, paymentMethod) {
            let url = this.Routes.v2_change_payment_method_for_pricing_plan_path(accountId);
            if (paymentMethod) {
                paymentMethod._type = this.Enums.PaymentMethodTypes.creditCard;
            }
            let promise = this.apiUpdate(url, {
                payment_method: paymentMethod
            });

            promise.then((res) => { this._savePaymentMethod(res); });

            return promise;
        },

        _savePaymentMethod(res) {
            this.UsersManager.getCurrUser().payment_methods = res.data.payment_methods;
            this.UsersManager.saveCurrUser();
        },

        fetchFeaturePlan(options) {
            const user = this.UsersManager.getCurrUser();
            const accountId = user && user.account_id;
            
            // Happens in client portal
            if (!accountId || !user) {
                return;
            }
            
            // if featurePlan has already been fetched, don't fetch it again
            const featureRestrictions = this.FeatureRestrictionService.featureRestrictions;
            
            const shouldFetchFeaturePlan = !featureRestrictions || (options && options.forceFetch);
            
            if (!shouldFetchFeaturePlan) {
                return;
            }
            
            const url = this.Routes.v2_get_feature_plan_for_account_path(accountId);

            this.FeatureRestrictionService.setIsFeaturePlanLoading(true);

            return this.apiFetch(url).then((resp) => {
                user.account.featurePlan = resp.data.feature_variations;
                user.account.featurePlanId = resp.data._id;
                this.UsersManager.saveCurrUser();
                this.AnalyticsService.identifyUser(user);
                this.FeatureRestrictionService.updateLastFetchDate();
                this.FeatureRestrictionService.updateFeatureRestrictions();
            }).catch(error => {
                this.AnalyticsService.trackError(this, 'error fetching feature plan', error, { pricing_and_packaging: true });
            }).finally(() => {
                this.FeatureRestrictionService.setIsFeaturePlanLoading(false);
            });
        },

        createCompany: function createCompany(accountId, companyType, companyName) {
            let url = this.Routes.v2_create_new_company_path(accountId);
            return this.apiCreate(url, {company_type: companyType, company_name: companyName});
        },

        getCompanies: function getCompanies(accountId) {
            let url = this.Routes.v2_list_account_companies_path(accountId);
            return this.apiFetch(url).then((resp) => {
               let user = this.UsersManager.getCurrUser();
               let companiesMap = this._.groupBy(resp.data, '_id');
               user.companies.forEach((company) => {
                   let _company = companiesMap[company._id][0];
                   this.ModelUtils.extendModel(company, _company);
               });

               return user.companies;
            });
        },

        switchCompany(companyId) {
            this.RepositoryService.cacheClearAll();
            this.RepositoryService.persistentClearAll();
            let user = this.UsersManager.getCurrUser();
            // reset user's company
            user.company.offAll();
            user.company = this.ModelFactory.newCompanyModel();
            user.company._id = companyId;
            user.company_id = companyId;
            return this.UsersManager.forceFetchCurrUser().then(() => {
                // why this doesn't return a promise..
                let currCompany = this.CompaniesManager.getCurrCompany(true);
                let defer = this.$q.defer();
                currCompany.once('success', () => { defer.resolve(); });
                currCompany.once('error', () => { defer.reject(); });
                return defer.promise;
            }).then(() => {
                return this._registerCompanySuccess(user.company);
            });
        },

        deleteCompany(accountId, companyId) {
            let url = this.Routes.v2_delete_company_path(accountId, companyId);
            return this.apiDelete(url);
        },

        _registerCompanySuccess(company) {
            company.on('success', () => { this.getCompanies(company.account_id); }, this);
        },

        updateAccountSettings: function updateAccountSettings(account, accountSettings) {
            var url = this.Routes.v2_update_account_settings_path(account._id);
            return this.apiUpdate(url, {account_settings: accountSettings});
        },

        getCoupon: function getCoupon(couponCode) {
            let accountId = this.UsersManager.getCurrUser().account_id;
            let url = this.Routes.v2_get_coupon_path(accountId, couponCode);
            return this.apiFetch(url);
        },

        triggerCouponApplied: function triggerCouponApplied(coupon) {
            this.trigger(this.Enums.PubSubTriggers.couponApplied, {coupon: coupon});
        },

        createSubscriptionChange: function createSubscriptionChange(account, planCode){
            var data = {
                account_id: account._id,
                subscription_id: account.subscription._id,
                plan_code: planCode
            };

            let user = this.UsersManager.getCurrUser();
            var url = this.Routes.v2_create_subscription_change_path(account._id);
            return this.apiCreate(url, data, user, true);
        },

        getSubscriptionChange: function getSubscriptionChange() {
            let accountId = this.UsersManager.getCurrUser().account_id;
            let url = this.Routes.v2_get_subscription_change_path(accountId);
            return this.apiFetch(url);
        },

        completeManualAccountSetup: function completeManualAccountSetup(account, batch_index) {
            var url = this.Routes.v2_complete_manual_account_setup_path(account._id, { batch_index: batch_index });
            return this.apiUpdate(url);
        },

        sendAccountSetupEvent: function sendAccountSetupEvent(account, eventName) {
            // make sure we're sending allowed event
            const events = Object.keys(this.Enums.accountSetupEvents).map(key => this.Enums.accountSetupEvents[key].event);
            if (!events.includes(eventName)) {
                return;
            }

            const url = this.Routes.v2_update_account_setup_state_path(account._id);
            return this.apiUpdate(url, {event_name: eventName});
        }
    });
}());
