(function () {
    "use strict";

    Controllers.SchedulingController = class SchedulingController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, AnalyticsService, AppStates, UIUtils, SchedulingService, UsersManager, moment, PopupMessageService,
                    UiPersistenceService, TemplatesRepositoryManager, FlowService, CompaniesManager, FlowsBetaUserTypeService,
                    Enums, FeaturesService, $translate, FeatureRestrictionService, StatsigService, DatadogRUMService, DeviceService, TimezoneService, ModelFactory) {
            super($scope, $injector);
            this.__objectType = 'SchedulingController';

            this.AnalyticsService = AnalyticsService;
            this.AppStates = AppStates;
            this.SchedulingService = SchedulingService;
            this.UsersManager = UsersManager;
            this.moment = moment;
            this.PopupMessageService = PopupMessageService;
            this.UiPersistenceService = UiPersistenceService;
            this.TemplatesRepositoryManager = TemplatesRepositoryManager;
            this.FlowService = FlowService;
            this.CompaniesManager = CompaniesManager;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
            this.Enums = Enums;
            this.FeaturesService = FeaturesService;
            this.FeatureRestrictionService = FeatureRestrictionService;
            this.DatadogRUMService = DatadogRUMService;
            this.TimezoneService = TimezoneService;
            this.ModelFactory = ModelFactory;
            // not sure why this is needed but if I use setSessionsList directly
            // it passes to react as undefined 
            this.setSessionsListFn = this.setSessionsList.bind(this);

            // ng2react - Scheduler page
            this.schedulerToolVariant = 'pending';
            StatsigService.isGateEnabled('ng2react-scheduler-tool').then(function (isEnabled) {
                this.schedulerToolVariant = isEnabled ? 'react' : 'angular';
            }.bind(this)).catch(function () {
                this.schedulerToolVariant = 'angular';
            }.bind(this));
            

            this.AnalyticsService.trackPageView(this, 'Scheduling View');

            this.SESSION_TYPES_COUNT_LIMIT = 40;

            // get user
            this.user = this.UsersManager.getCurrUser();
            this.company = this.user.company;

            this.restrictedFeature = this.FeatureRestrictionService.features.scheduler;
            this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);

            const unsubscribeFeatureRestrictions = $scope.$watch('schedulingPageVm.FeatureRestrictionService.featureRestrictions', function onFeatureRestrictionsChange() {
                this.isFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.restrictedFeature);
            }.bind(this), true);
            
            this.isPublicFlowsEnabled = this.FlowsBetaUserTypeService.setIsPublicFilesUser();

            $scope.$on("$destroy", function () {
                unsubscribeFeatureRestrictions();
            });

            const is_Mweb = DeviceService.nxSmallBreakpoint();
            this.featureRestrictionBannerTitle = $translate.instant('SCHEDULING.FEATURE_RESTRICTION_BANNER._TITLE_');
            const bannerSubTitle = is_Mweb ? '_MWEB_' : '_DESKTOP_';
            this.featureRestrictionBannerBenefits = [
                $translate.instant(`SCHEDULING.FEATURE_RESTRICTION_BANNER.${bannerSubTitle}._BENEFIT_1_`),
                $translate.instant(`SCHEDULING.FEATURE_RESTRICTION_BANNER.${bannerSubTitle}._BENEFIT_2_`)
            ];

            // set team members
            this.initTeamMembers();

            // load sessions
            this.loadSessionsData();

            // set calendar as the active route
            $scope.calendarAbstractVm.setSchedulingActive();

            $scope.$on('handle-create-scheduling', () => {
                this.createSession();
            });

            this.isLoading = false;

            if (this.isScheduleAndPayBannerVisible()) {
                this.AnalyticsService.trackLoad(
                    this,
                    this.AnalyticsService.analytics_events
                        .scheduler_block_banner_in_tool
                );
            }
        }

        initTeamMembers() {
            this.teamMembers = this.company.getAllCompanyMembers();
            if(this.teamMembers.length > 1) {
                this.teamMembers = this.teamMembers.filter((tm) => !!tm.full_name);

                const currTeamMember = this.teamMembers.filter((tm) => tm._id === this.user._id)[0];
                const idx = this.teamMembers.indexOf(currTeamMember);
                if(idx > 0) {
                    this.teamMembers.splice(idx, 1);
                    this.teamMembers.unshift(currTeamMember);
                }

                this.currentViewingUser = this.teamMembers[0];
            }

            this.canViewOtherTeamMembers = this.user.hasCompanyAdminPermissions();
            this.isViewingCurrentUser = true;
        }

        loadSessionsData() {
            let forUserId;
            this.loadingSessions = true;
            if(this.canViewOtherTeamMembers &&
                this.currentViewingUser &&
                this.currentViewingUser._id &&
                this.currentViewingUser._id !== this.user._id) {
                forUserId = this.currentViewingUser._id;
            }

            return this.user.getSessions(forUserId, null, false, true).then((sessions) => {
                this.FeatureRestrictionService.updateFeatureRestrictions();
                this.sessionsList = sessions.reverse().sort((sessionA, sessionB) => sessionA.enabled == sessionB.enabled ? sessionA : (sessionA.enabled ? -1 : 1));
                this.enabledSessionsList = this.sessionsList.filter((s) => s.enabled);

                // change empty state data
                this.sessionsEmptyText = this.isViewingCurrentUser ? 'SCHEDULING._HERO_TEXT_' : 'SCHEDULING._HERO_TEXT_OTHER_USER_';
                this.availabilityEmptyText = this.isViewingCurrentUser ? 'SCHEDULING._AVAILABILITY_EMPTY_STATE_TEXT_' : 'SCHEDULING._AVAILABILITY_EMPTY_STATE_TEXT_OTHER_USER_';

                this.loadingSessions = false;
            });
        }

        setSessionsList(sessions) {
            var sessionConfigs = this.ModelFactory.newCollectionByModelName('SessionModel');
            sessionConfigs.mixinFrom(sessions);
            this.sessionsList = sessionConfigs.reverse().sort((sessionA, sessionB) => sessionA.enabled == sessionB.enabled ? sessionA : (sessionA.enabled ? -1 : 1));
            this.enabledSessionsList = this.sessionsList.filter((s) => s.enabled);
        }

        updateSession(session) {
            this.user.updateSession(session);
            this.enabledSessionsList = this.sessionsList.filter((s) => s.enabled);
        }

        createSession() {
            // limit for now
            if(this.sessionsList.length < this.SESSION_TYPES_COUNT_LIMIT) {
                this.$state.go(this.AppStates.root_core_sessionConfig, { sessionId: 'new', userId: this.user._id });
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.create_first_session);
            } else {
                this.PopupMessageService.showAlertPromise(
                    this.PopupMessageService.severityTypes.none,
                    "Sorry, you can't create more than " + this.SESSION_TYPES_COUNT_LIMIT + " sessions.",
                    'OK',
                    'Create new session');
            }
        }

        onAvailabilityChange(session) {
            this.updateSession(session);
        }

        onChangeTeamMember(member) {
            this.currentViewingUser = member;
            this.isViewingCurrentUser = this.user._id === member._id;
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.select_team_member_scheduling);
            this.loadSessionsData();
        }

        isScheduleAndPayBannerVisible() {
            return (
                !this.isPublicFlowsEnabled &&
                this.FlowsBetaUserTypeService.hasFlows &&
                !this.UiPersistenceService.getUiPersistence(
                    this.UiPersistenceService.keys
                        .dismissedSchedulerBlockBannerInTool,
                    { value: false }
                ).value
            );
        }

        shouldShowLeadFormsBanner() {
            return (
                this.isPublicFlowsEnabled &&
                this.sessionsList &&
                this.sessionsList.length > 0
            );
        }

        onDismissBannerClick() {
            this.dismissBanner();
            this.AnalyticsService.trackClick(
                this,
                this.AnalyticsService.analytics_events
                    .scheduler_block_banner_in_tool_dismiss
            );
        }

        dismissBanner() {
            this.UiPersistenceService.setUiPersistence(
                this.UiPersistenceService.keys.dismissedSchedulerBlockBannerInTool,
                { value: true }
            );
        }

        onImportSchedulerTemplate() {
            this.isLoading = true;
            this.AnalyticsService.trackClick(
                this,
                this.AnalyticsService.analytics_events
                    .scheduler_block_banner_in_tool_cta
            );

            this.TemplatesRepositoryManager.importTemplates(
                this.CompaniesManager.getCurrCompany(),
                { id: [this.Enums.flowTemplateIds.blank_scheduler] }
            )
                .then(res => {
                    this.dismissBanner();
                    window.open(
                        `/app/flow/${res.data.imported_templates[0]._id}/edit`
                    );
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
        
        shouldDisplayRestrictedEmptyState() {
            return this.isFeatureRestricted && this.sessionsList.length === 0;
        }
    };

}());
