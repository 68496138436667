(function () {
    "use strict";

    // @ngInject
    function CompanySettingsPreferencesControllerCtor($scope,$stateParams, $rootScope, $q, $window, $injector, _, $translate,
                                                      CompaniesManager, UsersManager, AppStates, $sce,
                                                      DeviceService, Enums, ModalService, ReactModalService, AnalyticsService, AutomationService,
                                                      EventService, NavigationService, $state, PopupMessageService, UiPersistenceService, ngToast, ToastService, FeaturesService,
                                                      AccountsManager, FlowsBetaUserTypeService, StatsigService, DatadogRUMService) {


        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanySettingsPreferencesController';
        var self = this;
        this.AnalyticsService = AnalyticsService;
        this.AutomationService = AutomationService;
        this.DatadogRUMService = DatadogRUMService;

        this.AccountsManager = AccountsManager;
        this.CompaniesManager = CompaniesManager;
        this.company = this.CompaniesManager.getCurrCompany();
        this.flows = [];
        this.user = UsersManager.getCurrUser();
        this.FeaturesService = FeaturesService;
        this._ = _;
        this.DeviceService = DeviceService;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$q = $q;
        this.$window = $window;
        this.Enums = Enums;
        this.ModalService = ModalService;
        this.ReactModalService = ReactModalService;
        this.$sce = $sce;
        this.ngToast = ngToast;
        this.EventService = EventService;
        this.AppStates = AppStates;
        this.NavigationService = NavigationService;
        this.PopupMessageService = PopupMessageService;
        this.$translate = $translate;
        this.UiPersistenceService = UiPersistenceService;
        this.ToastService = ToastService;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
        this.StatsigService = StatsigService;
        this.isFlowThumbnailVisibleToClient = this.company.is_flow_thumbnail_visible_to_client;

        this.projectTypes = angular.copy(this.company.sortedProjectTypes());
        this.source = "company_settings";
        this.tagsAnalyticsArgs = {type: 'lead sources'};
        this.spacesAnalyticsArgs = {type: 'spaces'};
        this.detailsAnalyticsArgs = {type: 'project details'};

        this.notViewedAnalytics = 'notify_not_viewed';
        this.notBookedAnalytics = 'notify_not_booked';
        this.notSentAnalytics = 'notify_not_sent';
        this.inquiryReminderAnalytics = 'inquiry_reminder';
        this.priorToProjectAnalytics = 'notify_prior_to_project';
        this.displayConflictingDatesAnalytics = 'display_notify_conflicting_dates';
        this.displayAutomatedEmailsInFeedAnalytics = 'display_automated_emails_in_feed';

        this.translationBase = 'COMPANY_SETTING.AUTOMATION_TEMPLATE';
        this.reminderTrans = this.translationBase + '.REMINDER_COPY';
        this.templateTrans = this.translationBase + '.TEMPLATE_TYPES';
        this.fileExpirationText = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.ACTION_COPY._DEFAULT_FILE_EXPIRATION_TEXT_';

        this.questionnaireTimeFrames = {
            one_week: {title: '1 week', value: 'one_week'},
            two_weeks: {title: '2 weeks', value: 'two_weeks'},
            three_weeks: {title: '3 weeks', value: 'three_weeks'},
            one_month: {title: '1 month', value: 'one_month'},
            two_months: {title: '2 months', value: 'two_months'}
        };

        this.notViewedTimeFrames = {
            one_day: {title: '1 day', value: 'one_day'},
            two_days: {title: '2 days', value: 'two_days'},
            three_days: {title: '3 days', value: 'three_days'},
            seven_days: {title: '7 days', value: 'seven_days'},
            ten_days: {title: '10 days', value: 'ten_days'},
            two_weeks: {title: '2 weeks', value: 'two_weeks'}
        };

        this.emailNotificationTimeFrames = {
            one_day: {title: '1 day', value: 'one_day'},
            two_days: {title: '2 days', value: 'two_days'},
            three_days: {title: '3 days', value: 'three_days'},
            seven_days: {title: '7 days', value: 'seven_days'},
            ten_days: {title: '10 days', value: 'ten_days'},
            two_weeks: {title: '2 weeks', value: 'two_weeks'}
        };

        this.inquiryReminderTimeFrames = {
            1: {title: '1 day', value: 1},
            2: {title: '2 days', value: 2},
            3: {title: '3 days', value: 3},
            4: {title: '4 days', value: 4},
        };

        this.templateTypes = {
            rejection: {text: this.templateTrans + '._REJECTION_'},
            event_date: {text: this.templateTrans + '._PROJECT_DATE_'},
            thank_you: {text: this.templateTrans + '._THANK_YOU_'},
            questionnaire: {text: this.templateTrans + '._QUESTIONNAIRE_'}
        };

        this.openedTypes = [];
        this.actions = [];
        this.defaultFileExpirationData = {};
        this.inquiryFollowUpReminderData = {};
        this.CompaniesManager.getInquiryFollowUpReminder().then(function (res) {
            this.inquiryFollowUpReminderData = res.data;
            this.buildRemindersViewModel();
        }.bind(this))
            .catch(function (err) {
                this.DatadogRUMService.addError(new Error("error calling to getInquiryFollowUpReminder API", {cause: err}), {
                    error: JSON.stringify(err)
                });
            }.bind(this));

        this.projectFieldsEnabled = false;
        if (this.user.hasCompanyAdminPermissions()) {
            this.projectFieldsEnabled = this.user.is_custom_project_fields_enabled;
        }

        this.contactFieldsEnabled = false;
        let contactFieldsPromise = null;
        if (this.user.hasCompanyAdminPermissions()) {
           contactFieldsPromise = StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.contactFields).then((res) => {
                this.contactFieldsEnabled = res;
            });
        }

        this.clientFeedbackEnabled = false;
        if (this.user.hasCompanyAdminPermissions()) {
            StatsigService.isGateEnabled('project_client_feedback').then(function (result) {
                this.clientFeedbackEnabled = result;
            }.bind(this));
        }

        this.tagsEnabled = false;
        StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.tags).then((res)=>{
            this.tagsEnabled = res;
        });

        this.buildRemindersViewModel();
        this.buildDefaultNameViewModel();

        this.checkIfMobileSize = function checkIfMobileSize() {
            this.isMobileSize = this.DeviceService.checkIfMobileSize();
        };

        this.checkMobileSizeWrapper = function checkMobileSizeWrapper() {
            self.checkIfMobileSize();
            self.$scope.$apply();
        };

        self.checkIfMobileSize();

        angular.element($window).bind('resize', this.checkMobileSizeWrapper);

        $scope.$on('$destroy', function () {
            angular.element($window).off('resize', self.checkMobileSizeWrapper);
        });

        this.initProjectTypesVm();

        if (this.company.wasFullyFetched()) {
            this.gotCompany();
            this.initProjectTypesVm();
        }

        this.register(this.company, 'success', this.gotCompany);

        // redirect to /preferences if workflow is enabled
        if ($state.$current.name !== this.AppStates.root_core_navigation_settings_company_preferences) {
            this.goToState(this.AppStates.root_core_navigation_settings_company_preferences);
        }

        this.$scope.$on("$destroy", function () {
            this.ngToast.dismiss(this.toastLeadSource);
        }.bind(this));

        this.shouldShowFileNameSettings = this.user.isAccountOwner();
        this.smsEnabled = this.company.isSMSEnabled();
        this.smsPaymentRemindersEnabled = this.company.isSMSPaymentRemindersEnabled();

        const template_name = {label: "COMPANY_SETTING.FILE_NAMES_SETTINGS.OPTIONS._TEMPLATE_NAME_", key: "template_name"};
        const project_with_template_name = {label: "COMPANY_SETTING.FILE_NAMES_SETTINGS.OPTIONS._PROJECT_WITH_TEMPLATE_NAME_", key: "project_with_template_name"};
        const project_with_template_name_or_file_type = {label: "COMPANY_SETTING.FILE_NAMES_SETTINGS.OPTIONS._PROJECT_WITH_TEMPLATE_NAME_OR_FILE_TYPE_", key: "project_with_template_name"};
        const project_with_file_type = {label: "COMPANY_SETTING.FILE_NAMES_SETTINGS.OPTIONS._PROJECT_WITH_FILE_TYPE_",key: "project_with_file_type"};
        const project_with_flow_or_file_type = {label: "COMPANY_SETTING.FILE_NAMES_SETTINGS.OPTIONS._PROJECT_WITH_FLOW_OR_FILE_TYPE_",key: "project_with_file_type"};
        const project_name = {label: "COMPANY_SETTING.FILE_NAMES_SETTINGS.OPTIONS._PROJECT_NAME_", key: "project_name"};
        const file_type = {label: "COMPANY_SETTING.FILE_NAMES_SETTINGS.OPTIONS._FILE_TYPE_", key: "file_type"};

        if (this.FlowsBetaUserTypeService.hasFlows) {
            this.fileNamesOptions = this.FlowsBetaUserTypeService.hasFiles ?
                [ template_name, project_with_template_name_or_file_type, project_with_flow_or_file_type ]
                :
                [ template_name, project_name, project_with_template_name ];
            this.fileExpirationText = 'COMPANY_SETTING.AUTOMATION_TEMPLATE.ACTION_COPY._DEFAULT_FILE_EXPIRATION_TEXT_SMAF_ENABLED_';
        } else {
            this.fileNamesOptions = [ template_name, file_type, project_with_file_type ];
        }

        // defaults
        this.fileFromTemplateName = this.fileNamesOptions[1];
        this.fileFromWorkflowName = this.fileNamesOptions[1];

        this.setFileNameSettings();

        this.shouldSeeClientFeedback = this.user.getExistingAbTestVariation(this.Enums.ABTests.bookingReview) === 'booking_review';

        if ($stateParams.data_management) {
            switch ($stateParams.data_management) {
                case 'contact_custom_fields':
                    if (contactFieldsPromise) {
                        contactFieldsPromise.then(function () {
                            if (this.contactFieldsEnabled) {
                                this.editContactFields();
                            }
                        }.bind(this));
                    }
                    break;
                case 'project_custom_fields':
                    if (this.projectFieldsEnabled) {
                        this.editProjectDetailsV2();
                    }
                    break;
                default:
                    break;

            }
        }
    }

    Controllers.CompanySettingsPreferencesController = Class(Controllers.BaseController, {

        constructor: CompanySettingsPreferencesControllerCtor,

        buildRemindersViewModel: function buildRemindersViewModel() {
            if (this.FlowsBetaUserTypeService.hasFlows) {
                this.reminders = this.FlowsBetaUserTypeService.hasFiles ? [
                    this.buildReminderInfo(
                        this.Enums.WorkflowAutomationTypes.reminder_file_sent_email_not_viewed,
                        'FLOWS._UNREAD_EMAIL_WITH_FLOW_OR_FILE_',
                        this.emailNotificationTimeFrames,
                        this.notViewedAnalytics,
                        'one_day',
                        this.openedTypes
                    ),
                    this.buildReminderInfo(
                        this.Enums.WorkflowAutomationTypes.reminder_file_not_booked,
                        'FLOWS._UNACTED_FLOW_OR_FILE_',
                        this.emailNotificationTimeFrames,
                        this.notBookedAnalytics,
                        'seven_days',
                        this.openedTypes
                    ),
                    this.buildReminderInfo(
                        this.Enums.WorkflowAutomationTypes.reminder_unsent_file,
                        'FLOWS._UNSENT_FLOW_OR_FILE_',
                        this.emailNotificationTimeFrames,
                        this.notSentAnalytics,
                        'three_days',
                        this.openedTypes
                    )
                ] : [
                    this.buildReminderInfo(
                        this.Enums.WorkflowAutomationTypes.reminder_file_sent_email_not_viewed,
                        'FLOWS._UNREAD_EMAIL_WITH_FLOW_',
                        this.emailNotificationTimeFrames,
                        this.notViewedAnalytics,
                        'one_day',
                        this.openedTypes
                    ),
                    this.buildReminderInfo(
                        this.Enums.WorkflowAutomationTypes.reminder_file_not_booked,
                        'FLOWS._UNACTED_FLOW_',
                        this.emailNotificationTimeFrames,
                        this.notBookedAnalytics,
                        'seven_days',
                        this.openedTypes
                    ),
                    this.buildReminderInfo(
                        this.Enums.WorkflowAutomationTypes.reminder_unsent_file,
                        'FLOWS._UNSENT_FLOW_',
                        this.emailNotificationTimeFrames,
                        this.notSentAnalytics,
                        'three_days',
                        this.openedTypes
                    )
                ];
            } else {
                this.reminders = [
                    this.buildReminderInfo(this.Enums.WorkflowAutomationTypes.reminder_file_sent_email_not_viewed, '_UNREAD_EMAIL_WITH_FILE_', this.emailNotificationTimeFrames, this.notViewedAnalytics, 'one_day', this.openedTypes),
                    this.buildReminderInfo(this.Enums.WorkflowAutomationTypes.reminder_file_not_booked, '_UNACTED_FILE_', this.emailNotificationTimeFrames, this.notBookedAnalytics, 'seven_days'),
                    this.buildReminderInfo(this.Enums.WorkflowAutomationTypes.reminder_unsent_file, '_UNSENT_FILE_', this.emailNotificationTimeFrames, this.notSentAnalytics, 'three_days'),
                ];
            }
            this.reminders.push(
                this.buildReminderInfo(this.Enums.WorkflowAutomationTypes.reminder_event_coming_up, '_UPCOMING_PROJECT_', null, this.priorToProjectAnalytics),
            );

            if (this.inquiryFollowUpReminderData && this.inquiryFollowUpReminderData.is_gate_open) {
                this.reminders.push(this.buildInquiryReminder(this.inquiryFollowUpReminderData));
            }

            this.reminders.push(
                this.buildReminderInfo(this.Enums.WorkflowAutomationTypes.show_date_conflicts, '_DATE_CONFLICT_', null, this.displayConflictingDatesAnalytics),
                this.buildReminderInfo(this.Enums.WorkflowAutomationTypes.add_automated_emails_to_activity, '_AUTOMATED_ACTIVITY_EMAILS_', null, this.displayAutomatedEmailsInFeedAnalytics)
            );
        },

        buildDefaultNameViewModel: function buildDefaultNameViewModel() {
            if (this.FlowsBetaUserTypeService.hasFlows) {
                if (this.FlowsBetaUserTypeService.hasFiles) {
                    this.defaultNameFromTemplate = "COMPANY_SETTING.FILE_NAMES_SETTINGS.FLOWS._FLOW_OR_FILE_FROM_TEMPLATE_NAME_DESC";
                    this.defaultNameFromAutomation = "COMPANY_SETTING.FILE_NAMES_SETTINGS.FLOWS._FLOW_OR_FILE_FROM_WORKFLOW_NAME_DESC";
                } else {
                    this.defaultNameFromTemplate = "COMPANY_SETTING.FILE_NAMES_SETTINGS.FLOWS._FLOW_FROM_TEMPLATE_NAME_DESC";
                    this.defaultNameFromAutomation = "COMPANY_SETTING.FILE_NAMES_SETTINGS.FLOWS._FLOW_FROM_WORKFLOW_NAME_DESC";
                }
            }
            else {
                this.defaultNameFromTemplate = "COMPANY_SETTING.FILE_NAMES_SETTINGS._FILE_FROM_TEMPLATE_NAME_DESC";
                this.defaultNameFromAutomation = "COMPANY_SETTING.FILE_NAMES_SETTINGS._FILE_FROM_WORKFLOW_NAME_DESC";
            }
        },

        gotCompany: function gotCompany() {
            if (this.FlowsBetaUserTypeService.hasFlows && !this.company.flows) {
                this.CompaniesManager.fetchCompanyFlows(this.company);
            }
            this.flows =  !this._.isEmpty(this.company.flows) && this.company.flows.filter(flow => this._.isEmpty(flow.action_block_types));
            this.buildRemindersViewModel();
            this.actions = [];

            this.smsEnabled = this.company.isSMSEnabled();
            this.smsPaymentRemindersEnabled = this.company.isSMSPaymentRemindersEnabled();

            if (this.FlowsBetaUserTypeService.hasOnlyFlows) {
                this.actions.push(
                    this.AutomationService.buildActionInfo(this.company, this.Enums.WorkflowAutomationTypes.client_file_not_viewed,
                        'client_file_not_viewed',
                        'reminder_not_viewed',
                        this.translationBase + '.ACTION_COPY.FLOWS._CLIENT_FLOW_NOT_VIEWED_',
                        this.translationBase + '.ACTION_COPY.FLOWS._CLIENT_FLOW_NOT_VIEWED_EMAIL_',
                        true,
                        false,
                        false,
                        false,
                        false,
                        this.openedTypes,
                        this.notViewedTimeFrames,
                        'three_days')
                );
            } else {
                this.actions.push(
                    this.AutomationService.buildActionInfo(this.company, this.Enums.WorkflowAutomationTypes.automation_send_questionnaire,
                        'pre_event_questionnaire',
                        'send_before_project',
                        this.translationBase + '.ACTION_COPY._PROJECT_QUESTIONNAIRE_',
                        this.translationBase + '.ACTION_COPY._PROJECT_QUESTIONNAIRE_EMAIL_',
                        true,
                        true,
                        false,
                        false,
                        false,
                        this.openedTypes,
                        this.questionnaireTimeFrames,
                        'three_weeks')
                );
                this.actions.push(
                    this.AutomationService.buildActionInfo(this.company, this.Enums.WorkflowAutomationTypes.client_file_not_viewed,
                        'client_file_not_viewed',
                        'reminder_not_viewed',
                        `${this.translationBase}.ACTION_COPY.${this.FlowsBetaUserTypeService.hasFlows ? 'FLOWS._CLIENT_FILE_OR_FLOW_NOT_VIEWED_' : '_CLIENT_FILE_NOT_VIEWED_'}`,
                        this.translationBase + '.ACTION_COPY._CLIENT_FILE_NOT_VIEWED_EMAIL_',
                        true,
                        false,
                        false,
                        false,
                        false,
                        this.openedTypes,
                        this.notViewedTimeFrames,
                        'three_days')
                );
            }

            if(this.FlowsBetaUserTypeService.hasFlows){
                this.actions.push(
                    this.AutomationService.buildActionInfo(this.company, this.Enums.WorkflowAutomationTypes.send_flow_before_project,
                        'client_file_not_viewed',
                        'send_flow_before_project',
                        this.translationBase + '.ACTION_COPY.FLOWS._SEND_FLOW_BEFORE_PROJECT_',
                        this.translationBase + '.ACTION_COPY._CLIENT_FILE_NOT_VIEWED_EMAIL_',
                        true,
                        false,
                        false,
                        false,
                        true,
                        this.openedTypes,
                        this.questionnaireTimeFrames,
                        'three_weeks')
                );
            }

            this.buildDefaultExpirationData();
            this.buildAutoExpirationModel();

            this.paymentReminders = [

                this._buildPaymentReminder(
                    this.Enums.WorkflowAutomationTypes.upcoming_payment_reminder,
                    'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.UPCOMING._LABEL_',
                    'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.UPCOMING._DESC_',
                    'upcoming'),

                this._buildPaymentReminder(
                    this.Enums.WorkflowAutomationTypes.auto_recurring_payment_reminder,
                    'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.AUTO._LABEL_',
                    'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.AUTO._DESC_',
                    'recurring_auto_pay'),

                this._buildPaymentReminder(
                    this.Enums.WorkflowAutomationTypes.due_payment_reminder,
                    'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.DUE_DATE._LABEL_',
                    'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.DUE_DATE._DESC_',
                    'payment_due_date'),

                this._buildPaymentReminder(
                    this.Enums.WorkflowAutomationTypes.outstanding_payment_reminder,
                    'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.OUTSTANDING._LABEL_',
                    'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.OUTSTANDING._DESC_',
                    'outstanding'),

            ];

            this.getLateFeeVariation().then((isEnabled) => {
               if (isEnabled) {
                   this.paymentReminders.push(
                       this._buildPaymentReminder(
                           this.Enums.WorkflowAutomationTypes.longer_outstanding_payment_reminder,
                           'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.LONGER_OUTSTANDING._LABEL_',
                           'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.LONGER_OUTSTANDING._DESC_',
                           'longer_outstanding',
                           'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.LONGER_OUTSTANDING._TITLE',
                           'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.LONGER_OUTSTANDING._BODY_',
                       )
                   );

                   this.paymentReminders.push(
                       this._buildPaymentReminder(
                           this.Enums.WorkflowAutomationTypes.before_late_fee_payment_reminder,
                           'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.LATE_FEE._LABEL_',
                           'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.LATE_FEE._DESC_',
                           'late_fee',
                           'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.LATE_FEE._TITLE',
                           'COMPANY_SETTING.AUTOMATION_TEMPLATE.PAYMENT_REMINDERS.EDIT_MODAL.DEFAULTS.LATE_FEE._BODY_',
                       )
                   );
               }
            });
        },

        _buildPaymentReminder: function _buildPaymentReminder(type, label, description, analProperty, defaultSubjectKey, defaultBodyKey) {

            var paymentReminderInfo = this.company.getAutomationInfo(type);

            var subject = paymentReminderInfo ? paymentReminderInfo.subject : this.$translate.instant(defaultSubjectKey);
            var body = paymentReminderInfo ? paymentReminderInfo.html_body : this.$translate.instant(defaultBodyKey);

            return {
                id: type,
                type: type,
                analyticsProperty: analProperty,
                label: label,
                description: description,
                automationOn: this.company.getAutomationStatus(type),
                subject: subject,
                body: body
            };
        },

        onPaymentReminderCustomizeClick: function onPaymentReminderCustomizeClick(paymentReminder) {
            this.getLateFeeVariation().then((isEnabled) => {
                this.ModalService.openEditPaymentReminderEmailModal(paymentReminder, isEnabled);
            });
        },

        getLateFeeVariation: function isLateFeeFeatureEnabled() {
            return this.StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.lateFees);
        },

        // keep feature hidden for members with only flows (trialers)
        shouldShowDefaultFileExpirationToggle: function shouldShowDefaultFileExpirationToggle() {
            return !this.FlowsBetaUserTypeService.hasOnlyFlows;
        },

        buildDefaultExpirationData: function buildDefaultExpirationData() {
            var defaultExpirationOn = this.user.default_auto_expiration_period && this.user.default_auto_expiration_period !== 'never';
            this.defaultFileExpirationData.id = this.Enums.WorkflowAutomationTypes.default_file_expiration;
            this.defaultFileExpirationData.pretext = this.translationBase + '.ACTION_COPY._DEFAULT_FILE_EXPIRATION_PRE';
            this.defaultFileExpirationData.posttext = this.translationBase + '.ACTION_COPY._DEFAULT_FILE_EXPIRATION_POST_';
            this.defaultFileExpirationData.automationOn = defaultExpirationOn;
            this.defaultFileExpirationData.showEditBox = false;
            if (this.FlowsBetaUserTypeService.hasFlows) {
                this.defaultFileExpirationData.analyticsProperty = this.FlowsBetaUserTypeService.hasFiles ? 'expire_file_flow' : 'expire_flow';
            } else {
                this.defaultFileExpirationData.analyticsProperty = 'expire_file';
            }
        },

        buildAutoExpirationModel: function buildAutoExpirationModel() {
            this.autoExpirationModel = {
                options: [
                    {serverValue: 'one_week', displayKey: '1 Week', description: '1 week'},
                    {serverValue: 'two_weeks', displayKey: '2 Weeks', description: '2 weeks'},
                    {serverValue: 'three_weeks', displayKey: '3 Weeks', description: '3 weeks'},
                    {serverValue: 'four_weeks', displayKey: '4 Weeks', description: '4 weeks'}
                ],
                selected: null,
            };
            this.setDefaultExpiration(this.user.default_auto_expiration_period);
        },

        setDefaultExpiration: function setDefaultExpiration(value) {
            // Set default expiration
            if (value) {
                angular.forEach(this.autoExpirationModel.options, function (option) {
                    if (option.serverValue === value) {
                        this.autoExpirationModel.selected = option;
                    }
                }.bind(this));
            } else {
                // Default value is 2 weeks
                this.autoExpirationModel.selected = this.autoExpirationModel.options[1];
                value = 'never';
            }

            // Save if automation is already on or if it is being turned off
            if (this.defaultFileExpirationData.automationOn || (!this.defaultFileExpirationData.automationOn && value === 'never')) {
                this.user.updateAutoExpireDefault(this.user, value);
            }
        },

        /**
         *  buildReminderInfo
         * @param type - the type of this automation
         * @param translation - the text to be shown on the automation
         * @param timeFrames - if there is a drop-down of for the automation that involves time frames these are the values
         * @param defaultTimeFrameKey - the default value of the drop-down
         * @param openedEditBoxes - a hash mapping all the opened drop-downs so if they are rebuilt (due to server returning the result) the will stay open
         * @param analyticsProperty
         * @returns {object} - the view-model of the reminder.
         */
        buildReminderInfo: function buildReminderInfo(type, translation, timeFrames, analyticsProperty, defaultTimeFrameKey, openedEditBoxes) {
            const reminderInfo = {
                id: type,
                text: `${this.reminderTrans}.${translation}`,
                automationOn: this.company.getAutomationStatus(type),
                supportsTimeFrame: !this._.isEmpty(timeFrames),
                showEditBox: !!(openedEditBoxes && openedEditBoxes[type]),
                timeFrames,
                analyticsProperty: analyticsProperty
            };

            if (timeFrames) {
                reminderInfo.timeFrame = timeFrames[this.company.getAutomationTimeFrame(type)];
                if (!reminderInfo.timeFrame) {
                    reminderInfo.timeFrame = timeFrames[defaultTimeFrameKey];
                }
            }

            return reminderInfo;
        },

        buildInquiryReminder: function buildInquiryReminder(inquiryReminderData) {
            const inquiryInfo = {
                id: this.Enums.WorkflowAutomationTypes.inquiryReminder,
                text: `${this.reminderTrans}.FLOWS._INQUIRY_REMINDER_`,
                automationOn: inquiryReminderData['opt_in'],
                supportsTimeFrame: true,
                showEditBox: !!(this.openedTypes && this.openedTypes[this.Enums.WorkflowAutomationTypes.inquiryReminder]),
                timeFrames: this.inquiryReminderTimeFrames,
                analyticsProperty: this.inquiryReminderAnalytics
            };

            inquiryInfo.timeFrame = this.inquiryReminderTimeFrames[inquiryReminderData['timeframe_value']] || this.inquiryReminderTimeFrames[2];
            return inquiryInfo;
        },

        toggleActivityNotifications: function toggleActivityNotifications() {
            // Added setTimeout to fix issue where hb-click-outside is triggered right after the notifications are displayed
            // can be removed when we move 100% to new React notifications center
            setTimeout(function () {
                this.$rootScope.$broadcast('toggleActivityNotifications');
                this.$rootScope.$broadcast('setNotificationsSettingsOn');
                // for React (see NotificationsCenter)
                this.$window.document.dispatchEvent(
                    new CustomEvent('toggleActivityNotifications', {
                        detail: { openSettings: true }
                    })
                );
            }.bind(this), 1);
        },


        changeAutomationTimeFrame: function changeAutomationTimeFrame(automation, timeFrame) {
            automation.timeFrame = timeFrame;
            if (automation.id === this.Enums.WorkflowAutomationTypes.inquiryReminder) {
                const data = { timeframe_value: automation.timeFrame.value };
                this.CompaniesManager.updateInquiryFollowUpReminder(data);
            } else {
                this.company.setAutomationTimeFrame(automation.id, timeFrame.value);
            }
        },

        changeAutomationFlow: function changeAutomationFlow(automation, flow) {
            automation.flow = flow;
            this.company.setAutomationFlow(automation.id, flow);
        },

        changeAutomationEventType: function changeAutomationEventType(action, eventType) {
            var key = angular.isDefined(eventType) ? eventType._id : null;
            this.AutomationService.changeAutomationEventType(this.company, action, key);
        },

        changeAutomationTemplate: function changeAutomationTemplate(action, template) {
            this.AutomationService.changeAutomationTemplate(this.company, action, template);
        },

        changeAutomationAttachment: function changeAutomationAttachment(action, attachment) {
            if (action.questionnaireTemplate) {
                this.questionnaireChosen = true;
                this.brochureChosen = false;
                this.AutomationService.changeAutomationQuestionnaire(this.company, action, attachment);
            } else if (action.brochureTemplate) {
                this.brochureChosen = true;
                this.questionnaireChosen = false;
                this.AutomationService.changeAutomationBrochure(this.company, action, attachment);
            }
        },

        changeAutomationQuestionnaire: function changeAutomationQuestionnaire(action, questionnaire) {

            if (questionnaire) {

                this.questionnaireChosen = true;
                this.brochureChosen = false;
                this.AutomationService.changeAutomationQuestionnaire(this.company, action, questionnaire);

            } else {
                this.AutomationService.showMissingTemplate('questionnaire');
                var analyticsArgs = {
                    action_id: action.id
                };
            }
        },

        changeAutomationBrochure: function changeAutomationBrochure(action, brochure) {

            if (brochure) {
                this.brochureChosen = true;
                this.questionnaireChosen = false;
                this.AutomationService.changeAutomationBrochure(this.company, action, brochure);
            } else {

                this.AutomationService.showMissingTemplate('brochure');
                var analyticsArgs = {
                    action_id: action.id
                };
            }
        },

        clearAutomationAttachment: function clearAutomationAttachment(action) {
            this.brochureChosen = false;
            this.questionnaireChosen = false;
            this.AutomationService.clearAutomationAttachment(this.company, action);
        },

        onAutomationActionsListItemChange: function onAutomationActionsListItemChange(action, $index) {
            if (action.supportQuestionnaire && action.automationOn) {
                this.hasQuestionnaireTemplate('actions', $index).catch(function noTemplate() {
                    action.automationOn = false;
                });
            } else {
                this.toggleAutomation('actions', $index)
            }
        },

        toggleInquiryReminder: function(automationType, index) {
            var automationObj = this[automationType][index];
            if (automationObj.supportsTimeFrame) {
                this.showEditBox(automationType, index);
            }
            const data = { opt_in: automationObj.automationOn, timeframe_value: automationObj.timeFrame.value };
            var promise = this.CompaniesManager.updateInquiryFollowUpReminder(data);

            return promise;
        },

        toggleAutomation: function toggleAutomation(automationType, index, reminderId) {
            if (reminderId === this.Enums.WorkflowAutomationTypes.inquiryReminder) {
                this.toggleInquiryReminder(automationType, index);
            } else {
                var automationObj = this[automationType][index];
                var promise = this.AutomationService.toggleAutomation(this.company, automationObj);

                if (automationObj.supportEmail || automationObj.supportsTimeFrame) {
                    this.showEditBox(automationType, index);
                }

                return promise;
            }
        },

        toggleDefaultFileExpiration: function toggleDefaultFileExpiration() {
            this.defaultFileExpirationData.showEditBox = this.defaultFileExpirationData.automationOn;
            if (this.defaultFileExpirationData.automationOn) {
                this.setDefaultExpiration(this.autoExpirationModel.selected.serverValue);
            } else {
                this.setDefaultExpiration('never');
            }
        },

        toggleDefaultFileExpirationEditBox: function toggleDefaultFileExpirationEditBox() {
            this.defaultFileExpirationData.showEditBox = !this.defaultFileExpirationData.showEditBox;
        },

        hasQuestionnaireTemplate: function hasQuestionnaireTemplate(automationType, index) {
            if (this.company.questionnaires.length > 0) {
                return this.toggleAutomation(automationType, index);
            } else {
                return this.AutomationService.showMissingTemplate('questionnaire');
            }
        },

        showEditBox: function showEditBox(automationType, index) {
            var automationStatus = this[automationType][index].automationOn;
            var automationObj = this[automationType][index];

            if (!automationStatus && automationObj.showEditBox == false) {

            } else {
                var showEditBoxTemp = !this[automationType][index].showEditBox;
                this[automationType][index].showEditBox = showEditBoxTemp;
                this.openedTypes[this[automationType][index].id] = showEditBoxTemp;
            }
        },

        gotoEmailTemplates: function gotoEmailTemplates(action) {
            this.goToState(this.AppStates.root_core_navigation_templatesEditor, {
                templateModelKey: 'email',
                templateId: action.template._id
            });
        },


        /*
           // PROJECT TYPES and LEAD SOURCES //
        */

        initProjectTypesVm: function initProjectTypesVm() {
            this.projectTypes = angular.copy(this.company.projectTypesSort());
        },

        saveLeadSources: function saveLeadSources(leadSourcesList) {
            return this.CompaniesManager.updateLeadSources(this.company, leadSourcesList, this.shouldShowLeadSourcesEditedToast());
        },

        editLeadSources: function editLeadSources() {
            this.ModalService.openEditLeadSourceModal(
                this.company.sortedLeadSources(), this.saveLeadSources.bind(this), this.tagsAnalyticsArgs)
                .then(function (edited) {
                    if (edited) {
                        if (this.shouldShowLeadSourcesEditedToast()) {
                            this._showLeadSourcesEditedToast();
                        }
                    }
                }.bind(this));
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.edit_general_preferences, this.tagsAnalyticsArgs);
        },

        shouldShowLeadSourcesEditedToast: function shouldShowLeadSourcesEditedToast() {
            return !this.DeviceService.checkIfMobileSize() && this.user.isCompanyOwner() && this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.showLeadSourceToast, {show: true}).show;
        },

        editProjectTypes: function editProjectTypes() {

            var onProjectTypesSaved = function(projectTypes, defaultProjectTypeLabel) {
                var deferred = this.$q.defer();
                this.CompaniesManager.updateProjectTypes(this.company, projectTypes, defaultProjectTypeLabel).then(function() {
                    deferred.resolve();
                });
                return deferred.promise;
            }.bind(this);

            this.dissmissLeadSourceToast();
            this.ReactModalService.openEditProjectTypesModal('company settings', onProjectTypesSaved);
        },

        editContactFields: function editContactFields() {
            let source = 'company preferences';
            this.AnalyticsService.trackClick(this, 'customize contacts details', {source});
            this.ReactModalService.openCustomContactFieldsModal(source);
        },

        editTags: function editTags() {
            this.ReactModalService.openTagsModal();
        },

        editProjectDetails: function editProjectDetails() {
            this.ModalService.openProjectDetailsCustomizeModal(this.company);
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.edit_general_preferences, this.detailsAnalyticsArgs);
        },

        editProjectDetailsV2: function editProjectDetailsV2() {
            let source = 'company preferences';
            this.AnalyticsService.trackClick(this, 'customize project details', {source});
            this.ReactModalService.openCustomProjectFieldsModal(source);
        },

        toggleSmsRemindersEnabled: function toggleSmsRemindersEnabled() {
            this.company.sms_enabled = this.smsEnabled;
            this.CompaniesManager.updateSmsRemindersEnabled(this.company, this.smsEnabled);
        },

        toggleSmsPaymentRemindersEnabled: function toggleSmsPaymentRemindersEnabled() {
            this.company.sms_payment_reminders_enabled = this.smsPaymentRemindersEnabled;
            this.CompaniesManager.updateSmsPaymentRemindersEnabled(this.company, this.smsPaymentRemindersEnabled);
        },

        toggleFlowThumbnailVisibility: function toggleFlowThumbnailVisibility(){
            const transKey = 'COMPANY_SETTING.FLOW_DEFAULT_SETTINGS.VISIBILITY_MODAL_CONFIRMATION';
            const headerTextTransKey = this.company.is_flow_thumbnail_visible_to_client ? `${transKey}._HEADER_HIDE_TEXT_` : `${transKey}._HEADER_SHOW_TEXT_`;
            const subCopyTransKey = this.company.is_flow_thumbnail_visible_to_client ? `${transKey}._SUB_COPY_HIDE_TEXT_` : `${transKey}._SUB_COPY_SHOW_TEXT_`;
            const headerText = this.$translate.instant(headerTextTransKey);
            const subCopyText = this.$translate.instant(subCopyTransKey);
            const popUpDetails = {
                headerText,
                subCopyText,
                cancelBtn:`${transKey}._CANCEL_BTN_`,
                confirmBtn:`${transKey}._CONFIRM_BTN_`
            }

            this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.none ,
                popUpDetails.subCopyText,
                popUpDetails.confirmBtn,
                popUpDetails.cancelBtn,
                popUpDetails.headerText
                )
                .then(function() {
                    this.AnalyticsService.trackConfirm(this, this.AnalyticsService.analytics_events.toggle_thumbnail_visibility,`new value : ${this.isFlowThumbnailVisibleToClient}`);
                    this.CompaniesManager.updateThumbnailVisibleToClient(this.company, this.isFlowThumbnailVisibleToClient);

                    }.bind(this))

                .catch(function(){
                    this.AnalyticsService.trackCancel(this, this.AnalyticsService.analytics_events.toggle_thumbnail_visibility,`new value : ${this.company.is_flow_thumbnail_visible_to_client}`);
                    this.isFlowThumbnailVisibleToClient = !this.isFlowThumbnailVisibleToClient;
                    }.bind(this))
        },

        saveSpaces: function saveSpaces(spaces) {

            var _spaces = spaces.map(function (space) {
                return {
                    _id: space._id,
                    name: space.label
                };
            });

            return this.CompaniesManager.updateCompanySpaces(this.company, _spaces);
        },

        editSpaces: function editSpaces() {
            if (!this.user.isVenueVendor()) {
                return;
            }

            this.loadingSpaces = true;

            this.CompaniesManager.getCompanySpaces(this.company).then(function (res) {
                this.ModalService.openEditSpacesModal(
                    this._formatSpacesData(this.company.company_spaces), this.saveSpaces.bind(this), this.spacesAnalyticsArgs);
                this.loadingSpaces = false;
            }.bind(this));

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.edit_general_preferences, this.spacesAnalyticsArgs);
        },

        _formatSpacesData: function _formatSpacesData(spaces) {
            return spaces.map(function (space) {
                return {
                    _id: space._id,
                    label: space.name,
                    editable: true
                }
            });
        },

        _showLeadSourcesEditedToast: function _showLeadSourcesEditedToast() {
            var toastIcon = '<span class="icon icon-hb-nx-bell"></span>';
            var toastTitle = this.$translate.instant('LEADSOURCE.TOAST._TITLE_');
            var toastSubTitle = this.$translate.instant('LEADSOURCE.TOAST._SUB_TITLE_');
            var toastCtaUpdateText = this.$translate.instant('LEADSOURCE.TOAST._UPDATE_NOW_');
            var toastTextIntro = this.$translate.instant('LEADSOURCE.TOAST._YOU_CAN_');
            var toastTextReview = this.$translate.instant('LEADSOURCE.TOAST._REVIEW_');

            var dismiss = '<a ng-click="companySettingsAutomationVm.dissmissLeadSourceToast()" class="lead-sources-toast__close"><span class="icon icon-hb-nx-close-16"></span></a>';
            var toastCtaUpdate = '<a class="lead-sources-toast__cta" ng-click="companySettingsAutomationVm.updateContactForm()" >' + toastCtaUpdateText + '</a>';
            var toastCtaReview = '<div>' + toastTextIntro + '<a ng-click="companySettingsAutomationVm.reviewContactForm()" >' + toastTextReview + '</a>' + toastSubTitle + toastCtaUpdate + '</div>';
            var toastContent = '<div class="lead-sources-toast__content">' + '<div><strong>' + toastTitle + '</strong>' + toastCtaReview + '</div>';

            this.toastLeadSourceContent = '<div class="lead-source__toast">' + toastIcon + toastContent + dismiss + '</div>';

            this.toastLeadSource = this.ngToast.create({
                content: this.$sce.trustAsHtml(this.toastLeadSourceContent),
                className: 'lead-source_toast alert alert-info',
                dismissOnTimeout: false,
                compileContent: this.$scope
            });
        },

        reviewContactForm: function reviewEditedLeadSources() {
            this.ngToast.dismiss(this.toastLeadSource);
            this.$state.go(this.AppStates.root_core_navigation_templatesEditor, {templateModelKey: 'contactForm'});
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showLeadSourceToast, {show: false});
        },

        updateContactForm: function updateContactForm() {
            this.CompaniesManager.publishContactFormsLeadSource(this.company)
                .then(function success() {
                    this.ngToast.dismiss(this.toastLeadSource);
                    this._showConfirmationSavedContactFormToast();
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showLeadSourceToast, {show: false});
                }.bind(this));
        },

        _showConfirmationSavedContactFormToast: function _showConfirmationSavedContactFormToast() {
            this.$timeout(function () {
                this.ToastService.showSuccess({
                    contentTranslation: 'LEADSOURCE.TOAST._CONFIRMATION_SAVED_',
                    iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            }.bind(this), 1000);
        },

        dissmissLeadSourceToast: function dissmissLeadSourceToast() {
            this.ngToast.dismiss(this.toastLeadSource);
        },

        setFileNameSettings: function setFileNameSettings() {
            if (this.company.account && this.company.account.account_settings) {

                var templateNameSchema = this.fileNamesOptions.find(function (option) {
                    return option.key === this.company.account.account_settings.file_from_template_name;
                }.bind(this));

                if (templateNameSchema) {
                    this.fileFromTemplateName = templateNameSchema;
                }

                var workflowNameSchema = this.fileNamesOptions.find(function (option) {
                    return option.key === this.company.account.account_settings.file_from_workflow_name;
                }.bind(this));

                if (workflowNameSchema) {
                    this.fileFromWorkflowName = workflowNameSchema;
                }
            }
        },

        onFileNameSettingsChange: function onFileNameSettingsChange(value, fileNameSource) {

            // block if not allowed
            if (!this.shouldShowFileNameSettings) {
                return;
            }

            var isChanged = (
                fileNameSource === 'file_from_template_name' && value.key !== this.fileFromTemplateName.key ||
                fileNameSource === 'file_from_workflow_name' && value.key !== this.fileFromWorkflowName.key);

            if (isChanged) {
                var params = {};
                params[fileNameSource] = value.key;

                this.AccountsManager.updateAccountSettings(this.company.account, params)
                    .then(function success(res) {
                        this.company.account.account_settings = this._.extend({}, this.company.account.account_settings, res.data.account_settings);
                        this.setFileNameSettings();
                    }.bind(this));
            }
        },

        toggleBookingRating: function toggleBookingRating() {
            return this.AutomationService.toggleBookingRating(this.company);
        },
    });
}());