Directives.NavSmallScreensDirective = function NavSmallScreensDirective() {

    // @ngInject
    function NavSmallScreensControllerFunc($scope, $rootScope, $injector, $state, $translate, AppStates, ModalService, UsersManager, CompaniesManager, NavigationService,
                                           Enums, ReferralService, DeviceService, OnboardingService, UserService, PretrialersService, SetupGuideService, ClientPortalService,
                                           SearchV2Service, PopupMessageService,FlowsBetaUserTypeService, TemplatesViewService, ReactModalService, FeatureRestrictionService, GlobalBannerService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'NavSmallScreensDirectiveController';
        var self = this;

        this.$rootScope = $rootScope;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
        this.$state = $state;
        this.$translate = $translate;
        this.AppStates = AppStates;
        this.NavigationService = NavigationService;
        this.OnboardingService = OnboardingService;
        this.DeviceService = DeviceService;
        this.Enums = Enums;
        this.ModalService = ModalService;
        this.UserService = UserService;
        this.PretrialersService = PretrialersService;
        this.SetupGuideService = SetupGuideService;
        this.ClientPortalService = ClientPortalService;
        this.user = UsersManager.getCurrUser();
        this.company = CompaniesManager.getCurrCompany();
        this.subscription = this.user.getSubscription();
        this.SearchV2Service = SearchV2Service;
        this.PopupMessageService = PopupMessageService;
        this.TemplatesViewService = TemplatesViewService;
        this.ReactModalService = ReactModalService;
        this.FeatureRestrictionService = FeatureRestrictionService;
        this.GlobalBannerService = GlobalBannerService;                                          
        this.showClientAccessibility = this.UserService.shouldShowClientAccessibilityTopNav(this.user);

        //init
        this.clientPortalConfiguration = this.ClientPortalService.getConfiguration()
        this.showMobileMenu = this.PretrialersService.shouldShowCleanAtwsUI() ? this.PretrialersService.mobileNavigation : false;
        this.showSearchMobile = false; // old search. new search is a modal dlg.
        this.showActivityNotificationsMobile = false;
        this.showToolsMobile = false;
        this.ReferralService= ReferralService;
        this.isUserSubscribed = this.OnboardingService.isUserSubscribed();

        this.isNavPeople = NavigationService.isNavCurrentPeople();
        this.isNavContacts = NavigationService.isNavCurrentContacts();

        this.isUserClient = this.user.isClient();

        this.onboardingCardPersistence = this.SetupGuideService.getPersistenceHomeOnboardingCard();
        this.hasClickedCompleteMwebWizard = this.onboardingCardPersistence.hasClickedCompleteMwebWizard;
        this.isInAppBrowser  = this.DeviceService.isInAppBrowser();
        this.isUserVendor = this.user.isVendor();

        if (this.isUserVendor) {
            this.paymentsValueSum = this.UserService.paymentsValueSum;
        }

        // vendor notifications
        if (!this.isUserClient) {
            var activityNotifications = this.user.activity_notifications || [];
            this.totalUnseenNotifications = 0;
            activityNotifications.forEach(function (notification) {
                if (notification.seen === false) {
                    self.totalUnseenNotifications++;
                }
            });
        }

        this.showSearch = this.user.companyHasSubscription();

        // referral
        ////////////////////////////////////////////////////////
        // TODO : Remove from here
        // Need to use existing 'hb-refer-friend' directive,
        // but we need to rafactor it so it will fit here as
        // well. For now, extracted the logic from there
        ////////////////////////////////////////////////////////
        this.showReferrAFriend = false;
        if (this.user.companyHasSubscription()) {
            this.ReferralService.getCampaignDetails(this.user).then(function success(campaignDetails) {
                if (campaignDetails && campaignDetails.campaign_uid) {
                    this.showReferrAFriend = true;
                }
            }.bind(this));
        }
        ////////////////////////////////////////////////////////
        // END
        ////////////////////////////////////////////////////////


        this.visitedSignatureRoute = false;

        this.shouldShowAlmostComplete = SetupGuideService.setupGuideStats.completion === 99;
        this.isHideMobileNav = NavigationService.isHideMobileNav;

        if (this.user.isVendor() && this.company && this.company.subscribed && !this.isInAppBrowser &&
            !this.NavigationService.isRouteActive(this.AppStates.root_core_login)) {
                !function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "3b403aef-ea5b-4022-9bc8-389ce9cfa46d"}})
                AF('banners', 'showBanner');
            }
    }

    var NavSmallScreensController = Class(Controllers.BaseController, {
        constructor: NavSmallScreensControllerFunc,

        closeMobileMenu: function closeMobileMenu() {
            this.showMobileMenu = false;
        },

        goToRoute: function goToRoute(routeName, stateParams) {
            this.goToRouteName = routeName;
            this.closeMobileMenu();

            if (this.goToRouteName) {
                if (this.NavigationService.isRouteActive(this.goToRouteName)) {
                    return;
                }

                this.goToState(this.goToRouteName, stateParams);
            }
        },

        saveStateAndCloseMenu: function saveStateAndCloseMenu() {
            // saveCurrentState isn't a function anymore?
            // this.SetupGuideService.saveCurrentState();
            this.closeMobileMenu();
        },

        toggleMobileView: function goToMobileView(viewName) {
            switch (viewName) {

                case 'menu_mobile':
                    this.showMobileMenu = !this.showMobileMenu;
                    break;

                case 'search_mobile':
                    this.showSearchMobile = !this.showSearchMobile;
                    break;
            }
        },

        showSearchBox: function showSearchBox() {
            this.closeMobileMenu();
            this.SearchV2Service.open();
        },

        shouldShowSeePricing: function showSeePricing() {
            var isHomePage = this.$state.current.name === this.AppStates.root_core_navigation_home;
            return this.user.shouldBeAbleToUpgrade() && isHomePage && !this.user.isSubscriptionPaused();
        },

        getPricingText: function getPricingText() {
            if (this.user.nTillTrialEnds() > 0) {
                return this.$translate.instant(
                    'ONBOARDING.SETUP_GUIDE_MODAL.TRIAL_TEXT._X_DAYS_LEFT_', 
                    { remainingTrialDays: this.user.nTillTrialEnds() }
                )
            }
            return this.$translate.instant('MAIN_NAV._SEE_PRICING_');
        },

        _checkPreviousState: function _checkPreviousState(routeName) {
            if (this.$state.previous) {
                return this.$state.previous.route.name === routeName;
            }

            return false;
        },

        handleClickNavIcon: function handleClickNavIcon() {
            if (this.$state.$current.data.backToUrl) {
               return this.goToRoute(this.$state.$current.data.backToUrl)
            }

            this.NavigationService.goHistoryBack();
        },

        isCollapseIcon: function isCollapseIcon() {
            return (this.$state.$current.data &&
                   (this.$state.$current.data.backToState ||
                    this.$state.$current.data.backToHistoryOrState));
        },

        toggleNotificationsSettings: function toggleNotificationsSettings() {
            this.$rootScope.$broadcast('toggleNotificationsSettings');
        },

        onPlusButtonClick: function onPlusButtonClick() {
            this.NavigationService.onPlusButtonClickCallback();
        },

        onScheduleClick: function onScheduleClick() {
            this.$rootScope.$broadcast('handle-create-scheduling');
        }
    });

    return {
        templateUrl: 'angular/app/modules/core/features/top_nav/nav_small_screens/nav_small_screens_template.html',
        controller: NavSmallScreensController,
        controllerAs: 'navSmallVm',
        bindToController: true,
    };
};
