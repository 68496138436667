(function () {
  
    "use strict";

    Controllers.FinanceController = class FinanceController extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector, $sce, UsersManager, FinanceAppService, DeviceService, StatsigService) {
            super($scope, $injector);

            this.__objectType = 'FinanceController';
            this.user = UsersManager.getCurrUser();
            this.isInAppBrowser = DeviceService.isInAppBrowser();
            this.FinanceAppService = FinanceAppService;
            this.StatsigService = StatsigService;
            this.$sce = $sce;

            this._loadFinanceApp();
        }

        shouldLoadFinanceAppIframe() {
            return this._shouldLoadFinanceAppIframe;
        }

        getFinanceAppIframeUrl(fallback = '/') {
            return this.$sce.trustAsResourceUrl(`${this.FinanceAppService.getIframeUrl()}?callbackUrl=${fallback}`);
        }

        getFinanceAppIframeId() {
            return this.FinanceAppService.getIframeId();
        }

        _loadFinanceApp() {
            if (!this.isInAppBrowser) {
                Promise.all([this.FinanceAppService.isFinanceAppEnabled(this.user), this.StatsigService.isGateEnabled('finance_app_overview')]).then(([isEligible, isOverviewEnabled]) => {
                    if (isEligible || isOverviewEnabled) {
                        this._shouldLoadFinanceAppIframe = true;
                        this.FinanceAppService.loadFinanceApp(this.user);
                        this.FinanceAppService.listenToSignOut().then(() => {
                            this.goToState(this.AppStates.root_core_navigation_home);
                        });
                    }
                });
            }
        }
    };
}());
