(function () {
    "use strict";

    Controllers.BlockMwebController = class BlockMwebController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'BlockMwebController';
        }
    };

}());