(function () {
    "use strict";

    var STORAGE_KEY = 'REDIRECT_URL';

    // @ngInject
    function RedirectServiceCtor($window, $state, $stateParams, UsersManager, AppStates, AnalyticsService, jStorage,
                                 Constants, AppConfigService, OtamStatusService) {
        this.$state = $state;
        this.$window = $window;
        this.AppStates = AppStates;
        this.AnalyticsService = AnalyticsService;
        this.UsersManager = UsersManager;
        this.$stateParams = $stateParams;
        this.jStorage = jStorage;
        this.AppConfigService = AppConfigService;
        this.Constants = Constants;
        this.OtamStatusService = OtamStatusService;

        // TODO this is commented out because the loading HB unauthenticated triggers this event and then we deleted the local storage which ruins the whole point of using it
        // this.UsersManager.on('loggingOut', function onLogout() {
        //     this.resetLocalStorageData();
        // }.bind(this));
    }

    Services.RedirectService = Class(function () {


        return {

            constructor: RedirectServiceCtor,

            setToState: function setToState(name, params) {
                this.toState = name;
                this.toParams = params;

                // Saving to local storage so we'll not loose it if we refresh
                this.jStorage.set(STORAGE_KEY, {toState: name, toParams: params});
            },

            getCachedToState: function getCachedToState() {
                var localStorageData;
                if (!this.toState) {
                    // Try to load it from local storage if we don't have it in memory
                    localStorageData = this.jStorage.get(STORAGE_KEY);
                    if (!localStorageData) {
                        return;
                    }
                }

                var toState;
                if (localStorageData) {
                    var state = null;
                    if (localStorageData.toState.name) {
                        state = localStorageData.toState.name;
                    }
                    toState = {
                        name: state || localStorageData.toState,
                        params: localStorageData.toParams
                    };

                    this.resetLocalStorageData();
                } else {
                    toState = {
                        name: this.toState,
                        params: this.toParams
                    };

                    delete this.toState;
                    delete this.toParams;
                }

                return toState;
            },

            resetLocalStorageData: function resetLocalStorageData() {
                this.jStorage.deleteKey(STORAGE_KEY);
            },

            redirectFromReact: function redirectFromReact() {
                var user = this.UsersManager.getCurrUser();
                this.redirect(user);
            },

            redirect: function redirect(user) {

                var nextState = this.AppStates.root_core_navigation_home;
                var nextParams, nextOptions;
                var cachedRedirectState = this.getCachedToState();
                this.resetLocalStorageData();

                var workspaceId = this.$stateParams.workspace_id;
                var fileId = this.$stateParams.file_id;
                var eventId = this.$stateParams.event_id;
                var importJBTemplates = localStorage.getItem(this.Constants.storage.IMPORT_JB_TEMPLATES) === "1";
                var importPublicTemplate = sessionStorage.getItem(this.Constants.storage.IMPORT_PUBLIC_TEMPLATE);
                var templateToImport = importPublicTemplate ? JSON.parse(importPublicTemplate) : null;
                var importedTemplateId = user.imported_shared_template_id;
                const showOtamMigration =
                    this.OtamStatusService.isMigrationEnabledExp() &&
                    !this.OtamStatusService.isMigrated();
                var isMalkutHost = this.AppConfigService.isMalkutServer();
                if (user.isAdmin() && !isMalkutHost) {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.admin_login);
                    user.is_hb_admin = true;

                    this.$window.location.href = '/app/admin/login';
                    return;
                }
                else if (user.isMalkut() && isMalkutHost) {
                    nextState = this.AppStates.root_malkut_cs;
                }
                else if (workspaceId) {
                    nextState = this.AppStates.root_core_navigation_event_workspace_feed;
                    nextParams = {workspace_id: workspaceId, event_id: eventId};
                }
                else if (fileId) {
                    this.WorkspaceFileService.gotoWorkspaceFileById(fileId, null, null, true);
                    return;
                }
                else if (eventId) {
                    nextState = this.AppStates.root_core_navigation_event_overview;
                    nextParams = {event_id: eventId};
                }
                else if (importJBTemplates) {
                    nextState = this.AppStates.root_core_navigation_my_templates;
                }
                else if (templateToImport && showOtamMigration) {
                    nextState = this.AppStates.root_core_navigation_home;
                    nextParams = { pre_otam: 'inProduct' };
                }
                else if (importedTemplateId) {
                    this.AnalyticsService.track(this, "template saved", {
                        action: 'success',
                        original_template_id: templateToImport.template_id,
                        source: templateToImport.isGalleryTemplate === 'true' ? 'gallery_template' : 'member_template_sharing',
                        imported_template_id: importedTemplateId
                    });
                    nextState = this.AppStates.root_core_oneFlowWithAction;
                    nextParams = {flow_id: importedTemplateId, action: 'edit', oModalOnLoad: 1, importTemplateSuccess: 1, isGalleryTemplate: templateToImport.isGalleryTemplate };
                    sessionStorage.removeItem(this.Constants.storage.IMPORT_PUBLIC_TEMPLATE);
                }
                else if (this.UsersManager.shouldRedirectAfterLogin) {
                    nextState = this.UsersManager.shouldRedirectAfterLogin.state;
                    nextParams = this.UsersManager.shouldRedirectAfterLogin.stateParams;
                }
                else {
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.login);
                    user.is_hb_admin = false;

                    if (user.isBookkeeperMode()) {
                        nextState = this.AppStates.root_core_navigation_bookkeeping_reports_payments;
                        if (this.$state.current.name === nextState) {
                            nextOptions = {reload: true};
                        }
                    } else if (cachedRedirectState) {
                        nextState = cachedRedirectState.name;
                        nextParams = cachedRedirectState.params;
                    }
                }

                //if (!!user.desk_redirect){
                //    this.$window.location.href = res.data.redirect
                //}

                if ((user.isVendor() && this.isVendorSupportedBrowser(this.$window.navigator.userAgent)) ||
                    (!user.isVendor() && this.isClientSupportedBrowser(this.$window.navigator.userAgent))){
                    this.$state.go(nextState, nextParams, nextOptions);
                    return true;
                }
                else {
                    this.UsersManager.logout().then(function afterLogout() {
                        this.AnalyticsService.track(this, "browser not supported");
                        var unsupportedLink;
                        if(this.$window.location.origin){
                            unsupportedLink = this.$window.location.origin + "/unsupported";
                        } else {
                            //in ie 10 there is no origin property
                            unsupportedLink = this.$window.location.protocol + "//" + this.$window.location.hostname +
                                (this.$window.location.port ? ':' + this.$window.location.port: '') + "/unsupported";
                        }
                        this.$window.location.href = unsupportedLink;
                    }.bind(this));
                    return false;
                }
            },

            /**
             * checks if this is an iphone or ipad based browser that is based on apple webkit
             * @param userAgentString
             * @returns {boolean}
             */
            isIphoneOrIpadBrowserBased: function isIphoneOrIpadBrowserBased(userAgentString) {
                return (/(applewebkit)/i.test(userAgentString)) && (/(iphone|ipad)/i.test(userAgentString));
            },

            /**
             * returns true if the userAgentString shows that we are in a browser that is supported for clients
             * @param userAgentString
             */
            isClientSupportedBrowser: function clientSupportedBrowsers(userAgentString) {
                var ieVersion = this.checkIEVersion(userAgentString);
                return (/(opera|chrome|firefox|safari|applewebkit|edge)/i.test(userAgentString) || (ieVersion >= 10)) || this.isIphoneOrIpadBrowserBased();
            },

            /**
             * returns true if the userAgentString shows that we are in a browser that is supported for vendors
             * @param userAgentString
             */
            isVendorSupportedBrowser: function vendorSupportedBrowsers(userAgentString) {
                var isIE = this.checkIEVersion();
                if(isIE) {
                    return false;
                }

                return (/(chrome|firefox|safari|applewebkit|edge)/i.test(userAgentString)) || this.isIphoneOrIpadBrowserBased();
            },

            checkIEVersion: function checkIEVersion(userAgentString) {
                var ua = window.navigator.userAgent;

                var msie = ua.indexOf('MSIE ');
                if (msie > 0) {
                    // IE 10 or older => return version number
                    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
                }

                var trident = ua.indexOf('Trident/');
                if (trident > 0) {
                    // IE 11 => return version number
                    var rv = ua.indexOf('rv:');
                    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
                }

                // Edge is not IE we should allow
                // var edge = ua.indexOf('Edge/');
                // if (edge > 0) {
                //     // Edge (IE 12+) => return version number
                //     return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
                // }

                // other browser
                return false;
            }

        };
    });
}());
