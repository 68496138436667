(function () {
    'use strict';

    // @ngInject
    function NylasRedirectControllerCtor($scope, $injector, $location, $log, $q, InitialAppLoadParamsService, AnalyticsService, AppStates, UsersManager, PopupMessageService, Enums, $window) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'NylasRedirectController';
        AnalyticsService.trackPageView(this, 'NylasRedirectView');
        this.$log = $log;
        this.$q = $q;

        this.AppStates = AppStates;
        this.UsersManager = UsersManager;
        this.user = this.UsersManager.getCurrUser();
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.$window = $window;

        this.Enums = Enums;

        var queryStringParams = InitialAppLoadParamsService.getAllLoadParams();
        this.$log.info("queryStringParams debug: " + JSON.stringify(queryStringParams));
        InitialAppLoadParamsService.cleanParamsFromView(['code']);
        var stateAsObject = JSON.parse(queryStringParams.state);
        var originState = (stateAsObject && stateAsObject.origin_state) ? stateAsObject.origin_state : null;

        // access_token - Nylas API v2, code - Nylas API v3
        if (queryStringParams.access_token || queryStringParams.code) {
            this.UsersManager.setNylasAuthCode(queryStringParams)
                .then(function success() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.save_nylas_integration_auth_code);
                    this.redirectUserBackToOriginPage(originState);
                }.bind(this))
                .catch(function fail(resp) {
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.save_nylas_integration_auth_code, resp);

                    if (resp && resp.data && resp.data.error_type && resp.data.error_message && resp.data.error_type === "HBUserError") {

                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message, function success() {
                            this.redirectUserBackToOriginPage(originState);
                        }.bind(this));
                    }
                    else {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._TRY_AGAIN_', function success() {
                            this.redirectUserBackToOriginPage(originState);
                        }.bind(this));
                    }
                    this.$log.error('failed to save the auth-code on the nylas integration model');
                }.bind(this));

        } else {
            this.$log.error('Error in nylas authentication process: ' + $location.url());
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.nylas_integration_error);
            this.redirectUserBackToOriginPage(originState);
        }
    }

    Controllers.NylasRedirectController = Class(Controllers.BaseController, {
        constructor: NylasRedirectControllerCtor,

        setActiveEmailProvider: function setActiveEmailProvider() {
            return this.UsersManager.setActiveEmailProvider(this.user, 'nylas');
        },

        redirectUserBackToOriginPage: function redirectUserBackToOriginPage(originState) {
            switch (originState) {
                case 'calendar':
                    this.goToState(this.AppStates.root_core_navigation_calendar, {nylasIntegrationCreated: true});
                    this.$log.log('switching to the calendar view');
                    break;
                case 'email':
                    return this.setActiveEmailProvider().then(function success() {
                        this.goToState(this.AppStates.root_core_navigation_settings_company_integrations);
                        this.$log.log('switching to the account view on the setting');
                    }.bind(this));
                default:
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._TRY_AGAIN_');
                    this.$log.error('could not identify the origin_state code. this should not happen');
                    this.goToState(this.AppStates.root_core_navigation_settings_company_integrations);
                    break;
            }
        },

        goBackToPreviousStateOnError: function goBackToPreviousStateOnError(stateObject) {
            switch (stateObject.origin_state) {
                case 'calendar':
                    this.goToState(this.AppStates.root_core_navigation_calendar);
                    break;
                case 'gmail':
                    this.goToState(this.AppStates.root_core_navigation_settings_company_integrations);
                    break;
                case 'contacts':
                    this.goToState(this.AppStates.root_core_navigation_contacts);
                    break;
                default:
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._TRY_AGAIN_', function onPopupConfirm() {
                        this.goToState(this.AppStates.root_core_navigation_pipeline);
                    }.bind(this));
                    this.$log.error('could not identify the origin_state code. this should not happen');
                    break;
            }
        }
    });
}());

// Function.ubind = function (self) {
//     var f = this;
//     return function () {
//         f.apply(self, Array.prototype.slice.call(arguments, 0));
//     };
// };
