(function () {
    'use strict';

    class InteractiveOnboardingListController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            $translate,
            $state,
            $timeout,
            $,
            _,
            AppStates,
            EventService,
            OnboardingService,
            SetupGuideService,
            AnalyticsService,
            Enums,
            DeviceService,
            PopupMessageService,
            CompaniesManager,
            UsersManager,
            FlowsBetaUserTypeService,
            ReactModalService,
            IntentService
        ) {
            super($scope, $injector);
            this.__objectType = 'InteractiveOnboardingListController';

            this._ = _;
            this.$ = $;
            this.$timeout = $timeout;
            this.$translate = $translate;
            this.$state = $state;
            this.AppStates = AppStates;
            this.EventService = EventService;
            this.SetupGuideService = SetupGuideService;
            this.OnboardingService = OnboardingService;
            this.AnalyticsService = AnalyticsService;
            this.Enums = Enums;
            this.PopupMessageService = PopupMessageService;
            this.isMobile = DeviceService.nxSmallBreakpoint();
            this.CompaniesManager = CompaniesManager;
            this.UsersManager = UsersManager;
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
            this.company = this.CompaniesManager.getCurrCompany();
            this.user = this.UsersManager.getCurrUser();
            this.IntentService = IntentService;
            this.homePaymentsCardVariant = this.user.getExistingAbTestVariation('home_payments_poc');

            this.AnalyticsService.trackPageView(
                this,
                this.AnalyticsService.analytics_events.setup_guide,
                {
                    source: 'setup_guide_interactive',
                    setup_guide_variant: 'setup_guide_interactive',
                    phase: 'activation',
                    onboarding_type: this.onboardingType,
                    onboarding_abtest_name: this.onboardingAbTest && this.onboardingAbTest.name,
                    onboarding_abtest_variant: this.onboardingAbTest && this.onboardingAbTest.variant,
                    home_poc_abtest_variant: this.homePaymentsCardVariant
                }
            );

            this.isSequenceComplete = true;

            this.stepViewData = {
                subscribe: {
                    title: 'INTERACTIVE_ONBOARDING.STEP_SUBSCRIBE._TITLE_',
                    text: 'INTERACTIVE_ONBOARDING.STEP_SUBSCRIBE._TEXT_',
                    ctaText: 'INTERACTIVE_ONBOARDING.STEP_SUBSCRIBE._CTA_',
                    analytics: 'pricing',
                    ctaAction: () => {
                        this.goToState(this.AppStates.root_core_navigation_settings_companySettings_subscription);
                    },
                },
                account: {
                    title: 'INTERACTIVE_ONBOARDING.STEP_ACCOUNT_IN_LIST._TITLE_',
                    text: 'INTERACTIVE_ONBOARDING.STEP_ACCOUNT_IN_LIST._TEXT_',
                    ctaText: 'INTERACTIVE_ONBOARDING.STEP_ACCOUNT_IN_LIST._CTA_',
                    analytics: 'account',
                    ctaAction: () => {
                        this.goToState(this.AppStates.root_core_navigation_settings_account);
                    },
                },
                templates: {
                    title: 'INTERACTIVE_ONBOARDING.STEP_TEMPLATES._TITLE_',
                    text: 'INTERACTIVE_ONBOARDING.STEP_TEMPLATES._TEXT_',
                    ctaText: 'INTERACTIVE_ONBOARDING.STEP_TEMPLATES._CTA_',
                    analytics: 'templates',
                    ctaAction: () => {
                        this.goToState(this.AppStates.root_core_navigation_templates);
                    },
                    secondaryCtaText: 'INTERACTIVE_ONBOARDING.STEP_TEMPLATES._LINK_',
                    secondaryAnalytics: 'account setup',
                    secondaryCtaAction: () => {
                        this.goToState(this.AppStates.root_core_accountSetup, { importFiles: true });
                    }
                },
                clients: {
                    title: 'INTERACTIVE_ONBOARDING.STEP_CLIENTS._TITLE_',
                    text: 'INTERACTIVE_ONBOARDING.STEP_CLIENTS._TEXT_',
                    ctaText: 'INTERACTIVE_ONBOARDING.STEP_CLIENTS._CTA_',
                    analytics: 'clients',
                    ctaAction: () => {
                        this.AnalyticsService.trackClick(
                            this,
                            'create client',
                            { source: 'setup guide' }
                        );
                        return ReactModalService.openGlobalModal('AddClientFromContactsPageModalWrapper', {}).then(()=> this.OnboardingService.onAddClientDone())
                    }
                },
                template_gallery: {
                    title: 'INTERACTIVE_ONBOARDING.STEP_TEMPLATE_GALLERY._TITLE_',
                    text: 'INTERACTIVE_ONBOARDING.STEP_TEMPLATE_GALLERY._TEXT_',
                    ctaText: 'INTERACTIVE_ONBOARDING.STEP_TEMPLATE_GALLERY._CTA_',
                    analytics: 'template_gallery',
                    ctaAction: () => {
                        this.OnboardingService.onFlowsTemplateGalleryVisited();
                        this.goToState(this.AppStates.root_core_navigation_flows_template_gallery);
                    }
                },
                account_setup: (() => {
                    let ctaText, title, state;
                    if (!this.company.hasUploadedMaterials()) {
                        title = 'INTERACTIVE_ONBOARDING.STEP_ACCOUNT_SETUP._TITLE_';
                        ctaText = 'INTERACTIVE_ONBOARDING.STEP_ACCOUNT_SETUP._CTA_';
                        state = this.AppStates.root_core_accountSetup;
                    } else if (this.company.canUploadMaterials() || this.company.hasPendingMaterialsUpload()) {
                        title = 'INTERACTIVE_ONBOARDING.STEP_ACCOUNT_SETUP.UPLOADED._TITLE_';
                        ctaText = 'INTERACTIVE_ONBOARDING.STEP_ACCOUNT_SETUP.UPLOADED._CTA_';
                        state = this.AppStates.root_core_accountSetup;
                    } else {
                        title = 'INTERACTIVE_ONBOARDING.STEP_ACCOUNT_SETUP.UPLOADED._TITLE_';
                        ctaText = 'INTERACTIVE_ONBOARDING.STEP_ACCOUNT_SETUP.COMPLETED._CTA_';
                        state = this.AppStates.root_core_navigation_my_templates;
                    }
                    return {
                        title,
                        text: 'INTERACTIVE_ONBOARDING.STEP_ACCOUNT_SETUP._TEXT_',
                        ctaText,
                        analytics: 'account setup',
                        ctaAction: () => this.goToState(state),
                        shouldShowConditionalText: this.shouldShowMarkAcctSetupCompleteText(),
                        conditionalText: 'INTERACTIVE_ONBOARDING.STEP_ACCOUNT_SETUP._CONDITIONAL_TEXT_',
                        secondaryCtaText: 'INTERACTIVE_ONBOARDING.STEP_ACCOUNT_SETUP._CONDITIONAL_LINK_TEXT_',
                        secondaryCtaAction: () => this.openConfirmMarkAccountSetupCompleteModal()
                    };
                })(),
                bank_info: {
                    title: 'INTERACTIVE_ONBOARDING.STEP_BANK_INFO._TITLE_',
                    text: 'INTERACTIVE_ONBOARDING.STEP_BANK_INFO._TEXT_',
                    ctaText: 'INTERACTIVE_ONBOARDING.STEP_BANK_INFO._CTA_',
                    analytics: 'bank account',
                    ctaAction: () => {
                        this.goToState(this.AppStates.root_core_navigation_settings_company_bankInfo);
                    },
                },
                send_flow_test: {
                    title: 'INTERACTIVE_ONBOARDING.STEP_SEND_TEST._TITLE_',
                    text: 'INTERACTIVE_ONBOARDING.STEP_SEND_TEST._TEXT_',
                    ctaText: 'INTERACTIVE_ONBOARDING.STEP_SEND_TEST._CTA_',
                    analytics: 'send test',
                    ctaAction: () => ReactModalService.openSendTestOnboardingModal(),
                },
                contact_form: {
                    title: 'INTERACTIVE_ONBOARDING.STEP_CONTACT_FORM._TITLE_',
                    text: 'INTERACTIVE_ONBOARDING.STEP_CONTACT_FORM._TEXT_',
                    ctaText: 'INTERACTIVE_ONBOARDING.STEP_CONTACT_FORM._CTA_',
                    analytics: 'contact form',
                    ctaAction: () => {
                        this.goToState(this.AppStates.root_core_navigation_templatesEditor, {templateModelKey: 'contactForm'});
                    },
                },
                public_flow: {
                    title: 'INTERACTIVE_ONBOARDING.STEP_PUBLIC_FLOW._TITLE_',
                    text: 'INTERACTIVE_ONBOARDING.STEP_PUBLIC_FLOW._TEXT_',
                    ctaText: 'INTERACTIVE_ONBOARDING.STEP_PUBLIC_FLOW._CTA_',
                    analytics: 'public flow',
                    ctaAction: () => {
                        const stepStatus = this._.find(this.steps, { key: 'public_flow' });
                        if (stepStatus && stepStatus.status === 'complete') {
                            $state.go(AppStates.root_core_navigation_lead_capture);
                        } else {
                            ReactModalService.openPublicFlowModal();
                        }
                    },
                },
                cha_ching: {
                    title: 'INTERACTIVE_ONBOARDING.STEP_CHA_CHING._TITLE_',
                    text: 'INTERACTIVE_ONBOARDING.STEP_CHA_CHING._TEXT_',
                },
                services_hub: {
                    title: 'INTERACTIVE_ONBOARDING.STEP_SERVICES_HUB._TITLE_',
                    text: 'INTERACTIVE_ONBOARDING.STEP_SERVICES_HUB._TEXT_',
                    ctaText: 'INTERACTIVE_ONBOARDING.STEP_SERVICES_HUB._CTA_',
                    analytics: 'services hub',
                    ctaAction: () => {
                        ReactModalService.openAddServiceTemplateModal({
                             source: 'setup_guide',
                             onCreateSuccess: this.OnboardingService.onServiceTemplateCreated
                        });
                    }
                }
            };

            this.proTipBoldText = $translate.instant('INTERACTIVE_ONBOARDING._PRO_TIP_');
            this.proTipText = $translate.instant('INTERACTIVE_ONBOARDING._PRO_TIP_TEXT');
            this.proTipLinkTitle = $translate.instant('INTERACTIVE_ONBOARDING._PRO_TIP_LINK_TEXT');
            this.abTestIntentInfraVariation = this.user.getExistingAbTestVariation(this.Enums.ABTests.intentInfra);
            this.showIntentBanner = this.shouldShowIntentBanner();
        }

        $onInit() {
            const lastStep = this._.max(this.steps, step => step.order);

            const chaChingStep = {
                key: 'cha_ching',
                status: 'pending',
                score: 0,
                order: (lastStep.order + 1)
            };

            const steps = this.steps;
            if (!this.FlowsBetaUserTypeService.hasOnlyFlows) {
                steps.push(chaChingStep);
            }

            this.stepsWithViewData = steps
                .map(step => {
                    const stepViewData = this.stepViewData[step.key];
                    return this._.extend(step, stepViewData);
                });
        }

        shouldShowMarkAcctSetupCompleteText() {
            const stepsOtherThanAccountSetupAreComplete =
                this._.chain(this.steps)
                    .filter(s => s.key !== this.Enums.onboardingSteps.accountSetup)
                    .every(s => s.status === 'complete')
                    .value();

            const accountSetupStep = this._.find(this.steps, s => s.key === this.Enums.onboardingSteps.accountSetup)
            const accountSetupStepIsNotComplete = accountSetupStep && accountSetupStep.status !== 'complete';

            return stepsOtherThanAccountSetupAreComplete && accountSetupStepIsNotComplete;
        }

        stepShouldShowAsComplete(step) {
            return (
                // Normal case
                step.status === 'complete'
                // Synchronous exception for account_setup
                || ((step.key === this.Enums.onboardingSteps.accountSetup) && this.accountSetupIsComplete)
            )
        }

        openConfirmMarkAccountSetupCompleteModal() {
            const successHandler = () => {
                this.accountSetupIsComplete = true;
                this.OnboardingService.onAccountSetupFilesUploaded();
                this.AnalyticsService.trackClick(
                    this,
                    this.AnalyticsService.analytics_events.skip_account_setup,
                    { source: 'setup_guide' }
                );
            }

            this.PopupMessageService.showConfirm(
                this.PopupMessageService.severityTypes.info,
                'INTERACTIVE_ONBOARDING.STEP_ACCOUNT_SETUP._CONFIRM_MARK_COMPLETE_',
                successHandler,
                () => {} // Reject handler, do nothing
            )
        }

        handleCloseMwebWizard() {
            this.completeMwebWizard();
        }

        shouldShowIntentBanner() {
            return this.IntentService.isInIntentTestGroup();
        }

        proTipLinkClick() {
            this.AnalyticsService.trackClick(
                this,
                this.AnalyticsService.analytics_events.go_to_company_settings
            );
            this.$state.go(this.AppStates.root_core_navigation_settings_company_general);
        }
    }

    Components.InteractiveOnboardingList = {
        controller: InteractiveOnboardingListController,
        controllerAs: 'interactiveOnboardingListVm',
        bindings: {
            steps: '<',
            currentStep: '<',
            isCollapsed: '<',
            isSequenceComplete: '<',
            isMarkedComplete: '=',
            handleMarkedComplete: '&',
            completeMwebWizard: '&',
            listTitle: '@',
            listSubtitle: '@',
            hasSubtitleChaching: '<',
            onboardingType: '<',
            onboardingAbTest: '<',
        },
        name: 'hbInteractiveOnboardingList',
        templateUrl: 'angular/app/modules/core/features/onboarding/interactive_onboarding/int_onb_list/int_onb_list.html',
    };
}());
