/**
 * Created by inonstelman on 12/28/14.
 */

// @ngInject

Models.UserModel = function UserModel(BaseModel, UsersManager, AppConfigService, CompanyService, Enums, $, $log, moment, _, ImageService, FeaturesService, AvatarService, SchedulingManager, ModelFactory, VideoConferencingManager){

    var hbUserTypes = {
        regular: 'regular',
        demo: 'demo',
        malkut: 'malkut',
        admin: 'admin',
        pro: 'pro',
        bookkeeper: 'bookkeeper',
        honeybook_invoicing: 'honeybook_invoicing',
        honeybook_paying: 'honeybook_paying'
    };

    var hbPermissions = {
        malkut_cs: 'malkut_cs',
        malkut_transfers: 'malkut_transfers',
        malkut_fraud: 'malkut_fraud',
        malkut_recipients: 'malkut_recipients',
        malkut_venue_onboarding: 'malkut_venue_onboarding',
        community_moderator: 'community_moderator',
        restrict_access: 'restrict_access',
        manage_pricing: 'manage_pricing',
        malkut_pricing_tiers: 'malkut_pricing_tiers',
        malkut_home: 'malkut_home',
        malkut_access_documents: 'malkut_access_documents'
    };

    var UserModelClass = Class(BaseModel, {

        constructor: function constructor(){
            UserModelClass.$super.call(this);
            this.__className = 'UserModel';

            this.isMemberOfWorkspace = false;
            this.workspaceId = null;
            this.bookkeeperModeCompanyId = null;
            this.consultants_adjustments_feature_date = '2023-07-09';
            this.subtype_consultants_adjustments_feature_date = '2023-09-10';
            this.msp_company_types_feature_date = '2024-03-11';
            this.services_hub_feature_date = '2024-04-03';
            this.top_nav_2023_feature_date = '2024-04-24';
            this.new_iio_service_templates_feature_date = '2024-05-22'
            this.audited_companies = null;

        },

        mapChilds: function mapChilds(){
            return {
                creator: 'UserModel',
                company: 'CompanyModel',
                audited_companies: 'CompaniesCollection',
                network: 'NetworkUserModel',
                session_configs: 'SessionsCollection'
            };
        },

        afterMixingFrom: function afterMixingFrom(object){
        },

        /**
         * Override this.cachable when you don't want the models to be cached (always fetched from server)
         */
        isCachable: function isCachable() {
            return false;
        },

        _dataOnlyInterceptor: function _dataOnlyInterceptor(copy) {
            delete copy.activity_notifications;
            delete copy.mobile_notifications;
        },

        getAccount: function getAccount() {
            return this.company && this.company.account;
        },

        getSubscription: function getSubscription() {
            const account = this.account;
            if(account && account.subscription) {
                const subscription = ModelFactory.newSubscriptionModel();
                subscription.mixinFrom(account.subscription, null, false);
                return subscription;
            }
        },

        getTrial: function getTrial() {
            return this.getAccount() && this.getAccount().trial;
        },

        isFeatureActive: function isFeatureActive(feature, variation) {
            variation = variation || 'on';
            return (this.features_variations && this.features_variations[feature] === variation) || // splitio
                   (this.features_active && this.features_active.indexOf(feature) !== -1); // mini user db based feature flag
        },

        isFlagEnabled : function isFlagEnabled(name){
            let account = this.getAccount();
            return !!(account && account[name]);
        },
        // for view all in pipeline rollout.
        isViewAllEnabled: function isViewAllEnabled() {
            return this.es_version === '7-company-stages';
        },

        setAsMemberOfWorkspace: function setAsMemberOfWorkspace(workspaceId){
            this.isMemberOfWorkspace = true;
            this.workspaceId = workspaceId;
        },

        setSystemUserType: function setSystemUserType(type){
            this.system_user_type = type;
        },

        isTwoFactorAuthLoginEnabled: function isTwoFactorAuthLoginEnabled(){
            return this.login_2fa_enabled;
        },

        isPendingSetupTwoFactorAuth: function isWaitingToSetupTwoFactorAuth(){
            return this.isTwoFactorAuthLoginEnabled() && !this.phone_number_for_two_factor_auth;
        },

        isTempPassLogin: function isTempPassLogin(){
            return this.is_temp_pass_login;
        },

        notFirstSession: function notFirstSession(){
            if(this.getTrial()){
                return (new Date() - new Date(this.getTrial().start_date))/3600000 > 1;
            }
            return false;
        },

        hoursAfterTrialStarted: function hoursAfterTrialStarted(hours){
            if(this.getTrial()){
                return (new Date() - new Date(this.getTrial().start_date))/3600000 >= hours;
            }
            return false;
        },

        calendarDaysAfterTrialStarted: function calendarDaysAfterTrialStarted(days){
            if(this.getTrial()){
                return (moment().diff(this.getTrial().start_date, 'days') > days);
            }
            return false;
        },

        startedTrialAfterDate: function startedTrialAfterDate(dateString){ //example dateString: '2019-01-01'
            var startedOnboardingAfterDate = this.getTrial() && moment(dateString).isBefore(this.getTrial().start_date);
            return startedOnboardingAfterDate;
        },

        getTrialStartDate: function getTrialStartDate(){
            let trial = this.getTrial();
            if (trial) {
                return trial.start_date;
            }
            return null;
        },
        getSubscriptionStartDate: function getSubscriptionStartDate(){
            let subscription = this.getSubscription();
            if (subscription) {
                return subscription.start_date;
            }
            return null;
        },
        setSystemUserTypeAndWorkspaceRole: function setSystemUserTypeAndWorkspaceRole(type){

            if (!this._id || !this.system_user_type) {
                this.system_user_type = type.system_user_type;
            }

            if (this.isMemberOfWorkspace){
                this.workspace_user_data.workspace_role = type.workspace_role;
            }
        },

        _isHbUserType: function _isHbUserType(type){

            if(angular.isArray(this.hb_user_type)){
                return this.hb_user_type.indexOf(type) >= 0;
            }

            return false;
        },

        isClient: function isClient(){
            return this.system_user_type === 'client';
        },

        isVendor: function isVendor(){
            return this.system_user_type === 'vendor';
        },

        isAdmin: function isAdmin(){
            return this._isHbUserType(hbUserTypes.admin);
        },

        toggleBookkeeperMode: function toggleBookkeeperMode(isBkpr, companyId) {
            if (isBkpr) {
                let company = companyId ? this.audited_companies.find(a => a._id === companyId) : this.audited_companies[0];
                if (company) {
                    this.setBookkeeperContext(company._id);
                }  else {
                    // To do - Empty state for if the company doesnt exist or user has no companies
                    return;
                }
            } else {
                this.setBookkeeperContext(null);
            }
        },

        setBookkeeperContext: function setBookkeeperContext(companyId) {
            this.bookkeeperModeCompanyId = companyId;
        },

        bookkeeperModeCompany: function bookkeeperModeCompany(){
            if (!this.isBookkeeperMode()) {
                return null;
            }

            return this.audited_companies.find(comp => comp._id === this.bookkeeperModeCompanyId);
        },

        isBookkeeperMode: function isBookkeeperMode() {
            return !!this.bookkeeperModeCompanyId;
        },

        // Indicating that this login was done by admin login, if u need to use please consider creating infra for it
        isAdminLogin: function isAdminLogin(){
            return !!this.is_hb_admin;
        },

        isBookkeeper: function isBookkeeper() {
            return this._isHbUserType(hbUserTypes.bookkeeper) || this.is_bookkeeper;
        },

        isProLogin: function isProLogin(){
            return this.is_pro_login;
        },

        isExternalUser : function isExternalUser(){
            return this.isProLogin() || this.isAdminLogin() || this.isTempPassLogin();
        },

        isMalkut: function () {
          return this._isHbUserType(hbUserTypes.malkut);
        },

        isDemo: function isDemo(){
            return this._isHbUserType(hbUserTypes.demo);
        },

        isPro: function isPro(){
            return this._isHbUserType(hbUserTypes.pro);
        },

        isUserFullyLoaded: function isUserFullyLoaded(){
            return this.__isUserFullyLoaded;
        },

        isHBInvoicing: function isHBInvoicing(){
            return this._isHbUserType(hbUserTypes.honeybook_invoicing);
        },

        isEmailVerified: function isEmailVerified(){
            return !!this.email_verified;
        },

        _hasPermission: function (permission) {
            if(angular.isArray(this.hb_system_permissions)){
                return this.hb_system_permissions.indexOf(permission) >= 0;
            }
            return false;
        },

        hasMalkutFraudPremission: function () {
            return this._hasPermission(hbPermissions.malkut_fraud);
        },

        hasMalkutAccessDocumentsPremission: function () {
            return this._hasPermission(hbPermissions.malkut_access_documents) || this._hasPermission(hbPermissions.malkut_fraud);
        },

        hasMalkutPricingPermissions: function () {
            return this._hasPermission(hbPermissions.manage_pricing);
        },

        hasMalkutCsPremission: function () {
            return this._hasPermission(hbPermissions.malkut_cs);
        },

        hasMalkutTransfersPremission: function () {
            return this._hasPermission(hbPermissions.malkut_transfers);
        },

        hasMalkutRecipientsPremission: function () {
            return this._hasPermission(hbPermissions.malkut_recipients);
        },

        hasMalkutVenueOnboardingPremission: function () {
            return this._hasPermission(hbPermissions.malkut_venue_onboarding);
        },

        hasCommunityModeratorPermission: function () {
            return this._hasPermission(hbPermissions.community_moderator);
        },

        hasMalkutRestrictAccessPermission: function () {
            return this._hasPermission(hbPermissions.restrict_access);
        },

        hasMalkutPricingTiersPermission: function () {
            return this._hasPermission(hbPermissions.malkut_pricing_tiers);
        },

        hasMalkutHomePermission: function () {
            return this._hasPermission(hbPermissions.malkut_home);
        },


        isHoneyBookPaying: function () {
            return this._isHbUserType(hbUserTypes.honeybook_paying);
        },

        // user is active if logged-in in the past
        isActiveUser: function isActiveUser(){
            return !!this.last_login_time;
        },

        isActivated: function isActivated() {
            return !!this.is_activated;
        },

        getUserStatus: function getUserStatus() {
            if (this.subscriptionCanceled())
            {
                if(this.isHoneypaid()) {
                    return Enums.UserStatus.activatedSubChurn;
                }
                return Enums.UserStatus.subCancel;
            }
            if (this.companyHasSubscription()) {
                if (this.isActivated()) {
                    return Enums.UserStatus.activated;
                }
                return Enums.UserStatus.subscribed;
            }
            if(this.isHoneypaid()) {
                return Enums.UserStatus.honeypaid;
            }
            if (this.isInTrial()) {
                return Enums.UserStatus.trial;
            }
            if (this.hasTrialExpired()) {
                return Enums.UserStatus.trialExpired;
            }
            return Enums.UserStatus.preTrialer;
        },

        isMobileActive: function isMobileActive() {
            return this.is_mobile_active;
        },

        isCommunitySource: function isCommunitySource() {
            return this.attribution && this.attribution.user_source_primary === "network";
        },

        myBankAccount: function myBankAccount(){

            if (Array.isArray(this.payment_methods) && this.company && this.company.bank_account_user_id === this._id){

                return this.payment_methods.find( function(pm){
                    return pm._id === this.company.bank_account_payment_method_id;
                }.bind(this));
            }

            return undefined;
        },

        myDebitCard() {
            var bank_account = this.myBankAccount();

            if (!bank_account || !bank_account.debit_card_payment_method_id) {
                return null;
            }

            return this.payment_methods.find((method) => bank_account.debit_card_payment_method_id === method._id && method.card_role.includes('instant_payout'));
        },

        printDetails: function printDetails(){
            return this.full_name + ' (' + this.email + ') | id: ' + this.id();
        },

        updateCurrentUserProperties: function updateCurrentUserProperties (userData) {
            return UsersManager.updateCurrentUserProperties(userData);
        },

        changePassword: function changePassword(data) {
            return UsersManager.changePassword(data);
        },

        changePasswordSuccessful: function changePasswordSuccessful() {
            UsersManager.changePasswordSuccessful();
        },

        addPaymentMethod: function addPaymentMethod(paymentMethod, type, file_id) {
            paymentMethod.id = null;
            paymentMethod._type = type || Enums.PaymentMethodTypes.creditCard;
            return UsersManager.addPaymentMethod(this, paymentMethod, file_id);
        },

        getValidPaymentMethods: function getValidPaymentMethods(file_id, only_for_vendor_account_settings){
            return UsersManager.getValidPaymentMethods(this, file_id, only_for_vendor_account_settings);
        },

        deletePaymentMethod: function deletePaymentMethod(methodId, forceDelete) {
            return UsersManager.deletePaymentMethod(this, methodId, forceDelete);
        },

        addBankAccount: function addBankAccount(account, company, smsCode){
            return UsersManager.addBankAccount(this, account, company, smsCode);
        },

        deleteBankAccount: function deleteBankAccount(account){
            return UsersManager.deleteBankAccount(this, account);
        },

        deselectBankAccount: function deselectBankAccount(accountId) {
            console.log('user model', this._id);
            console.log('user model', accountId);
            return UsersManager.deselectBankAccount(this._id, accountId);
        },

        // Workspace Member functions
        isActiveMemberInWorkspace: function isActiveMemberInWorkspace() {

            if (!this.isMemberOfWorkspace || !this.workspace_user_data) {
                return false;
            }

            return this.workspace_user_data.workspace_member_status === "member_active";
        },

        isInviteSentToWorkspace: function isActiveInWorkspace() {
            if (!this.isMemberOfWorkspace) {
                $log.log("ERROR, should not call this function if not a model of a member");
                return false;
            }
            return this.workspace_user_data.workspace_member_status === "member_invite_sent";
        },

        hasCompanyAdminPermissions: function hasCompanyAdminPermissions() {
            return CompanyService.hasCompanyAdminPermissions(this, this.company);
        },

        hasCompanySuperAdminPermissions: function hasCompanySuperAdminPermissions() {
            return CompanyService.hasCompanySuperAdminPermissions(this, this.company);
        },

        trialDenomination: function trialDenomination() {
            return this.getTrial() && this.getTrial().type;
        },

        hasCompanyModeratorPermissions: function hasCompanyModeratorPermissions() {
            return CompanyService.hasCompanyModeratorPermissions(this, this.company);
        },

        saveDefaultSignature: function saveDefaultSignature(signature) {
            return UsersManager.saveDefaultSignature(this, signature);
        },

        isInTrial: function isInTrial() {
            if (this.getTrial()){
                return !this.getTrial().ended;
            }
            // if the user does not have a trial object on it's company than the default is not blocking the user
            // consider the user in trial
            return false;
        },

        isReferralTrialer: function isReferralTrialer() {
            var referral = this.referral_container && this.referral_container.is_referral_trialer;
            var mbsy = this.attribution && this.attribution.user_utm_params && this.attribution.user_utm_params.utm_mbsy;
            return referral || mbsy;
        },

        isPaidDigitalDiscountTrialer: function isPaidDigitalDiscountTrialer() {
            return this.attribution.user_utm_params && this.attribution.user_utm_params.utm_origin;
        },

        isInTrialAndNotSubscribed: function isInTrialAndNotSubscribed(){
            // True test for if user is a trialer. Does not include users who have paid subscription or never had
            // trial set
            if(this.company){
                if(this.company.subscribed){
                    return false;
                } else if (this.getTrial()){
                    return !this.getTrial().ended;
                } else if (this.getTrial() === null){
                    return false;
                }
            }
            return true;
        },

        companyHasSubscription: function companyHasSubscription() {
            if(this.company){
                return this.company.subscribed;
            }

            // the default should be false
            return false;
        },

        subscriptionCanceled: function subscriptionCanceled() {
            return Boolean(this.account &&
                this.account.pricing_plan &&
                this.account.pricing_plan.canceled);
        },


        companyHasWebSubscription: function companyHasWebSubscription() {
            if(this.company){
                return this.company.subscribed && this.account.pricing_plan && !this.account.pricing_plan.in_app_purchase_device;
            }

            // the default should be false
            return false;
        },

        isSubscriptionPaused: function companyHasSubscription() {
            if(this.account && this.account.subscription){
                return this.account.subscription.state === 'paused';
            }

            // the default should be false
            return false;
        },

        companyHasFreeSubscription: function companyHasFreeSubscription() {
            if(this.company){
                return this.company.subscribed_for_free;
            }

            // the default should be false
            return false;
        },

        companyHasAnySubscription: function companyHasAnySubscription() {
            return this.companyHasSubscription() || this.companyHasFreeSubscription();
        },

        hasTrialExpired: function hasTrialExpired() {
            return this.getTrial() && this.getTrial().ended;
        },

        notSelectedNextPlanCode: function notSelectedNextPlanCode() {
            return this.isStarterPlan()
                && (this.account.selected_next_plan_code === null || this.account.selected_next_plan_code === "disable");
        },

        isStarterPlan: function isStarterPlan() {
            return this.account.subscription
                && this.account.subscription.plan_code === Enums.pricingPlanTypes.starterMonthly;
        },

        shouldStartTrial: function shouldStartTrial() {
            if (this.isVendor() &&
                this.company &&
                this.getTrial() == null &&
                this.company.subscribed === false) {

                return true;
            }
            return false;
        },

        isCreatedOverAMonthAgo: function (isCreatedOverAMonthAgo) {
            return moment(this.created_at).isBefore(moment().subtract(1, 'months'));
        },

        isCreatedBefore: function isCreatedBefore(value, units) {
            return moment(this.created_at).isBefore(moment().subtract(value, units));
        },

        isNetworkPretrialer: function isNetworkPretrialer() {
            return (this.attribution && this.attribution.user_source_primary === 'network' && this.shouldStartTrial());
        },

        isProductPretrialer: function isProductPretrialer() {
            return (this.attribution && this.attribution.user_source_primary === 'product' && this.shouldStartTrial());
        },

        hasTrialExpiredDueToNoPayment: function hasTrialExpiredDueToNoPayment() {
            return this.hasTrialExpired() && !this.companyHasSubscription();
        },

        isTrialEnded: function isTrialEnded() {
            return this.account && this.account.trial && this.account.trial.ended;
        },

        shouldSeeTrialWarnings: function shouldSeeTrialWarnings() {
            var participatesInTrial = !_.isEmpty(this.getTrial()); //if the user has a trial object then he participates in the trial period
            var subscribed = this.companyHasSubscription();
            var inTrialOrTrialExpired = this.hasTrialExpired() || this.isInTrial();

            return participatesInTrial && inTrialOrTrialExpired && !subscribed;
        },

        daysTillTrialEnds: function daysTillTrialEnds() {
            if (this.getTrial()){
                return this.getTrial().days_to_end;
            }

            // if the user does not have a trial object on it's company than return a default
            return 0;
        },

        nTillTrialEnds: function nTillTrialEnds() {
            if (this.getTrial()){
                return this.getTrial().n_to_end;
            }
        },

        isCompanyOwner: function isCompanyOwner() {

            // TODO Alon - Why not use this.is_company_owner ??
            // Dvir: alon why asking questions instead of doing actions?
            // Erez: dvir, why not removing todos if done?
            // Alon: Erez, why are you friends with Dvir?
            // Boaz: Nothing here in 2021 yet, dibs
            // Dvir: we missed 2022! here's 2023 (btw my mom's birthday)

            if (this.is_company_owner) {
                return true;
            }
            else if (this._id && this.company && this.company.owner){
                return this._id === this.company.owner._id;
            }

            return false;
        },

        isAccountOwner: function isAccountOwner() {
            return !!this.is_account_owner;
        },

        isMyTeamMember: function isMyTeamMember(user){
            var currUser = user || UsersManager.getCurrUser();
            return currUser.company && this.company && currUser.company._id === this.company._id;
        },

        getAvatarFields: function getAvatarFields() {
            return {
                profile_image: $.extend({},this.profile_image),
                first_name: this.first_name,
                last_name: this.last_name,
                company: this.company,
                phone_number: this.phone_number,
                email: this.email
            };
        },

        updateEmailSignature: function updateEmailSignature(signature) {
            return UsersManager.updateEmailSignature(this, signature);
        },

        getCompanyEmailSignatures: function getCompanyEmailSignatures() {
            return this.email_signatures;
        },

        isDefaultProfileImage: function isDefaultProfileImage() {
            return AvatarService.isDefaultProfileImage(this);
        },

        getProfileImage: function getProfileImage(transformation) {

            // first, take user profile image url
            var imageUrl = this.profile_image ? this.profile_image.url : "";

            // if cloudinary id exists, take that
            if(this.profile_image && this.profile_image.cloudinary_public_id) {
                imageUrl = ImageService.getCloudinaryUrl(this.profile_image.cloudinary_public_id, transformation);
            }

            return imageUrl;
        },

        updateAutoExpireDefault: function updateAutoExpireDefault(user, period) {
            if(period === 'never'){
                return UsersManager.deleteAutoExpireDefault(user, period);
            }

            return UsersManager.updateAutoExpireDefault(user, period);
        },

        markActivityNotificationAsSeen: function markActivityNotificationAsSeen(activityNotificationId) {
            UsersManager.markActivityNotificationAsSeen(this, activityNotificationId);
        },

        markConversationNotificationAsSeen: function markConversationNotificationAsSeen(activityNotificationId) {
            UsersManager.markConversationNotificationAsSeen(this, activityNotificationId);
        },

        markAllWebNotificationsAsSeen: function markAllWebNotificationsAsSeen() {
            return UsersManager.markAllWebNotificationsAsSeen(this);
        },

        getRecentWebNotifications: function getRecentWebNotifications() {
            return UsersManager.getRecentWebNotifications(this);
        },

        getRecentConversationNotifications: function getRecentConversationNotifications() {
            return UsersManager.getRecentConversationNotifications(this);
        },

        getAECalendlyLink: function getAECalendlyLink() {

            return UsersManager.getAECalendlyLink(this);
        },

        getCalendlyWalkthroughDate: function getCalendlyWalkthroughDate() {

            return UsersManager.getCalendlyWalkthroughDate(this);
        },


        isVenueVendor: function () {
            return this.isVendor() && this.company.isVenue();
        },

        isAtwsOrPvlUser: function isAtwsOrPvlUser() {
            return !!this.atws_status;
        },

        getAtwsStage: function getAtwsStage() {
            if(this.isClient()){
                return null;
            }

            if (!_.isEmpty(this.atws_status) && (this.atws_status.onboarding_created === null) && (this.atws_status.trial_created === null)) {
                return Enums.AtwsStage.beforeCreatingFirstEvent;
            } else if (!_.isEmpty(this.atws_status) && (this.atws_status.onboarding_created !== null) && (this.atws_status.trial_created === null)){
                return Enums.AtwsStage.createdFirstEvent;
            } else if  (!_.isEmpty(this.atws_status) && (this.atws_status.onboarding_created !== null) && (this.atws_status.trial_created !== null)){
                return Enums.AtwsStage.createdSecondEvent;
            }

            return null;
        },

        isAtwsVendor: function isAtwsVendor() {
            return this.isVendor() && this.attribution.user_source_secondary === 'workspace_member';
        },

        isAtwsPreTrialVendor: function isAtwsPreTrialVendor(){
            return this.isAtwsVendor() && this.shouldStartTrial();
        },

        getUnseenNotificationsCount: function getUnseenNotificationsCount() {
            var activityNotifications = this.activity_notifications || [];
            var unseenNotificationsCount = 0;
            activityNotifications.forEach(function (notification) {
                if (notification.seen === false) {
                    unseenNotificationsCount++;
                }
            });
            var conversationNotifications = this.conversation_notifications || [];
            conversationNotifications.forEach(function (notification) {
                if (notification.seen === false) {
                    unseenNotificationsCount++;
                }
            });
            return unseenNotificationsCount;
        },

        hasUnseenNotifications: function hasUnseenNotifications() {
            return this.has_unseen_notifications || this.has_unseen_conversations;
        },

        shouldShowMembershipToUser: function shouldShowMembershipToUser() {
            return this.isAccountOwner() && this.company.shouldShowMembership() && !this.companyHasFreeSubscription();
        },

        shouldBeAbleToUpgrade: function isEligableToPay() {
            return !this.companyHasSubscription() && this.shouldShowMembershipToUser() && this.isVendor();
        },

        securityPhoneNumberLastFourDigits: function securityPhoneNumberLastFourDigits() {

            // this is only the last 4 digits of the actual number
            var phoneNumber = this.phone_number_for_two_factor_auth;
            return phoneNumber;
        },

        isLocatedInUSOrCanada: function isLocatedInUSOrCanada() {
            return UsersManager.isUSOrCanadaBased(this);
        },

        isGoogleIntegrationActive: function isGoogleIntegrationActive(integrationType) {
            var googleIntegrations = this.google_integrations;
            var foundIntegration;
            if(googleIntegrations && googleIntegrations.length > 0) {
                foundIntegration = _.find(googleIntegrations, function (integration) {
                    if(integration.google_integration_permissions) {
                        var foundPermission = _.find(integration.google_integration_permissions, function (permission) {
                            return (permission.integration_type === integrationType && permission.status === 'active');
                        });
                        return foundPermission;
                    }
                });
            }
            return !!foundIntegration;
        },

        getIntegratedCalendarProvider: function getIntegratedCalendarProvider() {
            return this.calendar_provider;
        },

        getExistingAbTestVariation: function getExistingAbTestVariation(abTestName) {
            return this.ab_tests ? this.ab_tests[abTestName] || null : null;
        },

        setLastSentFileTime: function setLastSentFileTime(time) {
            var lastSentTime = this.last_file_sent_time;
            this.last_file_sent_time = time;
            if (!lastSentTime) {
                this.trigger(Enums.PubSubTriggers.firstPayableFileSent);
            }
        },


        triggerMembershipCancellation: function triggerMembershipCancellation(reason) {
            this.trigger(Enums.PubSubTriggers.membershipCanceled, {reason: reason});
        },

        triggerOtamMigratedToFlows: function triggerMigratedToFlows() {
            this.trigger(Enums.PubSubTriggers.otamMigratedToFlows);
        },

        hasMultipleCompanies: function hasMultipleCompanies() {
            return (this.companies && this.companies.length > 1);
        },

        companyIds: function companyIds() {
            return (this.companies && this.companies.map(company => company._id)) || [];
        },

        isNewBrochureEducation: function isNewBrochureEducation() {

            if(!this.company) {
                return false;
            }

            return FeaturesService.isNewForUserByFirstLogin(this, FeaturesService.features.brochureEducation);
        },

        shouldShowClientPortal: function shouldShowClientPortal() {
            return this.hasCompanySuperAdminPermissions() && (this.companyHasAnySubscription() || (this.company.company_url_prefixes && this.company.company_url_prefixes.length));
        },

        isReferredByMemberReferral: function isReferredByMemberReferral() {
            return this.referral_container && this.referral_container.is_referred_by_member_referral;
        },

        isShowContactFormAwareness() {
            return this.isCompanyOwner() && this.isHoneypaid() && !this.company.isContactFormActive()
        },

        isHoneypaid() {
            return this.activated_on
        },

        shouldShowGiftCardFeature() {
            return this.gift_card_feature_allowed;
        },

        isNylasEmailIntegrationEnabled() {
            return this.nylas_email_feature_allowed;
        },

        isNylasCalendarIntegrationEnabled() {
            return this.nylas_calendar_feature_allowed;
        },

        isContactFormDynamicFileSupported() {
            return this.is_contact_form_dynamic_file_supported;
        },

        getSessions: function getSessions(forUserId, sessionConfigIds, ignoreDayAvailabilities, ignoreTimeFrame, getRelatedWorkflows) {
            return SchedulingManager.getSessions(this, forUserId, sessionConfigIds, ignoreDayAvailabilities, ignoreTimeFrame, getRelatedWorkflows).then(function(response) {
                var sessionConfigs = ModelFactory.newCollectionByModelName('SessionModel');
                sessionConfigs.mixinFrom(response.data.session_configs);
                this.sessionsList = sessionConfigs;
                return sessionConfigs;
            }.bind(this));
        },

        getActiveSchedulingSessions: function getActiveSchedulingSessions() {
            if(this.sessionsList){
                const sessionArray = Object.values(this.sessionsList);
                return sessionArray.filter(function(session){
                    return session.enabled;
                });
            }
            return [];
        },

        createSession: function createSession(session) {
            return SchedulingManager.createSession(this, session).then(function(response) {
                // TODO:(removed AppCues - intercom?)
                var sessionConfigs = ModelFactory.newModel('SessionModel');
                sessionConfigs.mixinFrom(response.data);
                return sessionConfigs;
            }.bind(this));
        },

        updateSession: function updateSession(session) {
            return SchedulingManager.updateSession(this, session);
        },

        deleteSession: function deleteSession(sessionId) {
            return SchedulingManager.deleteSession(this, sessionId);
        },

        getZoomVideoConferencingLink: function getZoomVideoConferencingLink(user) {
            return VideoConferencingManager.createZoomVideoConference(user);
        },

        cancelZoomVideoConferencingLink: function cancelZoomVideoConferencingLink(user, meetingId, calendarItemID = null) {
            return VideoConferencingManager.cancelZoomVideoConference(user, meetingId, calendarItemID);
        },

        isClientUserSystemType: function isClientUserSystemType() {
            return !!this.is_client_user_type;
        },

        getPlanType: function getPlanType() {
            const account = this.getAccount();
            return account.subscription && account.subscription.pricing_plan.plan_type;
        },

        isAppSubscription: function isAppSubscription() {
            const pricingPlan = this.account && this.account.subscription && this.account.subscription.pricing_plan;

            if (
                !pricingPlan ||
                this.isSubscriptionPaused()
            ) {
                return false;
            }

            return pricingPlan.in_app_purchase_device !== null;
        },

        daysSinceTrialEndDate: function daysSinceTrialEndDate() {
            const trialData = this.account && this.account.trial;

            if (!trialData) {
                return;
            }

            const trialEndDate = moment(trialData.start_date).add(trialData.duration, 'day');
            return moment().diff(trialEndDate, 'day');
        },

        shouldViewCompanyTagline: function shouldViewCompanyTagline() {
            return this.company && this.company.hasTagline() &&
                this.startedTrialAfterDate(this.consultants_adjustments_feature_date);
        },

        isConsultantCopyChangedEnabled: function () {
            return this.startedTrialAfterDate('2023-06-20') && this.company.isConsultant();
        },

        isTrialStartedAfterABTestDate: function isTrialStartedAfterABTestDate() {
            return this.startedTrialAfterDate(this.consultants_adjustments_feature_date);
        },

        isTrialStartedAfterSubtypeABTestDate: function isTrialStartedAfterABTestDate() {
            return this.startedTrialAfterDate(this.subtype_consultants_adjustments_feature_date);
        },

        isTrialStartedAfterServicesHubRolloutDate: function isTrialStartedAfterServicesHubRolloutDate() {
            return this.startedTrialAfterDate(this.services_hub_feature_date) && !AppConfigService.isE2E();
        },

        isTrialStartedAfterNewIIOServiceTemplatesABTestDate: function isTrialStartedAfterNewIIOServiceTemplatesABTestDate() {
            return this.startedTrialAfterDate(this.new_iio_service_templates_feature_date) && !AppConfigService.isE2E();
        },

        isTrialStartedAfterTopNav2023RolloutDate: function isTrialStartedAfterTopNav2023RolloutDate() {
            return this.startedTrialAfterDate(this.top_nav_2023_feature_date) && !AppConfigService.isE2E();
        },

        isTrialStartedAfterNewMSPCompanyTypes: function isTrialStartedAfterNewMSPCompanyTypes() {
            return this.startedTrialAfterDate(this.msp_company_types_feature_date);
        },

        isConsultantTrialStartedAfterABTestDate: function isConsultantTrialStartedAfterABTestDate() {
            return (
                this.isTrialStartedAfterABTestDate() &&
                this.company.isConsultant()
            );
        },

        isConsultantTrialStartedAfterServicesHubRolloutDate: function isConsultantTrialStartedAfterServicesHubRolloutDate() {
            return (
                this.isTrialStartedAfterServicesHubRolloutDate() &&
                this.company.isConsultant()
            );
        },

        isConsultantTrialStartedAfterNewIIOServiceTemplatesABTestDate: function isConsultantTrialStartedAfterNewIIOServiceTemplatesABTestDate() {
            return (
                this.isTrialStartedAfterNewIIOServiceTemplatesABTestDate() &&
                this.company.isConsultant()
            );
        }
    });

    return UserModelClass;
};
