(function () {
    "use strict";

    var MODAL_WINDOW_TEMPLATE_URL = "angular/app/modules/common/modals/modal_window_template.html";

    // @ngInject
    function ModalServiceCtor($injector, $modal, ModalDialogMappings, $uibModal, Enums, moment, ReactModalService) {

        this.$injector = $injector;
        this.$modal = $modal;
        this.$uibModal = $uibModal; // new service
        this.ModalDialogMappings = ModalDialogMappings;
        this.Enums = Enums;
        this.moment = moment;
        this.ReactModalService = ReactModalService;
    }


    Services.ModalService = Class(function () {

        return {

            constructor: ModalServiceCtor,

            _openModal: function _openModal(modalInfo, paramsMap, cssClass, closeOnEscapeKey, showBackdrop, enableScroll, useNewModalService) {
                if (!cssClass) {
                    cssClass = '';
                }

                if (typeof closeOnEscapeKey !== 'boolean') {
                    closeOnEscapeKey = true;
                }

                if (typeof showBackdrop !== 'boolean') {
                    showBackdrop = 'static';
                }

                if(enableScroll === true) {
                    cssClass += " reveal-modal--scrollable";
                }

                var resolves = {
                    AppConfigService: 'AppConfigService'
                };

                if (paramsMap) {

                    for (var key in paramsMap) {
                        if (paramsMap[key] === null) {
                            resolves[key] = key;
                        } else {
                            resolves[key] = {
                                injected: paramsMap[key]
                            };
                        }
                    }
                }

                if(!useNewModalService) {
                    var modalPromise = this.$modal.open({
                        templateUrl: modalInfo.template,
                        controller: modalInfo.controller,
                        windowClass: cssClass,
                        resolve: resolves,
                        backdrop: showBackdrop,
                        keyboard: closeOnEscapeKey,
                        backdropRemoveInstant: true
                    });
                    
                    // when the user clicks the back button, the modal will be dismissed
                    function onPopState(){
                        modalPromise.dismiss();
                    }
                    window.addEventListener('popstate', onPopState);
                    modalPromise.result.finally(function(){
                        window.removeEventListener('popstate', onPopState);
                    });

                    return modalPromise;
                } else {

                    return this.$uibModal.open({
                        windowClass: cssClass,
                        openedClass: "hb-modal-open-body-decorator",
                        backdropClass: "hb-modal-backdrop",
                        windowTemplateUrl: MODAL_WINDOW_TEMPLATE_URL,
                        templateUrl: modalInfo.template,
                        controller: modalInfo.controller,
                        bindToController: true,
                        resolve: paramsMap,
                        backdrop: showBackdrop,
                        keyboard: closeOnEscapeKey,
                    });

                }


            },

            _openModalPromise: function _openModalPromise(modalInfo, paramsMap, cssClass, closeOnEscapeKey, showBackdrop, enableScroll, useNewModalService) {
                return this._openModal(modalInfo, paramsMap, cssClass, closeOnEscapeKey, showBackdrop, enableScroll, useNewModalService).result;
            },

            openUploadLogoPhotoModal: function openUploadLogoPhotoModal(company) {
                var model = {
                    instance: company,
                    type: 'logo'
                };
                return this._openModalPromise(this.ModalDialogMappings.Modals.UploadCoverPhoto, {model: model});
            },

            openUploadIconPhotoModal: function openUploadIconPhotoModal(company, showFooterNote) {
                var model = {
                    instance: company,
                    type: 'icon'
                };

                if (showFooterNote) {
                    model.footerNote = 'UPLOAD_PHOTO._ICON_FOOTER_NOTE_';
                }

                return this._openModalPromise(this.ModalDialogMappings.Modals.UploadCoverPhoto, {model: model});
            },

            openSignatureDialog: function (signatureModel, defaultSignatureFromUser, branded) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.Signature, {signatureModel: signatureModel, defaultSignatureFromUser: defaultSignatureFromUser, branded: branded }, 'signature-modal-container');
            },

            openUploadProfilePhotoModal: function openUploadProfilePhotoModal(user) {
                var model = {
                    instance: user,
                    type: 'profile_image'
                };

                var promise = this._openModalPromise(this.ModalDialogMappings.Modals.UploadCoverPhoto, {model: model});

                promise.then(function success(){
                    this.$injector.get('OnboardingService').onAccountImageUploaded();
                    this.$injector.get('GoogleTagManagerService').onProfileImageUpload();
                }.bind(this));

                return promise;
            },

            openAddImageToMessageModal: function openAddImageToMessageModal(model) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.UploadFiles, {model: model}, 'full-page-modal full-page-modal--white');
            },

            openAddWorkspaceParticipantModal: function openAddWorkspaceParticipantModal(workspace, isNewWorkspace, subTitleText, showBackdrop, shouldShowUserType, defaultTab) {
                return this._openModalPromise(
                    this.ModalDialogMappings.Modals.AddWorkspaceParticipant, 
                    {
                        workspace: workspace,
                        isNewWorkspace: isNewWorkspace,
                        subTitleText: subTitleText,
                        shouldShowUserType: shouldShowUserType,
                        defaultTab: defaultTab || 'Client'
                    }, 
                    'hb-modal hb-modal--standard hb-modal--add-contact-modal',
                    undefined,
                    showBackdrop
                );
            },

            openAddClientTopNavModal: function openAddClientTopNavModal(workspace, isNewWorkspace, subTitleText, showBackdrop, shouldShowUserType) {
                return this.ReactModalService.openGlobalModal('AddClientFromTopNavModalWrapper', {});
            },

            openAddWorkspaceVendorModal: function openAddWorkspaceVendorModal(workspace, userList) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.AddWorkspaceMember, {workspace: workspace, userList: userList, requestProposal: true, config: this.getAddUserConfig(true),ContactsManager: null});
            },

            openNewVendorReferralModal: function openNewVendorReferralModal(workspace) {
                return this.$injector.get('StatsigService').isGateEnabled('ng2react-refer-to-vendor-modal').then(function (isEnabled) {
                    if (isEnabled) {
                        return this.ReactModalService.openGlobalModal('ReferToVendorModalWrapper', {});
                    } else {
                        return this._openModalPromise(this.ModalDialogMappings.Modals.NewVendorReferral, {workspace: workspace, config: this.getAddUserConfig(true)}, 'hb-modal hb-modal--standard hb-modal--add-contact-modal');
                    }
                }.bind(this));
            },

            openWorkspaceAdminMoveModal: function openWorkspaceAdminMoveModal(workspace, userList) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.WorkspaceAdminMove, {workspace: workspace, userList: userList, config: this.getAddUserConfig(true)}, 'hb-modal hb-modal--standard hb-modal--add-contact-modal');
            },

            openAssignWorkspaceToTeamMemberModal: function openAssignWorkspaceToTeamMemberModal(workspace, userList, teamMember) {
                return this.$injector.get('StatsigService').isGateEnabled('ng2react_assignworkspacetoteammembermodal').then(function (isEnabled) {
                    if (isEnabled) {
                        return this.ReactModalService.openGlobalModal(
                            'AssignWorkspaceToTeamMemberModalWrapper',
                            { workspace: workspace, viewingAsTeamMemberId: !!teamMember ? teamMember._id : null }
                        );

                    } else {
                        return this._openModalPromise(
                            this.ModalDialogMappings.Modals.AssignWorkspaceToTeamMember,
                            {
                                workspace: workspace,
                                userList: userList,
                                teamMember: teamMember
                            },
                            'assign-to-team-member-modal'
                        );
                    }
                }.bind(this));
            },

            openAddTeamMemberToWorkspaceModal: function openAddTeamMemberToWorkspaceModal(workspace, userList, canSelectMultiple, isNewWorkspace) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.AddTeamMemberToWorkspace, {workspace: workspace, userList: userList, canSelectMultiple: canSelectMultiple, isNewWorkspace: isNewWorkspace});
            },

            openNewAddContactModal: function openNewAddContactModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.NewAddContact, {}, 'hb-modal hb-modal--standard hb-modal--add-contact-modal');
            },

            newAddPreferredContactModal: function newAddPreferredContactModal(source) {
                var config = this.getAddUserConfig(true);
                config.source = source;
                return this._openModalPromise(this.ModalDialogMappings.Modals.NewAddPreferredContact, {config: config}, 'hb-modal hb-modal--standard hb-modal--add-contact-modal');
            },

            openEditContactModal: function openEditContactModal(user) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.EditContact, { userToEdit: user }, 'hb-modal hb-modal--standard hb-modal--add-contact-modal');
            },


            openNewAddCompanyTeamMemberModal: function openNewAddCompanyTeamMemberModal(company, userList) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.NewAddCompanyTeamMember, {company: company, userList: userList,}, 'hb-modal hb-modal--standard hb-modal--add-contact-modal');
            },

            openCreateProjectModal: function openCreateProjectModal(config) {
                return this.$injector.get('StatsigService').isGateEnabled('ng2react-create-project-modal').then(function (isEnabled) {
                    if (isEnabled) {
                        const _config = config || {};
                        return this.ReactModalService.openGlobalModal(
                            'CreateProjectModalWrapper',
                            {userId: _config.userId, dateParams: _config.dateParams, doNotGoToNewWorkspace: _config.doNotGoToNewWorkspace}
                        );
                    }
                    else {
                        return this._openModalPromise(this.ModalDialogMappings.Modals.CreateProject, { config: config }, 'nx-modal create-project-modal', true, true);
                    }
                }.bind(this));
            },

            openEmailEditorModal: function openEmailEditorModal(options) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.EmailEditor, {users: options.users, subject: options.subject || "", body: options.body, generalFiles: options.generalFiles || [], imageFiles: options.imageFiles || [], CKEDITOR: null, senderFunction: options.senderFunction, showScheduleOption: options.showScheduleOption, config: options.config || {}, previewFunction: options.previewFunction}, 'email-editor-modal'+ (options.additionalClass ? options.additionalClass : ''));
            },

            openEmailSchedulerModal: function openEmailSchedulerModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ScheduleEmailSend, {}, 'hb-modal hb-modal--standard schedule-email-send-modal', true, true);
            },

            openFileTitleModal: function openFileTitleModal(workspaceFile) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.FileTitleEditor, {workspaceFile: workspaceFile}, 'file-title file-title-modal');
            },

            openFraudStatusReasonModal: function openFraudStatusReasonModal(title, message, hideReasonField, placeholderText, showFraudVectors, userFraudData, checkTeamMembersInclusion) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.FraudStatusReason, {title: title, message: message, hideReasonField: hideReasonField, placeholderText: placeholderText, showFraudVectors: showFraudVectors, userFraudData: userFraudData || "", checkTeamMembersInclusion: checkTeamMembersInclusion}, 'file-title mark-user-fraud-status-modal', false);
            },

            openS3EmailPreview: function openS3EmailPreview(s3_email) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ShowS3EmailPreview, {email:s3_email}, 'file-title mark-user-fraud-status-modal', false);
            },

            openPaymentAdminEditModal: function openPaymentAdminEditModal(title, message) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.AddPaymentAdminEditModal, {title: title, message: message}, 'file-title payment-update-modal', true);
            },

            openClientBotRecapModal: function openClientBotRecapModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ClientBotRecapModal, {}, 'client-bot-recap-modal', true, true);
            },

            openVideoCarouselModal: function openVideoCarouselModal(modalContentType, onConfirm, onCancel) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.VideoCarouselModal, {modalContentType : modalContentType, onConfirmCallback: onConfirm, onCancelCallback: onCancel}, 'video-carousel-modal', true, true);
            },

            openTemplateExamplesModal: function openTemplateExamplesModal(modalContentType, modalContentData, onConfirm, onCancel) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.TemplateExamplesModal, {modalContentType : modalContentType, modalContentData: modalContentData ,onConfirmCallback: onConfirm, onCancelCallback: onCancel}, 'template-examples-modal', true, true);
            },

            openAddPromotionModal: function openAddPromotionModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.AddPromotionModal, {}, 'add-promotion-modal', true, true);
            },

            openContactFormExamplesModal: function openContactFormExamplesModal(modalSource) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ContactFormExamples, {modalSource: modalSource}, 'reveal-modal-full-screen scroll contact-form-examples-modal');
            },

            openEmbededContactFormModal: function openEmbededContactFormModal(contactFormModel, contactFormParentModel, contactFormState, showOnlyDirectLink) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.EmbededContactForm, {
                    contactFormModel: contactFormModel,
                    contactFormParentModel: contactFormParentModel,
                    contactFormState: contactFormState,
                    showOnlyDirectLink: showOnlyDirectLink
                }, 'cf-embed-modal-container', false);
            },

            openGiftCardDirectLinkModal: function openGiftCardDirectLinkModal(contactFormModel) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.GiftCardPublishModal, {
                    contactFormModel: contactFormModel
                }, 'gift-card-direct-link', false);
            },

            openPreviewContactFormModal: function openPreviewContactFormModal(company, contactForm) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.PreviewContactForm, {company: company,contactForm: contactForm}, 'full-page-modal cf-preview-modal-container__wrapper', false);
            },

            openPauseProjectModal: function openPauseProjectModal(projectId, workspaceId) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.PauseProjectModal, {projectId: projectId, workspaceId: workspaceId}, 'nx-modal project-action-modal', false, true);
            },

            openChooseTitleModal: function openChooseTitleModal(config) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ChooseTitle, {config: config}, 'nx-modal choose-title-modal', true, true);
            },

            openArchiveProjectModal: function openArchiveProjectModal(workspaceId, source) {
                return this.$injector.get('StatsigService').isGateEnabled('ng2react-archive-project-modal').then(function (isEnabled) {
                    if (isEnabled) {
                        return this.ReactModalService.openGlobalModal('ArchiveProjectWrapper', { workspaceId: workspaceId, source: source });
                    } else {
                        return this._openModalPromise(this.ModalDialogMappings.Modals.ArchiveProjectModal, {workspaceId: workspaceId, source: source}, 'nx-modal project-action-modal archive-project-modal', false, true);
                    }
                }.bind(this));
            },

            openSubdomainConfigModal: function openSubdomainConfigModal(company) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.SubdomainConfigModal, {company: company}, 'nx-modal custom-domain-modal', false, true);
            },

            openCreateFlowModal: function openCreateFlowModal(project, company, filters, workspaceId, appPath) {
                var DeviceService = this.$injector.get('DeviceService');
                var ReactModalService = this.$injector.get('ReactModalService');

                if (DeviceService.nxSmallBreakpoint()) {
                    return ReactModalService.openBlockFlowsModal();
                } else {
                    var createFlowData = {
                        flows: company.flows,
                        project_id: null,
                        event_name: null,
                        filters: filters,
                        workspaceId: workspaceId,
                        appPath: appPath
                    }

                    if (project.hasOwnProperty('_id') && project.hasOwnProperty('event_name') ) {
                        createFlowData.project_id = project._id;
                        createFlowData.event_name = project.event_name;
                    }
                    
                    ReactModalService.openCreateFlowModal(createFlowData);
                }
            },

            openMigrateUserModal: function openMigrateUserModal(source, fileId, fileType, fileTitle, targetType) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MigrateUserModal, {source: source, fileId: fileId, fileType: fileType, fileTitle: fileTitle, targetType: targetType}, 'hb-modal hb-modal--standard migrate-user-modal', false);
            },
            openTeamMembersOtamModal: function openTeamMembersOtamModal(source) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.TeamMembersOtamModal,{ source: source }, 'nx-modal create-project-modal', true);
            },
            openPreMigrateUserModal: function openPreMigrateUserModal(source) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.PreMigrateUserModal, {source: source}, 'nx-modal create-project-modal ', true);
            },

            openContactFormMembersModal: function openContactFormMembersModal(contactForm) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ContactFormMembers, {contactForm: contactForm}, 'cf-members-modal__wrapper');
            },

            openAttachAssetModal: function openAttachAssetModal(showCropTab, showGalleryTab, showUploadTab,
                                                                defaultTab, allowMultipleAttach, showBackdrop,
                                                                selectedImage, aspectRatio, showPreviewWhenCropping,
                                                                startUploadFile, acceptFilesFilter, model,
                                                                customCssClass, showCropTitle, footNoteText, branded, footNoteTextUploadTabDesktop, footNoteTextUploadTabMobile) {

                var showBackdrop = true;

                var paramsMap = {

                    showCropTab: !showCropTab ? false : true,
                    showGalleryTab: !showGalleryTab ? false : true,
                    showUploadTab: !showUploadTab ? false : true,
                    defaultTab: defaultTab || 'Library',
                    allowMultipleAttach: angular.isUndefined(allowMultipleAttach) ? true : allowMultipleAttach,
                    image: selectedImage ? selectedImage : 'no-image',
                    aspectRatio: aspectRatio || 2.5,
                    assetTypeForUpload: angular.isUndefined(model) ? "" : model.type,
                    showPreviewWhenCropping: angular.isUndefined(showPreviewWhenCropping) ? true : showPreviewWhenCropping,
                    startUploadFile: angular.isUndefined(startUploadFile) ? false : startUploadFile,
                    model: angular.isUndefined(model) ? "no-model" : model,
                    acceptFilesFilter: angular.isUndefined(acceptFilesFilter) ? "" : acceptFilesFilter,
                    customCssClass: "nx-modal nx-modal--full-screen media-modal" + (angular.isUndefined(customCssClass) ? '' : ' ' + customCssClass),
                    showCropTitle: angular.isUndefined(showCropTitle) ? false : showCropTitle,
                    footNoteText: footNoteText,
                    branded: branded,
                    footNoteTextUploadTabDesktop: footNoteTextUploadTabDesktop,
                    footNoteTextUploadTabMobile: footNoteTextUploadTabMobile
                };

                if (typeof showBackdrop !== 'boolean') {
                    showBackdrop = 'static';
                }


                return this._openModalPromise(this.ModalDialogMappings.Modals.AttachAsset, paramsMap, paramsMap.customCssClass, true, showBackdrop);
            },

            openBlockedVendorModal: function openBlockedVendorModal(vendor, tab_panel) {

                var showBackdrop = true;

                var paramsMap = {
                };

                if (typeof showBackdrop !== 'boolean') {
                    showBackdrop = 'static';
                }

                return this._openModalPromise(this.ModalDialogMappings.Modals.BlockedVendor, {vendor: vendor, tab_panel: tab_panel}, 'reveal-modal-blocked-vendor', true, showBackdrop);
            },

            openTemplateSaveAsModal: function openTemplateSaveAsModal(titleWrapper, modalHeader, preventEmptyTitle, onSuccess, onFailure) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.TemplateSaveAs, {
                    titleWrapper: titleWrapper,
                    modalHeader: modalHeader,
                    preventEmptyTitle: preventEmptyTitle,
                    onSuccess: onSuccess,
                    onFailure: onFailure
                }, 'file-title file-title-modal');
            },

            openUnsentFileModal: function openUnsentFileModal(fileType, fileHasPayment) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.UnsentFile, {fileType: fileType, fileHasPayment: fileHasPayment}, 'file-draft-warning-modal');
            },

            openUploadingFilesModal: function openUploadingFilesModal(isMoreThanOneFile) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.UploadingFiles, { isMoreThanOneFile: isMoreThanOneFile }, 'nx-modal uploading-files-modal', false, true);
            },

            openEditPreferredVendorsModal: function openEditPreferredVendorsModal(pvl) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.EditPreferredVendors, {pvl: pvl}, 'full-page-modal pvl-modal');
            },

            openViewPreferredVendorsListModal: function openViewPreferredVendorsListModal(pvl_id, eventId) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ViewPreferredVendorsList, { pvl_id: pvl_id, eventId: eventId }, 'full-page-modal pvl-modal');
            },

            openPVLCreationGuidanceModal: function openPVLCreationGuidanceModal(pvl, eventId) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.PVLCreationGuidanceModal, { pvl: pvl }, 'full-page-modal pvl-creation-guidance-modal');
            },

            getAddUserConfig: function getAddUserConfig(allowSearchContacts){
                return {
                    allowSearchContacts: allowSearchContacts
                };
            },

            /**
             * @param targetState
             * @param options - will be filled with the calendar list when we come back from google authentication flow.
             *      options.googleAccountCalendars
             * @returns {object} - the dialog's promise
             */
            openConnectCalendarModal: function openConnectCalendarModal(options) {
                var optionsInternal = {
                    isConnected: !!options.isConnected,
                    commingFromGoogleAuth: options && options.commingFromGoogleAuth ? options.commingFromGoogleAuth : false,
                    googleCalendarProvider: options && options.googleCalendarProvider ? options.googleCalendarProvider : null
                };
                return this._openModalPromise(this.ModalDialogMappings.Modals.ConnectCalendarModal, {options: optionsInternal}, 'nx-modal connect-calendar-modal', false);
            },

            openConnectNylasCalendarModal: function openConnectNylasCalendarModal(options) {
                var optionsInternal = {
                    isConnected: !!options.isConnected,
                    commingFromNylasAuth: options && options.commingFromNylasAuth ? options.commingFromNylasAuth : false
                };
                return this._openModalPromise(this.ModalDialogMappings.Modals.ConnectNylasCalendarModal, {options: optionsInternal}, 'nx-modal connect-calendar-modal', false);
            },

            openWorkspaceTypeIntroModal: function openWorkspaceTypeIntroModal(workspace) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.WorkspaceTypeIntro, {workspace: workspace});
            },

            openExpandImageModal: function openExpandImageModal(serviceModel, isEditMode) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ExpandImage, {serviceModel: serviceModel, isEditMode: isEditMode}, 'expand-image-modal');
            },

            openGenericVideoModal: function openGenericVideoModal(options) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.GenericVideo, {
                    modalOpenSource: options.modalOpenSource,
                    videoTitle: options.videoTitle,
                    videoLink: options.videoLink,
                    modalTitle: options.modalTitle,
                    modalSubTitle: options.modalSubTitle,
                    modalBtnText: options.modalBtnText,
                    modalBtnOnClickFunc: options.modalBtnOnClickFunc,
                    videoId: options.videoId,
                    enableYoutubeApi: options.enableYoutubeApi,
                    analyticsName: options.analyticsName,
                }, 'video-modal ' + options.additionalCssClasses, true, false);
            },

            openTOSChangedModal: function openTOSChangedModal(text) {

                var data = {
                    text: text
                };
                return this._openModalPromise(this.ModalDialogMappings.Modals.TOSChanged, data, 'tos-changed-modal', false);
            },

            openDisableAutoPayModal: function openDisableAutoPayModal(workspaceFile) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.DisableAutoPay, {workspaceFile: workspaceFile}, 'small-modal disable-auto-pay-modal', true);
            },
            openConfirmAutoPayModal: function openConfirmAutoPayModal(ach){
                return this._openModalPromise(this.ModalDialogMappings.Modals.ConfirmAutoPay, {ach: ach}, 'auto-pay-modal', true);
            },

            openTrialExpiredModal: function openTrialExpiredModal(){
                const currUser = this.$injector.get('UsersManager').getCurrUser();
                const trialData = currUser.account && currUser.account.trial;
                
                if (!trialData) {
                    this.$injector.get('AnalyticsService').trackError(
                        this,
                        'no trial data in openTrialExpiredModal'
                    );
                    return;
                }
                
                const ReactModalService = this.$injector.get('ReactModalService');
                const daysSinceTrialEndDate = currUser.daysSinceTrialEndDate();
                
                return ReactModalService.openTrialerDialog({
                    daysSinceTrialEndDate: daysSinceTrialEndDate
                });
            },

            openTrialExtendedModal: function openTrialExtendedModal(extensionLength){
                return this._openModalPromise(this.ModalDialogMappings.Modals.TrialExtendedModal, {extensionLength: extensionLength}, 'trial-extended-modal', true, true);
            },

            openPipelineSettingsModal: function openPipelineSettingsModal(currentUserCompany) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.PipelineSettings, {currentUserCompany: currentUserCompany},
                    'full-page-modal full pipeline-settings-modal', true, false);
            },

            openConfirmDeleteStageModal: function openConfirmDeleteStageModal(stageToMoveWorkspacesTo){
                return this._openModalPromise(this.ModalDialogMappings.Modals.ConfirmDeleteStage, {stageToMoveWorkspacesTo: stageToMoveWorkspacesTo}, 'small-modal confirm-delete-stage-modal', true);
            },

            openConfirmBrochureSelectionModal: function openConfirmBrochureSelectionModal(companyName) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ConfirmBrochureSelection, {companyName: companyName}, 'small-modal', true);
            },

            openSendBrochureWarningModal: function openSendBrochureWarningModal(title) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.SendBrochureWarning, {title: title}, 'small-modal', true);
            },

            openPipelineSendBrochureModal: function openPipelineSendBrochureModal(workspace) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.PipelineSendBrochure, {workspace: workspace}, 'pipeline-send-brochure');
            },

            /**
             * @param {object} options the options object
             * @param {object} options.isCreateMeeting - open the dialog in creation mode, false means in editing mode
             * @param {moment} options.dateTimeStart - this is valid in creation mode and indicate the start time that was selected, date and time if exists (day, week view selection)
             * @param {object} options.calendarItemModel - this is valid only in editing mode. the calendarItem model
             * @param {string} options.viewType - the view type in which we are creating this meeting. this will effect how we auto populate the start time and end time
             *                                    it could be one of the following view type of the fullcalendar: month,agendaWeek,agendaDay
             * @returns {promise}
             */
            openCalendarMeetingModal: function openCreateMeetingModal(options) {
                return this.$injector.get('StatsigService').isGateEnabled('ng2react-create-meeting-modal').then(function (isEnabled) {
                    if (isEnabled) {
                        const props = {};

                        if (options.calendarItemModel) {
                            props.existingMeeting = options.calendarItemModel;
                        } else if (!!options.duration) {
                            props.sessionConfigData = options;
                        }

                        return this.ReactModalService.openGlobalModal('ScheduleMeetingModalWrapper', props);
                    } else {
                        return this._openModalPromise(this.ModalDialogMappings.Modals.CalendarMeetingModal, { options: options }, 'nx-modal schedule-meeting-modal', false, true);
                    }
                }.bind(this));
            },

            openEmailSendErrorModal: function openEmailSendErrorModal(members, failed, succeeded, isScheduledEmail) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.EmailSendErrorModal, {members: members, failed: failed, succeeded: succeeded, isScheduledEmail: isScheduledEmail}, 'nx-modal email-send-error-modal', false, true);
            },

            openRescheduleModal: function openRescheduleModal(projectId, workspaceId, isUnpause, source) {
              return this._openModalPromise(this.ModalDialogMappings.Modals.RescheduleProjectContainerModal, {projectId: projectId, workspaceId: workspaceId, isUnpause: isUnpause, source: source}, 'nx-modal project-action-modal reschedule-project', false, true);
            },

            openMoveStageModal: function openMoveStageModal(workspace, company) {
                // this modal was changed to accept userPipelineStages instead of company, but I kept company to be backwards compatible during the transition
                return this.ReactModalService.openGlobalModal('MoveWorkspaceStageModalWrapper', { workspace: workspace, userPipelineStages: company.user_pipeline_stages, company: company });
            },

            openPausedOfferModal: function openPausedOfferModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.PausedOfferModal, {}, 'nx-modal pause-offer', true, true);
            },

            openFacebookLeadsIntroModal: function openFacebookLeadsIntroModal(params) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.FacebookLeadsIntroModal, {modalParams : params}, 'hb-modal facebook-leads-intro-modal', true, true);
            },

            openFacebookLeadsModal: function openFacebookLeadsModal(params) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.FacebookLeadsModal, {modalParams : params}, 'hb-modal hb-modal--standard add-facebook-page-modal', true, true);
            },

            openPvlRecommendationsModal: function openPvlRecommendationsModal(workspace) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.PvlRecommendationsModal, {workspace: workspace}, 'full-page-modal pvl-request-modal', true, false);
            },

            openEventSpaces: function openEventSpaces(event, isNewEvent) {
                var cssClass = isNewEvent ? 'event-spaces-modal' : 'event-spaces-modal-edit';
                return this._openModalPromise(this.ModalDialogMappings.Modals.EventSpacesModal, {
                    event: event,
                    isNewEvent: isNewEvent
                }, 'nx-modal ' + cssClass, false);

            },

            openIntakeModal: function openIntakeModal(intakeVersion) {
                return this.$injector.get('StatsigService').getExperimentConfigValue('new_collaborators_experience', 'isNewExperience', false).then(function (isNewExperience) {
                    if (isNewExperience) {
                        return this.ReactModalService.openSignupModal()
                    } else {
                        return this._openModalPromise(this.ModalDialogMappings.Modals.IntakeModal, {intakeVersion:intakeVersion}, 'modal--standard intake-modal', true, true);
                    }
                }.bind(this));
            },

            openMalkutBankOwnerDetailsModal: function openMalkutBankOwnerDetailsModal(user, person) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutBankOwnerDetailsModal, {user: user, person: person}, 'nx-modal malkut-bank-account__owner-modal bank-account__form-modal', true, true);
            },

            openMalkutLoginReasonModal: function openMalkutLoginReasonModal(user, malkutUserId) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutLoginReasonModal, {user: user, malkutUserId: malkutUserId}, 'nx-modal malkut-bank-account__owner-modal bank-account__form-modal', true, true);
            },

            openMalkutTransferFundsFromHbModal: function openMalkutTransferFundsFromHbModal(email, company_id) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutTransferFundsFromHBModal, {email: email, company_id: company_id}, 'malkut-tranfer-funds', true, true);
            },

            openMalkutPullFundsFromMemberBankModal: function openMalkutPullFundsFromMemberBankModal(email, company_id, allow_enter_managed_account_id) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutPullFundsFromMemberBankModal, {email: email, company_id: company_id, allow_enter_managed_account_id: allow_enter_managed_account_id}, 'malkut-tranfer-funds', true, true);
            },

            openMalkutBankAccountInfoModal: function openMalkutBankAccountInfoModal(email, company_id) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutBankAccountInfoModal, {email: email, company_id: company_id}, 'malkut-manage-bank-account', true, true);
            },

            openMalkutDisputedPaymentModal: function openMalkutDisputedPaymentModal(payment) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutDisputedPaymentModal, {payment: payment}, 'malkut-manage-bank-account', true, true);
            },

            openAddMalkutUserModal: function openAddMalkutUserModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.AddMalkutUserModal, {}, 'modal', true, true);
            },

            openSendBatchEmailModal: function openSendBatchEmail(onSuccessFunction) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.SendBatchEmails, {onSuccessFunction: onSuccessFunction}, 'reveal-modal-full-screen batch-emails-modal-outer');
            },

            openReferralModal: function openReferralModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ReferralModal, {}, 'referralModal', true, true);
            },

            openLoginAsClientModal: function openLoginAsClientModal(client, workspaceFileId) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.LoginAsClientModal, {client: client, workspaceFileId: workspaceFileId}, 'login-as-client-modal');
            },

            openConfirmGmailIntegrationNeeded: function openConfirmGmailIntegrationNeeded() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ConfirmGmailIntegrationNeeded, {}, 'small-modal confirm-gmail-integration-needed');
            },

            openTwoFactorAuthModal: function openTwoFactorAuthModal(shouldVerify, enablePhoneVerification, enableEmailVerification) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.TwoFactorAuth, { shouldVerify: shouldVerify, enablePhoneVerification: !!enablePhoneVerification, enableEmailVerification: !!enableEmailVerification }, 'two-factor-auth-modal full-page-modal', false);
            },

            openLoginFlowTwoFactorAuthModal: function openLoginFlowTwoFactorAuthModal(user) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.LoginFlowTwoFactorAuth, {user: user}, 'nx-modal new-two-factor-auth-modal', true);
            },

            openLoginTwoFactorAuthModal: function openLoginTwoFactorAuthModal(user) {
                return this.ReactModalService.openGlobalModal('User2FAModalWrapper', {
                    phone: user.phone_number_for_two_factor_auth
                });
            },

            openAddSecurityPhoneNumberModal: function openAddSecurityPhoneNumberModal(user) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.AddSecurityPhoneNumberModal, { user: user }, 'nx-modal login-two-factor-auth-modal', true);
            },
            
            openGoogleContactsModal: function openGoogleContactsModal(onUserClicked, addContactCta, selectVendorType, filterExistingContacts, originState, analyticsEventValue) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.GoogleContactsModal, {onUserClicked: onUserClicked, addContactCta: addContactCta, selectVendorType: selectVendorType, filterExistingContacts: filterExistingContacts, originState: originState, analyticsEventValue: analyticsEventValue}, 'googleContactsModal', true);
            },

            openNonUsaWarningModal: function openNonUsaWarningModal(clickSource) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.NonUsaWarningModal, {clickSource:clickSource, overrideCallback: false}, 'non-us-resident-modal non-us-internal-modal', false);
            },

            openExpiredPasswordModal: function openExpiredPasswordModal(email, loginParams) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ChangePasswordModal, {email: email, loginParams: loginParams},'nx-modal login-two-factor-auth-modal');
            },

            openNonUsaWarningForClientModal: function openNonUsaWarningForClientModal(overrideCallback) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.NonUsaWarningModal, {overrideCallback: overrideCallback, clickSource: ''}, 'non-us-resident-modal non-us-internal-modal', false);
            },

            openCreateManualReferralModal: function openCreateManualReferralModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.CreateManualReferralModal, {}, '', false);
            },

            openUpdateReferralCodeModal: function openUpdateReferralCodeModal(user) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.UpdateReferralCodeModal, {user: user}, '', false);
            },

            openUpdateContactFormUrlModal: function openUpdateContactFormUrlModal(user) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.UpdateContactFormUrlModal, {user: user}, '', false);
            },

            ConfirmFileExpirationModal: function openConfirmDeleteStageModal(){
                return this._openModalPromise(this.ModalDialogMappings.Modals.ConfirmFileExpiration, null, 'small-modal confirm-file-expiration-modal', true);
            },

            openArchiveReasonsModal: function openArchiveReasonsModal(workspace, reasons, handleArchiveReason) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ArchiveReasonsModal, {workspace: workspace, reasons: reasons, handleArchiveReason: handleArchiveReason}, 'archive-reasons-modal', false);
            },

            openMessagePopupModal: function openMessagePopupModal(popupData) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MessagePopupModal, { popupData: popupData }, 'hb-popup-window ' + popupData.cssClass, popupData.popupInfo.closeOnEsc, popupData.popupInfo.showBackdrop, null, true);
            },

            openConnectProjectModal: function openConnectProjectModal(options) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ConnectProjectModal, {options: options},'nx-modal connect-project-modal', false, true);
            },

            openMwebConnectProjectModal: function openMwebConnectProjectModal(options) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.mWebConnectProjectModal, {options: options}, 'nx-modal connect-project-modal', false);
            },

            openTimeTrackerSampleModal: function openTimeTrackerSampleModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.TimeTrackerSampleModal, {}, 'time-tracker-sample-modal', true, true);
            },

            openTimeTrackerEntryModal: function openTimeTrackerEntryModal(projectId, timeTrackerEntry, source) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.TimeTrackerEntryModal, {projectId: projectId, timeTrackerEntry: timeTrackerEntry, source: source}, 'time-tracker-modal', true);
            },

            openTimeTrackerViewAllModal: function openTimeTrackerViewAllModal(workspace, viewState, project) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.TimeTrackerViewAllModal, {workspace: workspace, viewState: viewState, project: project}, 'time-tracker-modal-all full-page-modal', false);
            },

            openTimeTrackerToolInvoiceModal: function openTimeTrackerToolInvoiceModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.TimeTrackerToolInvoiceModal, {}, 'time-tracker-tool-invoice', false);
            },

            openCreateFirstProjectModal: function openCreateFirstProjectModal(showCreateProject) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.openCreateFirstProjectModal, {showCreateProject: showCreateProject}, 'hb-popup-window create-first-project-modal', false, true);
            },

            openMalkutUpdateCompanyLocationModal: function openMalkutUpdateCompanyLocationModal(company) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutUpdateCompanyLocation, {company: company}, 'hb-modal hb-modal--standard update-company-location', false);
            },

            openMalkutInjectTemplatesModal: function openMalkutInjectTemplatesModal(destCompanyUser, destCompany) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutInjectTemplatesToCompany, {destCompanyUser: destCompanyUser, destCompany: destCompany, isFlows: false}, 'hb-modal hb-modal--standard inject-templates-modal', false);
            },

            openMalkutInjectFlowTemplatesModal: function openMalkutInjectFlowTemplatesModal(destCompanyUser, destCompany) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutInjectTemplatesToCompany, {destCompanyUser: destCompanyUser, destCompany: destCompany, isFlows: true}, 'hb-modal hb-modal--standard inject-templates-modal', false);
            },

            openMalkutRequestDocumentsModal: function openMalkutRequestDocumentsModal(destCompanyUser, destCompanyId) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutRequestDocumentsModal, {destCompanyUser: destCompanyUser, destCompanyId: destCompanyId}, 'hb-modal hb-modal--standard request-documents-modal', false);
            },


            openMalkutSelectTemplatesForPromoModal: function openMalkutSelectTemplatesForPromoModal(educator) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutSelectTemplatesForPromo, {educator: educator}, 'hb-modal hb-modal--standard inject-templates-modal', false);
            },

            openStartFromFileWizardModal: function openStartFromFileWizardModal(fileType, workspace) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.StartFromFileWizardModal,
                    {fileType: fileType, workspace: workspace}, 'hb-modal hb-modal--standard start-from-file-wizard-modal', true, true);
            },

            openBadgePillTypesModal: function openBadgePillTypesModal(collection, onSaveHandler, limit, collectionType, modalTitle, analyticsArgs, modalDescription) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.BadgePillTypesModal, {
                    badgePillType: collectionType,
                    badgePillCollection: collection,
                    onSaveHandler: onSaveHandler,
                    limit: limit,
                    modalTitle: modalTitle,
                    analyticsArgs: analyticsArgs,
                    modalDescription: modalDescription
                }, 'nx-modal hb-modal--standard edit-tags-modal', true);
            },

            openEditLeadSourceModal: function openEditLeadSourceModal(collection, onSaveHandler, analyticsArgs) {
                return this.openBadgePillTypesModal(collection, onSaveHandler, 18, this.Enums.badgePillTypes.lead_sources, 'PROJECT.LEADSOURCE.LABELS._MODAL_EDIT_LEAD_SOURCE_', analyticsArgs, 'PROJECT.LEADSOURCE.LABELS._MODAL_EDIT_LEAD_SOURCE_DESCRIPTION_');
            },

            openEditSpacesModal: function openEditSpacesModal(collection, onSaveHandler, analyticsArgs) {
                return this.openBadgePillTypesModal(collection, onSaveHandler, 18, this.Enums.badgePillTypes.company_spaces, 'PROJECT.LEADSOURCE.LABELS._MODAL_EDIT_SPACES_', analyticsArgs);
            },

            openSelectEmailProviderModal: function openSelectEmailProviderModal(integrationType) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.SelectEmailServiceProvider, {integrationType: integrationType}, 'hb-modal hb-modal--standard email-service-provider-modal', true, true);
            },

            openMarkAsPaidModal: function openMarkAsPaidModal(payment) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MarkAsPaidModal, {payment: payment}, 'hb-modal hb-modal--standard mark-as-paid-modal', true, true);
            },

            openRefundPaymentModal: function openRefundPaymentModal(payment, fileId, isUndoMap, isOnRedirect) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.RefundPaymentModal, {payment: payment, workspaceFileId: fileId, isUndoMap: isUndoMap, isOnRedirect: isOnRedirect}, 'nx-modal refund-modal', true, true);
            },

            openAddDebitCardModal: function openAddDebitCardModal(source) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.DebitCardModal, {source: source}, 'nx-modal debit-card-modal', true, true);
            },

            openWaiveLateFeeModal: function openWaiveLateFeeModal(source, payment) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.WaiveLateFeeModal, {source: source, payment: payment}, 'nx-modal waive-late-fee-modal', true, true);
            },

            openInstantDepositModal: function openInstantDepositModal(source, paymentParentId, invoiceId, isFlowPayment) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.InstantDepositModal, {source: source, paymentParentId: paymentParentId, invoiceId: invoiceId, isFlowPayment: isFlowPayment}, 'nx-modal instant-deposit-modal', true, true);
            },

            openInstantDepositPromoteAutoModal: function openInstantDepositPromoteAutoModal(source) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.InstantDepositPromoteAutoModal, {source: source}, 'nx-modal instant-deposit-promote-auto-modal', true, true);
            },

            openCreditCardConfirmModal: function openCreditCardConfirmModal(card) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.CreditCardConfirm, {card: card}, 'nx-modal credit-card-confirm__modal', true, true);
            },

            openApproveReferralCommissionModal: function openApproveReferralCommissionModal(referral, pendingCommissionReferrals) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ApproveReferralCommissionModal, {referral: referral, pendingCommissionReferrals: pendingCommissionReferrals}, 'hb-modal hb-modal--standard malkut-approve-referral-commissions-modal', true, true);
            },

            openEditReferralCampaignModal: function openEditReferralCampaignModal(campaign) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.EditReferralCampaignModal, {campaign: campaign}, 'nx-modal nx-modal--full-screen malkut-edit-referral-campaign-modal', false, true);
            },

            openBlockNewCouponModal: function openBlockNewCouponModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.BlockNewCouponModal, {}, 'hb-modal hb-modal--standard', true, true);
            },

            openBrandingInspirationModal: function openBrandingInspirationModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.BrandingInspirationModal, {}, 'nx-modal branding-inspiration__modal', true, true);
            },

            openUploadClientsModal: function openUploadClientsModal(startFromStep) {
                return this.ReactModalService.openGlobalModal('ImportContactsWrapper', {});
            },
            openClientFlowModal: function openClientFlowModal(fileType) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ClientFlowModal, {fileType: fileType}, 'nx-modal client-flow-modal', true, true);
            },

            openContactFormExplantionVideoModal: function openContactFormExplantionVideoModal(fileType) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ContactFormExplanationVideoModal, {fileType: fileType}, 'nx-modal cf-explain', true, true);
            },

            openAddCompanyModal: function openAddCompanyModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.AddCompanyModal, {}, 'nx-modal add-company-modal', true, true);
            },

            openClientPortalSendLinkModal: function openSendLinkClientPortal(workspaceClients, clientPortalUrl) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ClientPortalSendLink, {workspaceClients: workspaceClients, clientPortalUrl: clientPortalUrl}, 'nx-modal client-portal__send-link-modal', true, true);
            },

            openClientPortalDemoModal: function openClientPortalDemoModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ClientPortalDemo, {}, 'nx-modal client-portal__demo-modal', true, true);
            },

            openPipelineOnboardingModal: function openPipelineOnboardingModal(options) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.PipelineOnboardingModal, {}, 'nx-modal nx-modal--full-screen pipeline-onboarding-container', true, false);
            },

            openPendingActionModal: function openPendingActionModal(promise, workspaces, title, failedTitle, failedText, failedLink, positiveBtnText, analyticsActionName, successCallback) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.PendingActionModal, {promise: promise, workspaces: workspaces, title: title, failedTitle: failedTitle, failedText: failedText, failedLink: failedLink, positiveBtnText: positiveBtnText, analyticsActionName: analyticsActionName, successCallback: successCallback}, 'nx-modal pending-action-modal', false, true);
            },

            openCustomUrlModal: function openCustomUrlModal(icon, title, text, positiveBtnText) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.CustomUrlModal, {icon: icon, title: title, text: text, positiveBtnText: positiveBtnText}, 'nx-modal custom-url-modal', true, true);
            },

            openEditPaymentReminderEmailModal: function openEditPaymentReminderEmailModal(paymentReminder, lateFeesEnabled) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.EditPaymentReminderEmailModal, {paymentReminder: paymentReminder, lateFeesEnabled: lateFeesEnabled}, 'nx-modal edit-payment-reminder-email-modal', false, true);
            },

            openConnectBankAccountModal: function openConnectBankAccountModal(src, step, isMultiSelect, props) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ConnectBankAccount, {src: src, step: step, isMultiSelect: isMultiSelect, props: props}, 'nx-modal connect-bank-account-modal', true, true);
            },

            openSearchV2Modal: function openSearchV2Modal() {
                return this._openModal(this.ModalDialogMappings.Modals.SearchV2Modal, {}, 'nx-modal search-v2-modal', true, true);
            },

            openProjectDetailsNotesModal: function openProjectDetailsNotesModal(project, company) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ProjectDetailsNotesModal, {project:project, company:company}, 'nx-modal nx-modal--full-screen project-details__notes__modal', true, false);
            },

            openBirthdayModal: function openBirthdayModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.BirthdayModal, {}, 'nx-modal happy-birthday-modal', true, true);
            },

            openProjectDetailsCustomizeModal: function openProjectDetailsCustomizeModal(company) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.ProjectDetailsCustomizeModal, {customizeFields: company.custom_project_fields, company: company}, 'nx-modal project-details__customize-modal', true, true);
            },

            openInteractiveOnboardingExplainBrandingModal: function openInteractiveOnboardingExplainBrandingModal(fileData) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.InteractiveOnboardingExplainBrandingModal, { fileData: fileData }, 'nx-modal int-onb-explain-branding', true, true);
            },

            openInteractiveOnboardingExplainServicesModal: function openInteractiveOnboardingExplainServicesModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.InteractiveOnboardingExplainServicesModal, {}, 'nx-modal int-onb-explain-services', true, true);
            },

            openMwebPreviewBrandingModalController: function openMwebPreviewBrandingModalController() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MwebPreviewBrandingModalController, {}, 'nx-modal', true, true);
            },

            openAddBankAccountOwnerModal: function openAddBankAccountOwnerModal(person, fromBankAccountForm, bankAccountType, personType, showAwaitingVerificationOnFinish) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.AddBankAccountOwnerModal, {person: person, fromBankAccountForm: fromBankAccountForm, bankAccountType: bankAccountType, personType: personType, showAwaitingVerificationOnFinish: showAwaitingVerificationOnFinish}, 'nx-modal bank-account__owner-modal bank-account__form-modal', false, true);
            },

            openUploadCompanyAdditionalDocumentsModal: function openUploadCompanyAdditionalDocuments(company) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.UploadCompanyAdditionalDocuments, {}, 'nx-modal bank-account__owner-modal bank-account__form-modal', true, true);
            },

            openBankAccountBusinessInfoModal: function openBankAccountBusinessInfoModal(bankAccountType) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.BankAccountBusinessInfo, {bankAccountType: bankAccountType}, 'nx-modal bank-account__business-info-modal bank-account__form-modal', true, true);
            },

            openMwebBlockModal: function openMwebBlockModal() {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MwebBlockModal, {}, 'nx-modal mweb-block-modal', false, true);
            },

            openEditDayAvailabilityModal: function openEditDayAvailabilityModal(session, dayAvailability, date, addTimeRangeOpen) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.EditDayAvailabilityModal, {session: session, dayAvailability: dayAvailability, date: date, addTimeRangeOpen: addTimeRangeOpen}, 'nx-modal edit-day-availability-modal', false, true);
            },

            openCreatedSessionModal: function openCreatedSessionModal(session, company) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.CreatedSessionModal, { session: session, company: company, }, 'nx-modal created-session-modal', true, true);
            },

            openTemplatesExplainModal: function openTemplatesExplainModal(isReturnUser) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.TemplatesExplainModal, { isReturnUser: isReturnUser }, 'nx-modal templates-explain-modal');
            },

            openBulkEmailsIntroModal: function openBulkEmailsIntroModal(batchSource) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.BulkEmailsIntroModal, {batchSource: batchSource}, 'nx-modal bulk-emails-intro-modal', true, true);
            },

            openEmailTemplatesModal: function openEmailTemplatesModal(selectedWorkspacesIds) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.EmailTemplatesModal, {}, 'nx-modal email-templates-modal', true, true);
            },

            openVerifyBillingInfoModal: function openVerifyBillingInfoModal(account) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.VerifyBillingInfoModal, {account: account}, 'nx-modal bulk-emails-intro-modal', true, true);
            },

            openUpcomingPaymentsModal: function openUpcomingPaymentsModal(payments, analyticsSource) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.UpcomingPaymentsModal, {payments: payments, analyticsSource: analyticsSource}, 'nx-modal upcoming-payments-modal', false, true, true);
            },

            openRateBookingModal: function openRateBookingModal(ratingGrade, vendorFirstName, eventId, company) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.RateBooking, {config: {ratingGrade: ratingGrade, vendorFirstName: vendorFirstName, eventId: eventId, company: company}}, 'nx-modal  create-project-modal rate-booking-modal', true, true);
            },

            openMalkutUpdateFeatureVariationModal: function openMalkutUpdateFeatureVariationModal(globalFeatureVariation) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutUpdateFeatureVariationModal, {globalFeatureVariation: globalFeatureVariation}, 'nx-modal update-feature-variation-modal', true, true);
            },
            openMalkutAddProIntegrationModal: function openMalkutAddProIntegrationModal(userId, email) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutAddProIntegrationModal, {userId: userId, email: email}, 'hb-modal--standard', true, true);
            },
            openRecurringLegacyFilesDetailsModal: function openRecurringLegacyFilesDetailsModal(accountId) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutRecurringLegacyFilesDetailsModal, {accountId: accountId}, 'workflow-modal full-page-modal', true, true);
            },
            openUserFeatureVariationModal:function openFeatureVariationEditModal(account) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutUserFeatureVariationsModal, {account: account}, 'workflow-modal full-page-modal', true, true);
            },
            openUserUpdateFeatureVariationModal: function openUserUpdateFeatureVariationModal(accountId, featureVariation) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutUpdateUserFeatureVariationModal, {accountId: accountId, featureVariation: featureVariation}, 'nx-modal update-feature-variation-modal', true, true);
            },
            openStartTrialAsClientModal: function openStartTrialAsClientModal(user, companyName) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.StartTrialAsClientModal, {user: user, companyName: companyName}, 'hb-modal hb-modal--standard start-trial-modal', true, true);
            },
            openMalkutClientUserActionsModal: function openMalkutClientUserActionsModal(clientUser, clientUsersInfoVm) {
                return this._openModalPromise(this.ModalDialogMappings.Modals.MalkutClientUserActionsModal, {clientUser: clientUser, clientUsersInfoVm: clientUsersInfoVm}, 'hb-modal hb-modal--standard start-trial-modal', true, true);
            },
            openOooInEffectModal: function openOooInEffectModal() {
                return this.ReactModalService.openOooInEffectModal();
            },
            openOooHasEndedModal: function openOooHasEndedModal() {
                return this.ReactModalService.openOooHasEndedModal();
            },
        };
    });
}());
