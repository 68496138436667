Directives.FeedMessageEditorDirective = function FeedMessageEditorDirective() {

    // @ngInject
    function FeedMessageEditorDirectiveControllerFunc($injector, $document, $scope, CKEDITOR, Constants, $translate, $state, $timeout, $, RTEFactory,
                                                      PhotosUploadManager, AppConfigService, UiPersistenceService, ContactsManager, CompaniesManager, TemplatesManager, UsersManager, RepositoryService,
                                                      ModalService, AnalyticsService, $q,  _, FeedService, Gon, PvlService, AbTestService, Enums, uuid4, $sce,
                                                      InputFieldsService, $log, UIUtils, PopupMessageService, UserService, RegexService, WorkspaceService, ToastService,
                                                      HelpService, DeviceService, SchedulingService, FeaturesService, AppStates, AttachmentsService, FeatureRestrictionService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FeedMessageEditorDirectiveController';
        this.AbTestService = AbTestService;
        this.Enums = Enums;
        var self = this;

        this.AppConfigService = AppConfigService;
        this.ContactsManager = ContactsManager;
        this.TemplatesManager = TemplatesManager;
        this.AnalyticsService = AnalyticsService;
        this.RepositoryService = RepositoryService;
        this.PopupMessageService = PopupMessageService;
        this.UserService = UserService;
        this.RegexService = RegexService;
        this.HelpService = HelpService;
        this.DeviceService = DeviceService;
        this.SchedulingService = SchedulingService;
        this.meetingTypes = Constants.MeetingTypes;
        this.windowTypes = Constants.WindowTypes;
        this.UsersManager = UsersManager;
        this.AppStates = AppStates;
        this.AttachmentsService = AttachmentsService;
        this.UiPersistenceService = UiPersistenceService;
        this.ToastService = ToastService;
        this.FeatureRestrictionService = FeatureRestrictionService;

        this.config = this.config || {};
        this.imageFiles = [];
        this.generalFiles = [];
        this.mobileExpanded = false;
        this.isEditorLoaded = false;
        var queryParams = document.location.href.split('?')[1];
        var queryValues = queryParams && queryParams.split('=');
        this.isOpenFromSchedulerBlock = queryValues && queryValues[0] === 'schedulerBlockDeleted' && queryValues[1] === '1';
        this.subject = this.isOpenFromSchedulerBlock && "Session is not currently available";
        this.isEditorExpanded = this.isOpenFromSchedulerBlock;
        this.fileBeingRemoved = false;
        this.editorHasContent = false;
        this.showSendTooltip = false;
        this.statusText = "";
        this.hideTooltip = false;
        this.sendMessageToServer = this.sendMessageFunc();
        this.$scope = $scope;
        this.$q = $q;
        this.$ = $;
        this.$state = $state;
        this._ = _;
        this.$timeout = $timeout;
        this.FeedService = FeedService;
        this.PvlService = PvlService;
        this.CKEDITOR = CKEDITOR;
        this.uuid4 = uuid4;
        this.InputFieldsService = InputFieldsService;
        this.UIUtils = UIUtils;
        this.$sce = $sce;
        this.FeaturesService = FeaturesService;

        this.user = UsersManager.getCurrUser();
        this.isVendor = this.user.isVendor();
        this.ModalService = ModalService;
        this.showSharePreferredVendors = this.config.showSharePreferredVendors && this.isVendor;
        this.setSendButtonText();

        this.feedState = $state.$current.name;

        this.company = CompaniesManager.getCurrCompany();
        this.uploadIngFilesPromises = [];
        this.pvList = [];
        this.pvlParams = {};
        this.MAX_DISPLAY_RECIPIENTS = 3;

        // scheduling exposure poc consts
        this.MAX_TIMES_PER_USER = this.AppConfigService.isProduction() ? 1 : 5000;
        this.RANDOM_FACTOR = this.AppConfigService.isProduction() ? 0.15 : 0.5; // show for 15% of users with random_popup variation
        this.PHRASES = [
            "calendar",
            "calendars",
            "video chat",
            "video chats",
            "consult",
            "consultation",
            "consultations",
            "consulting",
            "converse",
            "conversation",
            "conversations",
            "virtual",
            "virtuals",
            "hangout",
            "hangouts"
        ]; // trigger phrases for smart_popup variation

        this.showCropTab = false;
        this.showGalleryTab = true;
        this.showUploadTab = true;
        this.defaultTab = "Library";
        this.allowMultipleAttach = true;
        this.isSystemClient = this.user.isClient();
        this.cursorRange = null;
        this.allowThreading = true;

        this.showingEmailScheduler = false;
        this.scheduledSendDate = null;

        this.replyMode = false;
        this.lastMessageSubject = null;
        this.last_feed_to_reply_id = null;

        this.teamMembers = this.company.getAllCompanyMembers().filter(function(tm) { return !!tm.full_name; });

        this.isMobile = this.DeviceService.checkIfMobileSize();

        // Scheduling link
        if (SchedulingService.isSchedulingEnabled(this.user)) {
            this._initScheduling();
        }

        this.onAddEmailSignature = this.onAddEmailSignature.bind(this);

        this.autoSaveDraft = this.config.autoSaveDraft !== undefined ? this.config.autoSaveDraft : true;

        WorkspaceService.on(Enums.PubSubTriggers.autoFeedMessage, function(data) {
            this.onAutoFeedMessage(data.subject, data.htmlBody);
        }.bind(this));

        WorkspaceService.on(Enums.PubSubTriggers.openFeedEditor, function() {
            this.toggleExpandEditor();
        }.bind(this));

        $scope.$watch('feedMessageEditorVm.lastMessageToReply', function (newVal) {
            if (!this.allowThreading) {
                return;
            }

            // Don't update last message subject and id if we have a draft
            if (this.autoSaveDraft && this.FeedService.getDraftMessage(this.modelInstance._id)) {
                if (this.lastMessageToReply && this.lastMessageToReply.data) {
                    this.lastMessageSubject = (this.lastMessageToReply.data.subject || "").trim();

                    if (!this.lastMessageSubject.startsWith('Re:')) {
                        this.lastMessageSubject = "Re: " + this.lastMessageSubject;
                    }
                }
            } else if (this.lastMessageToReply && this.lastMessageToReply.data) {
                this.replyMode = true;
                this.lastMessageSubject = (this.lastMessageToReply.data.subject || "").trim();
                if (!this.lastMessageSubject.startsWith('Re:')) {
                    this.lastMessageSubject = "Re: " + this.lastMessageSubject;
                }
                this.last_feed_to_reply_id = this.lastMessageToReply._id;
                this.subject = this.lastMessageSubject;
            }

            // After loading last message to reply, check if we have a draft and change the subject accordingly
            var draft = null;
            if (this.autoSaveDraft) {
                draft = this.FeedService.getDraftMessage(this.modelInstance._id);
            }
            if (draft) {
                $scope.$applyAsync(function () {
                    if (draft.subject) {
                        this.subject = draft.subject;
                    }
                    if (draft.hasOwnProperty('replyMode')) {
                        this.replyMode = draft.replyMode;
                    }
                }.bind(this));
            }
        }.bind(this));

        $scope.$on('$destroy', function() {
            WorkspaceService.off(Enums.PubSubTriggers.autoFeedMessage);
            WorkspaceService.off(Enums.PubSubTriggers.openFeedEditor);
        }.bind(this));

        var _toggleExpandForMobile = function () {
            // invoke only after CKEDITOR is initiated
            if (window.innerWidth < 640) {
                var windowContainer     = angular.element('.workspace-docs');
                var editor              = angular.element('.cke_wysiwyg_div');
                var header              = angular.element('.header-docs');
                // if no element with header-docs class is found, ignore the offet
                // this is the case for feed message in groups mobile - header.offset() is undefined
                var headerOffset        = header.offset() ? header.offset().top + header.height() : header.height();
                var editorOffset        = 46; // height of feed-editor-tool; it is not init'ed when this is invoked so can't be queried
                var windowHeight        = window.innerHeight;
                var feedboxTargetHeight = windowHeight - (editorOffset + headerOffset);
                var maxHeight           = 1000;
                feedboxTargetHeight     = feedboxTargetHeight < maxHeight ? feedboxTargetHeight : maxHeight;

                if (self.mobileExpanded) {
                    windowContainer.removeClass('workspace-docs-mobile-expanded');
                    self.mobileExpanded = false;
                    self.HelpService.showHelp();
                } else {
                    windowContainer.addClass('workspace-docs-mobile-expanded');
                    editor.height(feedboxTargetHeight);
                    self.mobileExpanded = true;
                    self.HelpService.hideHelp();

                    // allows time for mobile keyboard to rise and move window down before moving window to top
                    $timeout(function() {
                        if (window.scrollY > 0) {
                            window.scrollTo(0,0);
                        }
                    }, 500);
                }
            }
        };

        this.onAutoFeedMessage = function onAutoFeedMessage(subject, htmlBody) {

            var setAutoFeedMessage = function setAutoFeedMessage(subject, htmlBody) {
                this.editor.setData(htmlBody);
                $scope.$applyAsync(function () {
                    if (subject) {
                        this.subject = subject;
                    } else {
                        this.subject = "New message from " + this.user.first_name;
                    }
                }.bind(this));
                this.handleContentUpdate();
                this.toggleEditorView(true);
            }.bind(this);

            // we need to load the editor in both cases because in the first case it actually loads the editor for the first time.
            // In the second case it opens the editor.
            if (!this.isEditorLoaded) {
                this.loadEditor();
                this.editor.on('instanceReady', function () {
                    // we use a timeout to make sure that the auto message overrides any draft messages
                    $timeout(function() {
                        setAutoFeedMessage(subject, htmlBody);
                    }.bind(this), 500);
                }.bind(this));
            } else {
                this.loadEditor();
                setAutoFeedMessage(subject, htmlBody);
            }

        };

        this.loadEditor = function loadEditor(toolbarStateParams) {
            if (!this.isEditorLoaded) {
                const isDisabled = this.FeatureRestrictionService.checkIfBlockedAccess({
                    source: 'send_email',
                    actionType: 'click'
                });
                if (isDisabled) {
                    return;
                }

                this.editor = RTEFactory.newFeedEditor('messageEditorPlaceholder', {
                    height: this.config.height,
                    toolbarLocation: 'bottom'
                });

                this.isEditorLoaded = true;
                this.isEditorExpanded = true;
                this.toggleInsertLink = false;
                this.toolboxActive = false;

                var toolboxToggler = angular.element('#js-editor-toolbox-toggle');
                var imageToggler   = angular.element('#js-add-image-toggle');

                this.onClick = function(e) {
                    var isFeedState = $state.current.name === self.feedState;

                    if (!this.isEditorExpanded || !isFeedState) {
                        // don't do anything if editor isn't expanded or not on feed
                        return;
                    }

                    var target = angular.element(e.target);
                    var hyperlinkDialogOpen = false;

                    if (this.$addHyperlinkArea.is(target)) {
                        // set cursorRange for updating cursor link in safari
                        if (this.editor.getSelection()) {
                            this.cursorRange = this.editor.getSelection().getRanges()[0];
                        }
                        this.toggleInsertLink = !this.toggleInsertLink;
                        if (this.toggleInsertLink) {
                            hyperlinkDialogOpen = true;
                        }
                        this._toggleToolbox(false);
                    }
                    else if (!this.$addHyperlinkArea.has(target).length) {
                        this.toggleInsertLink = false;
                    }

                    if (toolboxToggler.is(target)) {
                        this._toggleToolbox();
                    }
                    else if (imageToggler.is(target)) {
                        this._toggleToolbox(false);
                    }

                    if (!target.parents('#FeedMessageEditor').length &&
                        !target.is(angular.element('#FeedMessageEditor')) &&
                        !target.parents('.reveal-modal').length &&
                        !target.parents('.slick-lightbox').length &&
                        !target.is(angular.element('.reveal-modal-bg')) &&
                        !target.is(angular.element('.slick-lightbox')) &&
                        !this.fileBeingRemoved &&
                        !this.config.showEditorAlwaysExtended) {
                        // minimize message editor if user clicks off of it
                        this._toggleToolbox(false);
                        // this.toggleExpandEditor();
                    }

                    if(target.is(angular.element('#removeEmailSignature'))) {
                        this.removeEmailSignature("feed_editor_inline");
                    }

                    this.hideTooltip = this.toggleInsertLink || this.toolboxActive;
                    $scope.$digest();
                    this.fileBeingRemoved = false;

                    if (hyperlinkDialogOpen) {
                        this.$newHyperlinkInput.focus();
                    }

                    // Scheduling Link
                    if (e.target.matches('#addSchedulingLinkArea')){
                        this.schedulingSessionPopoverOpen = !this.schedulingSessionPopoverOpen;
                        this.schedulingLinkPopoverOpen = false;
                        this.addLinkReminderPopoverOpen = false;
                    } else if (e.target.matches('.js-addSchedulingLinkInput')) {
                        this.schedulingLinkPopoverOpen = true;
                    } else {
                        this.schedulingSessionPopoverOpen = false;
                        this.schedulingLinkPopoverOpen = false;
                        this.addLinkReminderPopoverOpen = false;
                    }

                }.bind(this);

                this.editor.on('blur', this.saveDraft.bind(this));

                this.editor.on('contentDom', function() {
                    var editable = this.editor.editable();

                    editable.attachListener(this.editor.document, 'keyup', function () {
                        this.handleContentUpdate();
                    }.bind(this));
                }.bind(this));

                this.editor.on('instanceReady', function () {
                    $document.on('mousedown.feed.honeybook', function (e) {
                        this.onClick(e);
                    }.bind(this));

                    // add placeholder to expanded ckeditor
                    var ckEditorEl = angular.element('#' + this.editor.container.$.id);
                    var editorContentsContainer = ckEditorEl.find('.cke_contents');
                    //var placeholderString = $translate.instant('COMMON_FEED_COMPONENTS.MESSAGE_EDITOR._PLACEHOLDER_');

                    editorContentsContainer.prepend('<div class="fake-ckeditor-placeholder" ></div>');
                    this.$ckeditorPlaceholder = this.$element.find('.fake-ckeditor-placeholder');

                    this.$ckeditorContents = this.$element.find(".cke_wysiwyg_div");

                    // expand editor to mobile height if mobile
                    _toggleExpandForMobile();

                    // Get draft data
                    var draft = null;
                    if (this.autoSaveDraft) {
                        draft = this.FeedService.getDraftMessage(this.modelInstance._id);
                    }

                    if (draft) {

                        this.editor.setData(draft.htmlBody);
                        $scope.$applyAsync(function () {
                            if (draft.subject) {
                                this.subject = draft.subject;
                            } else {
                                this.subject = "New message from " + this.user.first_name;
                            }

                            if(draft.hasOwnProperty('replyMode') && this.allowThreading) {
                                this.replyMode = draft.replyMode;
                            }
                        }.bind(this));


                        angular.copy(draft.images, this.imageFiles);
                        angular.copy(draft.files, this.generalFiles);
                        angular.copy(draft.pvl, this.pvl);

                        if (draft.htmlBody.indexOf("EmailSignature") > -1) {
                            this.removeEmailSignature();
                            this.initEmailSignature();
                            this.setRemoveSignature();
                        }

                        this.handleContentUpdate();
                        this.toggleEditorView(true);
                    }

                }.bind(this));
            } else {
                this.toggleExpandEditor();
            }

            switch (toolbarStateParams) {
                case 'link':
                    this.toggleInsertLink = true;
                    $timeout(function() {
                        this.$newHyperlinkInput.focus();
                    }, 200);
                    break;
                case 'image':
                    this.addFiletoFeed();
                    break;
                case 'preferred_vendors':
                    this.onSharePreferredVendors(null, 'attached_to_feed');
                    break;
            }

        };

        this.sending = false;
        this.messageModelType = this.modelType + "_message";

        this.setStatusText = function setStatusText(translationEntry) {
            if (this.isMobile) {
                if (translationEntry) {
                    $translate(translationEntry).then(function success(text) {
                        this.statusText = text;
                    }.bind(this));
                }
            } else {
                this.ToastService.showError({
                    contentTranslation: translationEntry,
                    dismissOnTimeout: false,
                    dismissButton: true,
                });
            }
        };

        this.setDomElements = function(parentElem) {
            this.$element = parentElem;
            this.$addHyperlinkArea = parentElem.find("#addHyperlinkArea");
            this.$newHyperlinkInput = parentElem.find("#newHyperlinkInput")[0];
            this.$newHyperlinkInputName = parentElem.find("#newHyperlinkInputName")[0];
            this.$sendButton = parentElem.find(".js-feed-message__button-send")[0];
        };

        this.setContents = function setContents(html_body, images, files) {

            this.editor.setData(html_body);

            var attachments = this.AttachmentsService.getAdditiveAttachments(images, files, this.imageFiles, this.generalFiles);

            this.imageFiles = attachments.images;
            this.generalFiles = attachments.files;
        };

        this.getHtmlContents = function getHtmlContents() {
            if (this.editor) {
                return this.editor.getData();
            }
        };

        this.getSubject = function getSubject() {
            return this.subject;
        };

        this.getTextualContents = function getTextualContents() {
            return this.$ckeditorContents[0].textContent;
        };

        this.getTextualContentsWithSpaces = function getTextualContentsWithSpaces() {
            return this.$ckeditorContents[0].innerText;
        };

        this.getSelectedText = function getSelectedText() {
            return this.editor.getSelection().getSelectedText();
        };

        this.clearEditor = function clearEditor() {
            this.setContents("");
            this.imageFiles = [];
            this.generalFiles = [];
            this.uploadIngFilesPromises = [];
            this.pvList = [];
            this.pvlParams = {};
            this.handleContentUpdate();
            this.statusText = "";
            this.pvl = this.PvlService.getEmptyPvl();
            this.subject = "";
            this.scheduledSendDate = null;
            this.setSendButtonText();
        };

        this.resetFiles = function resetFiles() {
            this.imageFiles = [];
            this.generalFiles = [];
            this.uploadIngFilesPromises = [];
        };

        this.hasContent = function hasContent() {
            return  this.getTextualContents() !== "" ||
                this.imageFiles.length > 0 ||
                this.generalFiles.length > 0 ||
                (this.pvl.preferred_vendor_ids && this.pvl.preferred_vendor_ids.length > 0);
        };

        this.setEditingState = function setEditingState(isEditable) {
            this.$ckeditorContents.attr("contentEditable", isEditable);
        };

        this.setSendingState = function setSendingState(isSending) {
            this.sending = isSending;
            this.setEditingState(!isSending);
        };

        this.handleContentUpdate = function handleContentUpdate() {
            if (this.hasContent()) {

                if (!this.editorHasContent) {
                    this.toggleEditorView(true);
                }

                this.saveDraft();

            } else if (this.editorHasContent) {
                this.toggleEditorView(false);
            }

            this.$scope.$applyAsync();
        };

        this.toggleEditorView = function toggleEditorView(show) {
            if (show) {
                this.$ckeditorPlaceholder.addClass('hidden');
                this.editorHasContent = true;
                this.$sendButton.disabled = false;
            }
            else {
                this.$ckeditorPlaceholder.removeClass('hidden');
                this.editorHasContent = false;
                this.$sendButton.disabled = true;
            }
        };

        this.validateUrl = function(str){
            this.checkUrlIsValid = !( this.RegexService.isUrlValid(str) || this.RegexService.isEmailValid(str));
        };

        this.addLinkToMessage = function () {
            var isEmail = function (str) {
                return str.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) != null;
            };

            var urlHasProtocol = function(url) {
                var protocols = ["http", "https", "ftp", "news"];
                for (var i=0; i<protocols.length; i++) {
                    if (url.indexOf(protocols[i]+"://") === 0) {
                        return true;
                    }
                }
                return false;
            };

            var inputText = this.$newHyperlinkInput.value.trim();
            var inputTextName = this.$newHyperlinkInputName.value;

            var resLink = inputText;
            var resText = this.getSelectedText();
            if (resText === "") {
                resText = " " + inputText + " ";
            }
            var resTarget = "_blank";
            if (inputText !== "") {
                if (isEmail(inputText)) {
                    resLink = "mailto:" + resLink;
                    resTarget = "_top";
                }
                else if (!urlHasProtocol(inputText)) {
                    resLink = "http://" + resLink;
                }
                if (this.DeviceService.isSafari()) {
                    this.editor.getSelection().selectRanges( [ this.cursorRange ] );
                }


                var newLinkElem = document.createElement("a");
                newLinkElem.href = resLink;
                newLinkElem.target = resTarget;
                newLinkElem.textContent = inputTextName || resText;

                this.editor.insertHtml(newLinkElem.outerHTML);
                this.$newHyperlinkInput.value = "";
                this.$newHyperlinkInputName.value = "";

                this.toggleInsertLink = false;
                this.hideTooltip = false;
                this.handleContentUpdate();
            }

        };

        this.addImage = function () {
            var self = this;

            var model = {
                instance: this.modelInstance,
                type: this.messageModelType,
                dontUpload: true
            };

            ModalService.openAddImageToMessageModal(model).then(
                function success(filesList) {
                    filesList = filesList.filter(function (currentFile) {
                        var currentImageFile, currentGeneralFile, i;
                        if (!currentFile || !currentFile.dataUrl) {
                            return false;
                        }

                        // check if this file already exists on the image files list
                        for (i = 0; i < self.imageFiles.length; i++) {
                            currentImageFile = self.imageFiles[i];
                            if (currentImageFile.dataUrl === currentFile.dataUrl) {
                                return false;
                            }
                        }

                        // check if this file already exists on the general files list
                        for (i = 0; i < self.generalFiles.length; i++) {
                            currentGeneralFile = self.generalFiles[i];
                            if (currentGeneralFile.dataUrl === currentFile.dataUrl) {
                                return false;
                            }
                        }

                        return true;
                    });

                    filesList.forEach(function (file) {
                        if(file.type.toLowerCase().indexOf('image') !== -1){
                            self.imageFiles.push(file);
                        } else {
                            self.generalFiles.push(file);
                        }

                        //start to upload these files right away. save the promises of the uploads
                        //because the send message will have to wait for them to finish till they upload
                        var model = {
                            instance: self.modelInstance,
                            type: self.messageModelType,
                            dontUpdateServer: true
                        };

                        PhotosUploadManager.getUpdatedModelData(model);
                        var fileUploadPromise = PhotosUploadManager.uploadPhoto([file], model, self);

                        var fileNode = {
                            file: file,
                            fileUploadPromise: fileUploadPromise
                        };

                        self.uploadIngFilesPromises.push(fileNode);
                    });

                    self.handleContentUpdate();
                },
                function error() {
                }
            );
        };


        this.addFiletoFeed = function addFiletoFeed() {

            if (this.isSystemClient)
            {
                this.showCropTab = false;
                this.showGalleryTab = false;
                this.defaultTab = "Upload";
            }

            this.ModalService.openAttachAssetModal(this.showCropTab, this.showGalleryTab, this.showUploadTab, this.defaultTab, this.allowMultipleAttach,
                false, false, null, false, false, '', '', '','MEDIA_MODAL.TITLE._ATTACH_IMG_OR_FILE_').then(
                function success(filesList) {
                    filesList = filesList.filter(function (currentFile) {
                        var currentImageFile, currentGeneralFile, i;
                        var isNewUploaded = true;

                        if (!currentFile || !currentFile.dataUrl) {
                            isNewUploaded = false;

                            // In case user added existing files, 'url' field should be used instead of dataUrl
                            if (!currentFile || !currentFile.url) {
                                return false;
                            }
                        }

                        // check if this file already exists on the image files list
                        for (i = 0; i < self.imageFiles.length; i++) {
                            currentImageFile = self.imageFiles[i];
                            if (currentImageFile.dataUrl) {
                                if (currentImageFile.dataUrl === currentFile.dataUrl) {
                                    return false;
                                }
                            }
                            else if (currentImageFile.url === currentFile.url) {
                                return false;
                            }
                        }

                        // check if this file already exists on the general files list
                        for (i = 0; i < self.generalFiles.length; i++) {
                            currentGeneralFile = self.generalFiles[i];
                            if (currentGeneralFile.dataUrl) {
                                if (currentGeneralFile.dataUrl === currentFile.dataUrl) {
                                    return false;
                                }
                            }
                            else if (currentGeneralFile.url === currentFile.url) {
                                return false;
                            }
                        }

                        return true;
                    });

                    filesList.forEach(function (file) {
                        var fileType;

                        if (!file.url) {
                            fileType = file.type.toLowerCase();
                        }  // New file to upload

                        else {
                            fileType = (/\.(gif|jpg|jpeg|tiff|png)([?#].*)?/i.test(file.url)) ? "image" : "file";
                        }


                        if(fileType.indexOf('image') !== -1){
                            self.imageFiles.push(file);
                        } else {
                            self.generalFiles.push(file);
                        }

                        if (!file.url)  // New file to upload
                        {
                            //start to upload these files right away. save the promises of the uploads
                            //because the send message will have to wait for them to finish till they upload
                            var model = {
                                instance: self.modelInstance,
                                type: self.messageModelType,
                                dontUpdateServer: true
                            };

                            PhotosUploadManager.getUpdatedModelData(model);
                            var fileUploadPromise = PhotosUploadManager.uploadPhoto([file], model, self);

                            var fileNode = {
                                file: file,
                                fileUploadPromise: fileUploadPromise
                            };

                            self.uploadIngFilesPromises.push(fileNode);
                        }
                    });

                    self.handleContentUpdate();
                },
                function error() {
                }
            );
        };


        // this file is used to remove files from the list of uploding promises
        // so when the message is sent we will not wait for them to finish the upload.
        this.removeFileUploadPromise = function removeFileUploadPromise(file) {
            var indexToRemove = null;
            for (var i = 0; i < this.uploadIngFilesPromises.length; i++) {
                if (this.uploadIngFilesPromises[i].file === file) {
                    indexToRemove = i;
                }
            }

            if(indexToRemove !== null){
                this.uploadIngFilesPromises.splice(indexToRemove, 1);
            }
        };

        this.removeImage = function removeImage(index) {
            this.fileBeingRemoved = true;
            var removedFile = this.imageFiles.splice(index,1)[0];
            if (removedFile.uploadAPI) {
                removedFile.uploadAPI.abort();
                this.removeFileUploadPromise(removedFile);
            }
            this.handleContentUpdate();
        };

        this.removeGeneralFile = function removeGeneralFile(index) {
            this.fileBeingRemoved = true;
            var removedFile = this.generalFiles.splice(index, 1);
            if (removedFile.uploadAPI) {
                removedFile.uploadAPI.abort();
                this.removeFileUploadPromise(removedFile);
            }
            this.handleContentUpdate();
        };

        this.sendMessage = function() {

            var pvlData;

            try {
                pvlData = this.pvl._id ? { pvl_id: this.pvl._id, flow_type: this.pvl.flow_type } : {};

                var attachedFiles = {
                    generalFiles: this.AttachmentsService.translateFilesListForServer(this.generalFiles),
                    imageFiles: this.AttachmentsService.translateFilesListForServer(this.imageFiles)
                };

            } catch (e) {
                // We failed miserably while trying to prepare the message for sending
                // and now, with the draft saving, even refresh won't save us!
                // So I'm resetting the file section of the message editor :-(
                this.resetFiles();
                this.setStatusText("COMMON_FEED_COMPONENTS.MESSAGE_EDITOR._SENDING_WITH_FILES_FAILED_");
                return;
            }

            this.showSendTooltip = false;

            var messageHTML = this.getHtmlContents();
            this.sendMessageToServer(this.modelInstance, messageHTML, attachedFiles, pvlData, this.subject, this.last_feed_to_reply_id, this.replyMode, this.scheduledSendDate).then(function success(shouldCollpaseEditorAfterSent) {
                if(shouldCollpaseEditorAfterSent){
                    if (this.allowThreading) {
                        this.subject = this.subject || "";
                        this.lastMessageSubject = this.subject.startsWith('Re:') ? this.subject : "Re: " + this.subject;
                    }
                    this.resetCurrentMessage();
                } else {
                    this.setSendingState(false);
                }
                //TODO: AnalyticsService.track(AnalyticsService....) message sent
            }.bind(this))
                .catch(function error(res) {
                    this.setSendingState(false);
                    var error_msg = "";
                    if (res && res.data && res.data.error_message) {
                        error_msg = res.data.error_message;
                    }
                    else {
                        error_msg = "COMMON_FEED_COMPONENTS.MESSAGE_EDITOR._SENDING_FAILED_";
                    }
                    this.setStatusText(error_msg);
                    //TODO: AnalyticsService.track(AnalyticsService....) message error
                }.bind(this));
        };

        this.resetCurrentMessage = function resetCurrentMessage() {
            if (this.allowThreading) {
                this.replyMode = true;
            }
            this.clearEditor(); //clear editor after message has been sent
            this.toggleExpandEditor();
            this.setSendingState(false);

            if (this.autoSaveDraft) {
                this.FeedService.deleteDraftMessage(this.modelInstance._id);
            }
            this.refreshNewMessageText();
        };

        this.getAllUploadingFilePromises = function getAllUploadingFilePromises() {
            return this.$q.all(this._.map(this.uploadIngFilesPromises, function (node) {
                return node.fileUploadPromise;
            }));
        };

        this.submit = function submit() {

            if (this.editor === null || !this.hasContent() || this.sending) {
                return;
            }

            this.statusText = "";
            this.setSendingState(true);

            // create an array of promises needs to resolve
            // before the actual send
            var beforeSendPromises = [];

            // uploading images promises (as one combined), if exists
            var uploadImagesPromise = this.getAllUploadingFilePromises();
            if (uploadImagesPromise) {
                beforeSendPromises.push(uploadImagesPromise);
            }

            // pvl save promise
            if(this.pvl && this.pvl.preferred_vendor_ids && this.pvl.preferred_vendor_ids.length) {
                var pvlSavePromise = this.PvlService.savePreferredVendorsList(this.pvl);
                beforeSendPromises.push(pvlSavePromise);
            }

            // wait for all promises to resolve, or fire imidiately
            if(beforeSendPromises.length > 0) {
                this.$q.all(beforeSendPromises).then(function success() {
                    this.sendMessage();
                }.bind(this), function error() {
                    this.setSendingState(false);
                    this.resetFiles();
                    this.saveDraft();
                    this.setStatusText("COMMON_FEED_COMPONENTS.MESSAGE_EDITOR._SENDING_WITH_FILES_FAILED_");
                }.bind(this));
            } else {
                this.sendMessage();
            }
        };

        this.toggleExpandEditor = function() {
            var editor = angular.element('.cke_wysiwyg_div');
            var textareaPreview = angular.element('#messageEditorPlaceholder');
            var editorContainer = angular.element('#cke_messageEditorPlaceholder');
            var visible   = {display: 'block', visibility: 'visible'};
            var invisible = {display: 'none',  visibility: 'hidden'};

            if (this.isEditorExpanded) {
                textareaPreview.css(visible);
                editorContainer.css(invisible);
            } else {
                textareaPreview.css(invisible);
                editorContainer.css(visible);
                editor.focus();
            }

            _toggleExpandForMobile();

            this.isEditorExpanded = !this.isEditorExpanded;
        };

        this._toggleToolbox = function(switchOn) {
            if (typeof(switchOn) !== "boolean" || this.toolboxActive !== switchOn) {
                var editorToolbox = angular.element('.cke_bottom');
                if (editorToolbox.css('display') === 'none') {
                    editorToolbox.css('display', 'block');
                } else {
                    editorToolbox.css('display', 'none');
                }
                this.toolboxActive = !this.toolboxActive;
            }
        };

        //    FEED TEMPLATES
        this.closeTemplateMenu = function closeTemplateMenu() {
            this.showEmailTemplates = false;
        };

        this.emailTemplates = function emailTemplates() {
            this.showEmailTemplates = !this.showEmailTemplates;
        };

        this.templateChosen = function templateChosen(template) {
            var templateId = template.id();

            var analyticsArgs = {
                company_id: this.company.id(),
                template_type: 'email',
                template_id: templateId,
                source: 'email editor'
            };

            var self = this;
            TemplatesManager.getEmail(this.company, this.user, templateId).then(
                function success() {
                    var currentTemplate = _.find(self.company.vendor_emails, function (template) {
                        return template._id === templateId;
                    });

                    if (self.replyMode) {
                        self.editorSwitchThreadMode();
                    }
                    self.subject = currentTemplate.subject;
                    var html = this.$(currentTemplate.html_body);
                    var clientName = this.getFirstClientFirstName();
                    if (!clientName) {
                        self.body = currentTemplate.html_body;
                    } else {
                        var inputFields = html.find('input');
                        for (var i=0; i<inputFields.length; i++) {
                            var input = inputFields[i];
                            var isClientField = false;
                            if (input.placeholder === 'First Client First Name') {
                                isClientField = true;
                            } else if (input.name) {
                                var field = this.InputFieldsService.getField(input.name);
                                if (field.fieldDefault === "first_client_first_name" || field.field_name === "client_first_name_1") {
                                    isClientField = true;
                                }
                            }
                            if (isClientField) {
                                this.$(input).replaceWith(this._createInputField(clientName));
                            }
                        }

                        self.body = "";
                        html.each(function (index) {
                            if (html[index].outerHTML) {
                                self.body += html[index].outerHTML;
                            }
                        });
                    }

                    self.images = currentTemplate.image_attachments || [];
                    self.files = currentTemplate.file_attachments || [];
                    self.setContents(this.UIUtils.getHtmlBodyCleanInlineStyle(self.body), self.images, self.files);

                    // Check if template has a signature and replace to current user just in case (server does it but..)
                    if (this.$element.find('.editor-email-signature').length > 0) {
                        this.removeEmailSignature();
                        this.initEmailSignature();
                        this.setRemoveSignature();
                    }

                    self.handleContentUpdate();
                    AnalyticsService.track(self, AnalyticsService.analytics_events.choose_template_success, analyticsArgs);
                }.bind(this), function error() {
                    AnalyticsService.track(self, AnalyticsService.analytics_events.choose_template_error, analyticsArgs);

                }.bind(this)
            );
        };

        this.pvl = this.PvlService.getEmptyPvl();


        if (this.templateId) {
            this.isEditorExpanded = true;
            this.loadEditor();

            var templateObject = {
                _id: this.templateId,
                id: function id() {
                    return templateObject._id
                }
            };

            this.editor.on('instanceReady', function () {
                this.templateChosen(templateObject);
            }.bind(this));
        }

        //keep this last so that the object will be fully constructed
        FeedService.registerFeedEditor(this);
        $scope.$on('$destroy', function destroy() {
            FeedService.unregisterFeedEditor();
            $document.off('.feed.honeybook');
        });

        if (this.config.showEditorAlwaysExtended) {
            this.loadEditor();
        }

    }

    function feedMessageEditorLink($scope, $element) {
        $scope.feedMessageEditorVm.setDomElements($element);
    }

    var FeedMessageEditorDirectiveController = Class(Controllers.BaseController, {
        constructor: FeedMessageEditorDirectiveControllerFunc,

        saveDraft: function saveDraft() {
            if(!this.autoSaveDraft){
                return;
            }

            if (this.saveDraftPromise) {
                this.$timeout.cancel(this.saveDraftPromise);
            }

            this.saveDraftPromise = this.$timeout(function() {
                this.FeedService.saveDraftMessage(this.modelInstance._id, {
                    htmlBody: this.editor.getData(),
                    images: this.imageFiles,
                    files: this.generalFiles,
                    pvl: this.pvl,
                    subject: this.subject,
                    replyMode: this.replyMode
                });
            }.bind(this), 300);
        },

        getFirstClientFirstName: function getFirstClientFirstName() {
            return this.UserService.getFirstClientFirstName(this.users, this.user);
        },

        extractRecipientsArray: function extractRecipientsArray(isFullName) {
            var clients = this.users.filter(function(user) {
                return user.system_user_type === 'client' && user._id !== this.user._id;
            }.bind(this));
            var vendors = this.users.filter(function(user) {
                return user.system_user_type === 'vendor' && user._id !== this.user._id;
            }.bind(this));

            var allRecipients = this._.union(clients, vendors);

            if(isFullName) {
                return this._.pluck(allRecipients, 'full_name');
            } else {
                return this._.pluck(allRecipients, 'first_name');
            }
        },

        getSafeEditorHeader: function getSafeSubject(isShowTooltip, defaultPrefixText) {
            var formattedEditorHeaderString;
            if (this.replyMode && this.lastMessageSubject != null) {
                if (this.isEditorExpanded) {
                    formattedEditorHeaderString = this.getSafeFormattedRecipientsString(isShowTooltip, 'Reply to: ');
                } else {
                    var lastMessageSubject = this.lastMessageSubject.startsWith('Re:') ? this.lastMessageSubject.replace('Re: ','') : this.lastMessageSubject;
                    formattedEditorHeaderString = (!!lastMessageSubject) ? "Reply to: '" + this.UIUtils.sanitize(lastMessageSubject) + "'" : "Reply to:";
                }
            } else {
                formattedEditorHeaderString = this.getSafeFormattedRecipientsString(isShowTooltip, defaultPrefixText);
            }
            return formattedEditorHeaderString;
        },

        setSafeRecipientsStringOnFakeCkEditor: function setRecipientsStringOnFakeCkEditor() {
            var fakeCkEditorPlaceholder = $(angular.element('.fake-ckeditor-placeholder')[0]);
            if (fakeCkEditorPlaceholder) {
                var safeEditorHeader = this.getSafeEditorHeader(false, 'Type your email message to ');
                fakeCkEditorPlaceholder.html(safeEditorHeader);


            }
        },

        getRecipientsString: function getRecipientsString() {

            if(!this.users) {
                return;
            }

            this.setSafeRecipientsStringOnFakeCkEditor();
            var safeEditorHeader = this.getSafeEditorHeader(true, 'Send to: ');

            return this.$sce.trustAsHtml(safeEditorHeader);
        },

        getSafeFormattedRecipientsString: function getSafeFormattedRecipientsString(isShowTooltip, prefixText) {

            var allRecipients = this.extractRecipientsArray(false);

            var numOfRecipients = allRecipients.length;
            var slicedRecipients = allRecipients.slice(0, this.MAX_DISPLAY_RECIPIENTS);
            var lastElem = '';

            var toolTipText;
            if (allRecipients.length > this.MAX_DISPLAY_RECIPIENTS) {
                toolTipText = this.getSafeAllRecipients();
            }

            var tooltipHtml = '<span class="feed-editor__recipients-tooltip css-tooltip css-tooltip-top" data-css-tooltip="' + toolTipText  + '">';
            var formattedRecipients = '';

            if (!slicedRecipients.length) {
                // only me
                formattedRecipients ='Send email';
            } else if (slicedRecipients.length < this.MAX_DISPLAY_RECIPIENTS) {
                // no need for count the overflow

                if (slicedRecipients.length > 1) {
                    // there are more than 1, the last one will have "and " before
                    lastElem = ' and ' + slicedRecipients.pop();
                }
                formattedRecipients = this.toSafeString(prefixText + slicedRecipients.join(', ') + lastElem);
            } else {
                // there are 3 recipients or more

                var numOfSlicedRecipients = slicedRecipients.length;
                var othersCount = numOfRecipients - numOfSlicedRecipients;

                if(!othersCount) {
                    // recipients are exactly 3. no overflow needed.
                    // btw, this must be here and not in the previous block since in case there are more than 3,
                    // we will never get to here, since slice will always return 3.
                    lastElem = slicedRecipients.pop();
                } else {
                    // recipients more than 3.
                    lastElem = othersCount + ' other' + (othersCount > 1 ? 's' : '');
                }

                formattedRecipients = prefixText + this.toSafeRecipientNames(slicedRecipients) + ' and ' + (isShowTooltip && toolTipText ? tooltipHtml : '') + this.toSafeString(lastElem) + '</span>';
            }

            return formattedRecipients;
        },

        toSafeRecipientNames: function toSafeRecipientNames(recipientsArray) {
            var concatedRecipients = recipientsArray.join(', ');
            return this.toSafeString(concatedRecipients);
        },

        toSafeString: function toSafeString(text) {
            return this.UIUtils.sanitize(text);
        },

        getSafeAllRecipients: function getAllRecipients() {
            var allRecipients = this.extractRecipientsArray(true);

            var recipientsString;
            if (allRecipients.length > 0) {
                var lastRecipient = allRecipients.pop();
                recipientsString = allRecipients.join(', ') + ' and ' + lastRecipient;
                recipientsString = this.toSafeString(recipientsString);
            }
            return recipientsString;
        },

        populateMessageEditor: function populateMessageEditor(htmlBody, images, files, flushCurrentContent) {
            if (!this.isEditorLoaded) {
                this.loadEditor();
                this.editor.on('instanceReady', function() {
                    //call function again after editor instance is ready
                    this.populateMessageEditor(htmlBody, images, files, flushCurrentContent);
                }.bind(this));
                return;
            } else if (!this.isEditorExpanded) {
                this.toggleExpandEditor();
            }
            if (this.hasContent()) {
                if (flushCurrentContent) {
                    this.clearEditor();
                } else {
                    this.setRemoveSignature();
                    //we don't want to delete an unsent message the client has been working on
                    return;
                }
            }
            this.setContents(htmlBody);

            if (Array.isArray(images)) {
                images.forEach(function(image) {
                    image.progress = 100;
                    image.s3Response = {};
                    image.s3Response.url = image.dataUrl;
                });
                this.imageFiles = images;
            } else {
                this.imageFiles = [];
            }

            if (Array.isArray(files)) {
                files.forEach(function(file) {
                    file.progress = 100;
                    file.s3Response = {};
                    file.s3Response.url = file.dataUrl;
                });
                this.generalFiles = files;
            } else {
                this.generalFiles = [];
            }

            this.setRemoveSignature();
            this.handleContentUpdate();
            this.$scope.$digest();
        },

        callOnSharePreferredVendors: function callOnSharePreferredVendors(pvl) {
            if (!this.isEditorLoaded) {
                this.loadEditor();
                this.editor.on('instanceReady', function() {
                    //call function again after editor instance is ready
                    this.callOnSharePreferredVendors(pvl);
                }.bind(this));
                return;
            } else if (!this.isEditorExpanded) {
                this.toggleExpandEditor();
            }
            this.onSharePreferredVendors(pvl);
        },

        enableSendTooltip: function enableSendTooltip(tooltipText) {
            this.showSendTooltip = true;
            this.tooltipText = tooltipText;
        },

        disableSendTooltip: function disableSendTooltip() {
            this.tooltipText = "";
        },

        onSharePreferredVendors: function onSharePreferredVendors(pvl, flow_type) {

            // set pvl
            if(pvl) {
                this.pvl = pvl;
            }

            // if we already attached the pvl before
            var firstAttach = !(this.pvl.preferred_vendor_ids && this.pvl.preferred_vendor_ids.length);

            // update workspace id
            this.pvl.workspace_id = this.modelInstance._id;

            this.PvlService.editPreferredVendorsList(this.pvl).then(function() {

                // Clean up in case vendor closed PVL modal without selecting any vendor
                if (!this.pvl.preferred_vendor_ids || this.pvl.preferred_vendor_ids.length === 0) {
                    this.pvl = this.PvlService.getEmptyPvl();
                    return;
                }

                if(flow_type) {
                    this.pvl.flow_type = flow_type;
                }

                // if this is the first attach, add to the editor
                if (firstAttach && this.pvl.preferred_vendor_ids.length) {

                    if (this.getTextualContents() === "") { //no previous text in editor, add text for PVL
                        var recommendationsType = this.config.isAlternateVendorRecommendations ? 'alternate_vendors' : 'preferred_vendors';
                        this.setContents(this.FeedService.getDefaultRecommendationsText(this.user, recommendationsType));
                        this.handleContentUpdate();
                    }
                }

            }.bind(this));
        },

        onAddEmailSignature: function onAddEmailSignature() {
            if (this.user.email_signatures.length === 0) {
                return;
            }

            var signatureElement =  this.$element.find('.editor-email-signature');
            if (signatureElement.length > 0) {
                this.removeEmailSignature("message_editor_toolbar");
            } else {
                this.initEmailSignature();

                // Add "Remove Signature" button
                this.setRemoveSignature();
            }
        },

        initEmailSignature: function initEmailSignature() {

            var sig = this.user.email_signatures[0];

            if (sig && sig.html_body) {
                var $tmp = new this.CKEDITOR.dom.element('div');
                $tmp.setAttributes({
                    id: this.uuid4.generate(),
                    class: 'editor-email-signature ck-content vendor-field email-editor-field',
                    name: 'EmailSignature',
                    contenteditable: "false"
                }).setHtml(sig.html_body);

                // Move selection to the end of the editable element.
                var range = this.editor.createRange();
                range.moveToPosition(range.root, this.CKEDITOR.POSITION_BEFORE_END);
                this.editor.getSelection().selectRanges([range]);
                this.editor.insertElement($tmp);
                //this.editor.getSelection().selectElement($tmp);
                this.editor.focus();
                this.handleContentUpdate();
            }
        },

        removeEmailSignature: function removeEmailSignature(source) {
            this.AnalyticsService.track(this, "click: remove email signature from feed", {source: source});
            var signatureCont = this.$element.find('.editor-email-signature');
            if (signatureCont.length === 1) {
                var next = signatureCont.next();
                if (next && next.prop("tagName") && next.text() === "") {
                    next.remove();
                }
                signatureCont.remove();
                this.editor.focus();
                this.handleContentUpdate();
            }
        },

        setRemoveSignature: function setRemoveSignature() {
            var signatureCont = this.$element.find('.editor-email-signature');
            if (this.$element.find('#removeEmailSignature').length === 0) {
                signatureCont.prepend('<span id="removeEmailSignature" class="remove-signature-email"></span>');
            }
        },

        removePVL: function removePVL() {
            // do something that removes or marks the pvl as deleted
            // or maybe save it while editing in case the user changes his mind
            // as a draft or something
            this.pvl = this.PvlService.getEmptyPvl();
        },

        _createInputField: function _createInputField(fieldValue, isEditable, addSpace) {
            return '<span class="feed-editor input-field" ' +
                'contentEditable="' + (isEditable ? "true" : "false" ) + '">' +
                fieldValue + "</span>" + (addSpace ? "<span>&nbsp;</span>" : "");
        },

        onAddClientNameField: function onAddClientNameField() {
            var clientName = this.getFirstClientFirstName();
            if (clientName) {
                var fieldHtml = this._createInputField(clientName, false, true);
                this.editor.insertHtml(fieldHtml);
                this.editor.focus();
                this.handleContentUpdate();
            }
        },

        onAddCalendlyLink: function onAddCalendlyLink() {
            this.editor.insertText(this.user.calendly_url);
            this.editor.focus();
            this.handleContentUpdate();
        },

        setEditorPlaceholder: function setEditorPlaceholder(placeholder) {

            if(!placeholder) {
                return;
            }

            if (!this.isEditorLoaded) {
                this.loadEditor();
                this.editor.on('instanceReady', function () {
                    this.setEditorPlaceholder(placeholder);
                }.bind(this));
            } else {
                if(this.$ckeditorPlaceholder) {
                    this.$ckeditorPlaceholder.text(placeholder);
                }
            }
        },

        editorSwitchThreadMode: function editorSwitchThreadMode() {
            this.replyMode = !this.replyMode;
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.feed_reply_mode_toggle, {reply_mode: this.replyMode});
            this.refreshNewMessageText();
        },

        refreshNewMessageText: function refreshNewMessageText() {
            if (this.replyMode && this.lastMessageToReply) {
                this.subject = this.lastMessageSubject;
                this.last_feed_to_reply_id = this.lastMessageToReply._id;
            } else {
                this.subject = null;
                this.FeedService.deleteDraftMessage(this.modelInstance._id);
                this.last_feed_to_reply_id = null;
            }
            this.getRecipientsString();
        },

        deleteDraft: function deleteDraft() {
            this.clearEditor();
            this.onCancelScheduler();

            if (this.autoSaveDraft) {
                this.FeedService.deleteDraftMessage(this.modelInstance._id);
            }
            this.refreshNewMessageText();
        },

        _initScheduling : function _initScheduling() {
            this.schedulingLink = null;
            this.loadSessionsData().then(function() {
                // only vendors, not mweb, and only when we have session types
                this.showSchedulingLink = this.isVendor && (!this.isMobile || (this.groupSessions.length || this.individualSessions.length));

                // when sessions are loaded, check if we need to continue the scheduling exposure flow
                if (this.shouldShowAddSchedulingLinkReminder()) {
                    this.loadEditor();
                    this.showAddLinkReminder();
                }
            }.bind(this));
        },

        handleAddSchedulingLink : function handleAddSchedulingLink() {
            var schedulingLinkName = this.schedulingLinkName || 'Session link';
            var schedulingLinkElem = '<a target="_blank" rel="noopener noreferrer" href="' + this.schedulingLink + '" data-is-scheduling-link="true">' + schedulingLinkName + '</a>';
            this.editor.insertHtml(schedulingLinkElem);
            this.handleContentUpdate();
            this.schedulingSessionPopoverOpen = false;
            this.schedulingLinkPopoverOpen = false;
            this.schedulingLinkName = "";

        },

        _sortSessions: function _sortSessions(sessions){
            sessions.forEach(function (session) {
                if (session.schedule_behaviour_type) {
                    this.groupSessions.push(session);
                } else {
                    this.individualSessions.push(session);
                }
            }.bind(this));
        },

        loadSessionsData: function loadSessionsData() {
            this.individualSessions = [];
            this.groupSessions = [];
            return this.user.getSessions(null, null, true, false).then(function(sessionConfigs) {
                var enabledSessionsList = sessionConfigs.filter(function(s) { return s.enabled; } );
                this._sortSessions(enabledSessionsList);
            }.bind(this));
        },

        getSessionType: function getSessionType(sessionType){
            var sessionTypeObj = this.meetingTypes.find(function(type) { return type.key === sessionType; }.bind(this));
            return sessionTypeObj.label;
        },

        getWindowType: function getWindowType(windowType){
            var windowTypeObj = this.windowTypes.find(function(type) { return type.key === windowType; }.bind(this));
            return windowTypeObj.label;
        },

        selectSession: function selectSession(session) {
            this.schedulingLink = session.session_url;
            if(this.schedulingLink){
                this.schedulingSessionPopoverOpen = false;
                this.schedulingLinkPopoverOpen = true;
            }
        },

        onSetScheduledEmail: function onSetScheduledEmail() {
            this.showingEmailScheduler = true;

            this.ModalService.openEmailSchedulerModal().then(
                function success(resp) {
                    this.showingEmailScheduler = false;
                    if (resp.epochSelectedDate) {
                        this.scheduledSendDate = resp.epochSelectedDate;

                        // In case editor was closed during scheduling, expand
                        if (!this.isEditorExpanded) {
                            this.toggleExpandEditor();
                        }
                    }
                    this.showEmailScheduler = false;
                }.bind(this),
                function error(resp) {
                    this.showingEmailScheduler = false;
                    this.scheduledSendDate = null;
                })
                .finally(function final() {
                    this.setSendButtonText();
                }.bind(this));
        },

        onCancelScheduler: function onCancelScheduler() {
            this.showingEmailScheduler = false;
            this.scheduledSendDate = null;
            this.setSendButtonText();
        },

        setSendButtonText: function setSendButtonText() {
            if (this.scheduledSendDate) {
                this.sendButtonCaptionTranslationKey = 'FREQUENT_BUTTONS._SCHEDULE_SEND_';
            } else {
                this.sendButtonCaptionTranslationKey = this.config.sendButtonCaptionTranslationKey || 'FREQUENT_BUTTONS._SEND_';
            }
        },

        _hasSchedulingSessionsConfigured: function hasSchedulingSessionsConfigured() {
            return (this.groupSessions.length || this.individualSessions.length);
        },

        showAddLinkReminder: function showAddLinkReminder() {
            this.addLinkReminderPopoverOpen = true;
            this.schedulingSessionPopoverOpen = true;
        },

        shouldShowAddSchedulingLinkReminder: function shouldShowAddSchedulingLinkReminder() {
            var uiPersistenceObject = this.UiPersistenceService.getUiPersistence(
                this.UiPersistenceService.keys.showAddSchedulingLinkReminder);
            if (uiPersistenceObject && uiPersistenceObject.workspace_id === this.modelInstance._id) {
                this.UiPersistenceService.setUiPersistence(
                    this.UiPersistenceService.keys.showAddSchedulingLinkReminder,
                    {workspace_id: null}
                );
                if (this._hasSchedulingSessionsConfigured()) {
                    return true;
                }
            }
            return false;
        },

        handleCreateSchedulingSession: function handleCreateSchedulingSession() {
            this.UiPersistenceService.setUiPersistence(
                this.UiPersistenceService.keys.showAddSchedulingLinkReminder,
                {workspace_id: this.modelInstance._id}
            );
            this.$state.go(this.AppStates.root_core_sessionConfig, {
                sessionId: 'new',
                userId: this.user._id,
                returnTo: {
                    eventId: this.modelInstance.event._id,
                    workspaceId: this.modelInstance._id
                }
            });
        },
    });

    return {
        scope: {
            modelInstance: '=modelInstance',
            modelType: '@modelType',
            sendMessageFunc: '&sendMessageFunc',
            viewMode: '=viewMode',
            config: '=?config',
            users: '=?users',
            lastMessageToReply: '=lastMessageToReply',
            templateId: '<?'
        },

        templateUrl: 'angular/app/modules/core/features/feed/feed_messenger/feed_message_editor_directive_template.html',
        controller: FeedMessageEditorDirectiveController,
        controllerAs: 'feedMessageEditorVm',
        bindToController: true,
        link: feedMessageEditorLink
    };
};
