(function () {
    "use strict";

    class CompanySettingsBankComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $stateParams, uuid4, UsersManager, ModalService, $q, CompaniesManager, PopupMessageService, FullStoryService,
                    StripeHelperService, AnalyticsService, FeaturesService, $translate, _, OnboardingService, UIUtils,
                    GoogleTagManagerService, AppStates, $state, DeviceService, MobileAppService) {
            super($scope, $injector);
            this.__objectType = 'CompanySettingsBankController';
            this.DeviceService = DeviceService;
            this.isInAppBrowser = this.DeviceService.isInAppBrowser();
            this.MobileAppService = MobileAppService;
            this.isSmallScreen = this.DeviceService.nxSmallBreakpoint();
            this.ModalService = ModalService;
            this.$state = $state;
            this.$q = $q;
            this.CompaniesManager = CompaniesManager;
            this.PopupMessageService = PopupMessageService;
            this.AnalyticsService = AnalyticsService;
            this.StripeHelperService = StripeHelperService;
            this.$translate = $translate;
            this.AppStates = AppStates;
            this._ = _;
            this.OnboardingService = OnboardingService;
            this.UIUtils = UIUtils;
            this.UsersManager = UsersManager;
            this.GoogleTagManagerService = GoogleTagManagerService;
            this.FullStoryService = FullStoryService;
            this.alreadyTracking = FullStoryService.isTracking;

            AnalyticsService.trackPageView(this, 'bank settings screen');

            this.UsersManager.forceFetchCurrUser();

            this.user = UsersManager.getCurrUser();
            this.company = CompaniesManager.getCurrCompany(true);
            this.isCanada = this.user.company.country === 'CA';
            this.entityIdsBeingDeleted = {};
            this.oldAccountNoStrucutureDirectorsUnknown =  false;
            this.hideMissingInfoBanner = false;

            // Instant Deposits
            this.debitCard = this.user.myDebitCard();
            this.refreshBankAndPersonal();

            this.addDirectorButtonAlert = this.bankAccount && this.showDirectorsSection && this.businessOwners.length > 0 && !this.hideMissingInfoBanner;
            this.addOwnerButtonAlert = !!this.bankAccount && this.businessOwners.length === 0 && !this.hideMissingInfoBanner;


            if (!this.bankAccountType && this.bankAccount && this.bankAccount.type) {
                this.bankAccountType = (this.bankAccount.type.toLowerCase() === 'company' || this.bankAccount.type.toLowerCase() === 'corporation') ? "corp" : "individual";
            }

            this.learnHowToHelpCenterLink = this.isCanada ? "https://help.honeybook.com/en/articles/9518366" :
                                            (this.bankAccount ? 'https://help.honeybook.com/en/articles/2209040' : 'https://help.honeybook.com/en/articles/2209105');

            this.register(this.user, 'success', () => {
                this.refreshBankAndPersonal();
            });

            this.FullStoryService.trackEvent('on bank details page', {
                path: window.location.pathname,
                action: "loaded"
            });

            this.updateSubtitle();
        }

        refreshBankAndPersonal() {
            this.bankAccount = this.user.myBankAccount();
            this.stripePersons = this.user.company.stripe_persons || [];
            this.businessOwners = this.stripePersons.filter(function (pm) { return !pm.director;}) || [];
            this.businessDirectors = this.stripePersons.filter(function (pm) { return pm.director;}) || [];

            // First copy directors not provided request to var and remove from fields to fix list as not related to bank
            if (this.isCanada && !!this.bankAccount && !this.bankAccount.company_structure && !!this.bankAccount.global_fields_to_fix && this.bankAccount.global_fields_to_fix.includes('directors_provided')) {
                this.oldAccountNoStrucutureDirectorsUnknown =  true;
            } else {
                this.oldAccountNoStrucutureDirectorsUnknown =  false;
            }

            // Directors are required for CA private corporations. In case of old CA managed account set as a company, we don't know the company structure so we will ask for director info
            this.showDirectorsSection = this.isCanada && !!this.bankAccount && ((this.businessDirectors.length > 0) ||
                (this.bankAccount.type.toLowerCase() === "company" || this.bankAccount.type.toLowerCase() === "corporation") &&
                (this.bankAccount.company_structure === 'private_corporation' || this.oldAccountNoStrucutureDirectorsUnknown));
        }

        showInstantDepositSection() {
            // note: eligible_for_instant_payouts is actually enough, left allow_instant_payouts to avoid reload
            return !this.company.eligible_for_finance_app_payments &&
                this.hasBankAccount() &&
                (this.company.eligible_for_instant_payouts || this.company.allow_instant_payouts);
        }

        onIdentifyBusinessTypeClick() {
            this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.onIdentifyBusinessTypeClick, { });
        }

        isOldBankAccount() {
            return this.bankAccount && !this.bankAccount.merchant_category_code && !this.bankAccount.doing_business_as_name;
        }

        additionalDocumentsRequired() {
            return this.company.company_verification_documents.length > 0;
        }

        additionalDocumentsCtaText() {
            if (this.company.company_verification_documents.length === 1 && this.company.company_verification_documents[0].key === 'bank_ownership_verification') {
                return this.$translate.instant("COMPANY_SETTING.BANK_ACCOUNT.ADDITIONAL_DOCUMENTS._UPLOAD_BANK_VERIFICATION_");
            } else {
                return this.$translate.instant("COMPANY_SETTING.BANK_ACCOUNT.ADDITIONAL_DOCUMENTS._UPLOAD_CTA_");
            }
        }

        isPendingStripeFutureRequirements() {
            return this.company.payment_gateway_future_requirements_action && this.company.payment_gateway_future_requirements_action !== 'none';
        }

        onAddOwnerClick(clickLocation) {
            this.AnalyticsService.trackClick(
                this,
                "add business owner",
                { currentOwnerCount: this.businessOwners.length, clickLocation: clickLocation, currentPersonsCount: this.stripePersons.length}
            );
            this._showAddOwnerForm(undefined, false);
        }

        onAddDirectorClick() {
            this.AnalyticsService.trackClick(
                this,
                "add business director",
                { currentDirectorCount: this.businessDirectors.length}
            );
            this._showAddDirectorForm(undefined, false);
        }

        onOwnerEdit(owner) {
            this._showAddOwnerForm(owner, false);
        }

        onDirectorEdit(owner) {
            this._showAddDirectorForm(owner, false);
        }

        onOwnerDelete(owner) {
            this.AnalyticsService.trackClick(this, "delete business owner");
            this._showDeleteConfirmation("COMPANY_SETTING.BANK_ACCOUNT._DELETE_OWNER_POPUP_TEXT_").then(() => {
                this.entityIdsBeingDeleted[owner._id] = true;
                this.CompaniesManager.deleteBankAccountOwner(this.user.company, owner._id).then(()  => {
                    delete this.entityIdsBeingDeleted[(owner || {})._id];
                    this.AnalyticsService.track(this, "business owner deleted");
                    this.refreshBankAndPersonal();
                }).catch((resp) => {
                    this.entityIdsBeingDeleted[owner._id] = false;
                    if (resp.data && resp.data.error_type === 'HBUserError') {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                    } else {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNEXPECTED_');
                    }
                });
            });
        }

        onBankAccountTypeSelected(type) {
            if (!this.user.securityPhoneNumberLastFourDigits()) {
                this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                    `COMPANY_SETTING.BANK_ACCOUNT._MISSING_SECURITY_PHONE_NUMBER_`,
                    () => {
                        this.goToState(this.AppStates.root_core_navigation_settings_account);
                    },
                    undefined,
                    "Update Security Number");
                return;
            }

            this.bankAccountType = type;

            if (type === 'personal' && this.company.stripe_persons && this.company.stripe_persons.length > 1) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info,
                    `You have listed ${this.company.stripe_persons.length} business owners, yet a personal bank account should have only one owner associated with it. Either remove other owners' info or select a business bank account to list more than one owner.`);
                return;
            }
            this.hideMissingInfoBanner = true;
            this.ModalService.openBankAccountBusinessInfoModal(type)
                .then(() => {
                    this.refreshBankAndPersonal();
                    if (!this.businessOwners || !this.businessOwners.length) {
                        this._showAddOwnerForm(undefined, true);
                    }
                })
                .catch(() => 
                    this.AnalyticsService.trackCancel(
                        this,
                        "bank account modal"
                    )
                );
        }

        onBankAccountEdit(account) {
            const type = account.type.toLowerCase(); // account.type === "Individual" ? "personal" : "business";
            this.ModalService.openBankAccountBusinessInfoModal(type);
        }

        onBankAccountDelete() {
            this.AnalyticsService.trackClick(this, "delete bank account");
            var last4 = this.bankAccount.account_number_last4 ? this.bankAccount.account_number_last4.slice(-4) : 'N/A';
            this._showDeleteConfirmation(
                "COMPANY_SETTING.BANK_ACCOUNT._DELETE_ACCOUNT_POPUP_TEXT_",
                {accountNumber: last4}
            ).then(() => {
                this.entityIdsBeingDeleted[this.bankAccount._id] = true;
                this.AnalyticsService.track(this, "bank account deleted");
                return this.user.deleteBankAccount(this.bankAccount)
                                .then(() => {
                                    this.hideMissingInfoBanner = true;
                                    delete this.entityIdsBeingDeleted[(this.bankAccount || {})._id];
                                    this.UsersManager.forceFetchCurrUser();
                                    this.company.stripe_persons = [];
                                    this.businessOwners = [];
                                    this.businessDirectors = [];
                                    this.bankAccount = null;
                                    this.debitCard = null;
                                });
            }).catch((resp) => {
            });

            this.updateSubtitle();
        }

        updateSubtitle() {
            if (!this.bankAccount) {
                return;
            }

            if (this.isPendingStripeFutureRequirements() && this.bankAccount.future_due_by_verification_date) {
                if (this.isOldBankAccount()) {
                    this.subtitle_text = this.$translate.instant('COMPANY_SETTING.BANK_ACCOUNT._PENDING_STRIPE_VERIFICATION_RECREATE_', {due_date: this.bankAccount.future_due_by_verification_date});
                } else {
                    this.subtitle_text = this.$translate.instant('COMPANY_SETTING.BANK_ACCOUNT._PENDING_STRIPE_VERIFICATION_UPDATE_', {due_date: this.bankAccount.future_due_by_verification_date});
                }
            } else {
                // No idea why its here... comment and monitor
                // this.subtitle_text = this.isOldBankAccount() ?
                //     "COMPANY_SETTING.BANK_ACCOUNT._NEW_STRIPE_SUBTITLE_" :
                //     "COMPANY_SETTING.BANK_ACCOUNT._SUBTITLE_";
            }
        }

        hasBankAccount() {
            return !this._.isEmpty(this.bankAccount);
        }

        totalOwnershipPercentage() {
            return (this.businessOwners || []).reduce(
                (acc, person) => acc + person.ownership_percentage,
            0);
        }

        isTotalOwnershipFull() {
            return this.totalOwnershipPercentage() > 75; // we do not allow to add owner with less then 25%
        }

        addOwnerTooltipText() {
            if (!this.hasBankAccount()) {
                return this.$translate.instant(
                    "COMPANY_SETTING.BANK_ACCOUNT._ADD_OWNER_DISLABED_TOOLTIP_TEXT_"
                );
            }

            if (this.bankAccount.type !== "Individual" && this.isTotalOwnershipFull()) {
                return this.$translate.instant(
                    "COMPANY_SETTING.BANK_ACCOUNT._FULL_OWNERSHIP_TOOLTIP_TEXT_"
                );
            }  
            
            return null;
        }

        // instant deposits

        onAddDebitCardClick() {
            this.ModalService.openAddDebitCardModal('companySettings')
                .then((resp) => {
                    this.UsersManager.forceFetchCurrUser().then(() => {
                        this.user = this.UsersManager.getCurrUser();
                        this.debitCard = this.user.myDebitCard();
                    });
                }).catch((resp) => {

            });
        }

        deleteDebitCard() {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.delete_debit_card);
            this. _showDeleteConfirmation( 'COMPANY_SETTING.BANK_ACCOUNT._DELETE_ACCOUNT_POPUP_TEXT_',
               {accountNumber: this.debitCard.last4}).then(() => {
                this.user.deletePaymentMethod(this.debitCard._id, true).then(() => {
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.delete_debit_card);
                    this.debitCard = null;
                }).catch((e) => {
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.delete_debit_card, {error_message: e});

                });
           }).catch((e) => {
                this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.debit_card_delete_confirmation, {error_message: e});
            });
        }

        toggleGlobalInstantDeposit() {
            const newAllInclusiveInstantPayouts = this.company.all_inclusive_instant_payouts;
            // console.log(`turn instant deposits ${newAllInclusiveInstantPayouts ? 'on' : 'off'} for all payments`);
            this.CompaniesManager.updateAllInclusiveInstantPayouts(this.company, newAllInclusiveInstantPayouts, 'company_settings_universal_toggle')
                .then(resp => {
                    this.AnalyticsService.trackSuccess(this, 'universal instant deposit changed', {toggledTo: newAllInclusiveInstantPayouts, from: 'bank settings page'});
                })
                .catch(
                    function onError(resp) {
                        this.AnalyticsService.trackError(this, 'toggle global instant deposit failed', resp);
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error,
                            (resp && resp.data.error_message) || 'ERRORS.SERVER_API._UNKNOWN_');
                    }.bind(this));
        }

        isAddOwnerButtonDisabled() {
            return this.isTotalOwnershipFull() || !this.hasBankAccount();
        }

        isAddDirectorButtonDisabled() {
            // For now allow up to 3 directors
            return this.businessDirectors && this.businessDirectors.length > 2;
        }

        _showAddOwnerForm(owner, fromBankAccountForm) {
            let openAddDirectorModalWhenCompleted = fromBankAccountForm && this.showDirectorsSection && (!this.businessDirectors || !this.businessDirectors.length);

            this.bankAccount = this.bankAccount || this.user.myBankAccount();
            this.ModalService.openAddBankAccountOwnerModal(owner, fromBankAccountForm, this.bankAccountType, 'owner', !openAddDirectorModalWhenCompleted).then(() => {
                this.UsersManager.forceFetchCurrUser();
                this.registerOnce(this.CompaniesManager.getCurrCompany(true), 'success', function(){
                    this.refreshBankAndPersonal();
                    if (owner) {
                        this.ownersMissingById[owner._id] = false;
                    }
                    if (openAddDirectorModalWhenCompleted) {
                        this._showAddDirectorForm(undefined, true);
                    } else {
                        this.hideMissingInfoBanner = false;
                    }
                }.bind(this));
            });
        }

        _showAddDirectorForm(director, fromBankAccountForm) {
            let personType = this.oldAccountNoStrucutureDirectorsUnknown ? 'check' : 'director';
            this.bankAccount = this.bankAccount || this.user.myBankAccount();
            this.ModalService.openAddBankAccountOwnerModal(director, fromBankAccountForm, this.bankAccountType, personType, true).then(() => {
                this.UsersManager.forceFetchCurrUser();
                this.registerOnce(this.CompaniesManager.getCurrCompany(true), 'success', function(){
                    this.refreshBankAndPersonal();
                    if (director) {
                        this.directorsMissingById[director._id] = false;
                    }
                }.bind(this));
                this.hideMissingInfoBanner = false;
            });
        }
        
        _showDeleteConfirmation(text, textParams = {}) {
            return this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.warning, this.$translate.instant(text, textParams)
            );
        }

        isBeingDeleted(entity) {
            if (!entity) {
                return false;
            }
            return !!this.entityIdsBeingDeleted[entity._id];
        }

        missingDetailsMessage() {
            if (this.isOldBankAccount() || (!this.bankAccount && this.stripePersons && this.stripePersons.length === 0)) {
                return false;
            }

            this.bankAccountMissing = this.bankAccount && this.bankAccount.fields_to_fix && this.bankAccount.fields_to_fix.length > 0;
            this.ownersMissing = this.businessOwners.filter(person => person.fields_to_fix && person.fields_to_fix.length > 0);
            this.directorsMissing = this.businessDirectors.filter(person => person.fields_to_fix && person.fields_to_fix.length > 0);

            this.ownersMissingById = this.ownersMissing.reduce((acc, owner) => {
                acc[owner._id] = true;
                return acc;
            }, {});

            this.directorsMissingById = this.directorsMissing.reduce((acc, director) => {
                acc[director._id] = true;
                return acc;
            }, {});

            if (this.isPendingStripeFutureRequirements()) {
                // We show future reqs message and highlight missing section. Hide the Missing Details banner
                return false;
            }

            if (!this.bankAccount) {
                return this.$translate.instant(
                    "COMPANY_SETTING.BANK_ACCOUNT._BANK_INFO_MISSING_"
                );
            } else if (this.bankAccountMissing && this.ownersMissing.length) {
                return this.$translate.instant(
                    "COMPANY_SETTING.BANK_ACCOUNT._BOTH_INFO_MISSING_"
                );
            } else if (this.bankAccountMissing) {
                return this.$translate.instant(
                    "COMPANY_SETTING.BANK_ACCOUNT._BANK_INFO_MISSING_"
                );
            } else if (!this.businessOwners || this.businessOwners.length === 0) {
                return this.$translate.instant(
                    "COMPANY_SETTING.BANK_ACCOUNT._OWNER_INFO_MISSING_"
                );
            } else if (this.showDirectorsSection && (!this.businessDirectors || this.businessDirectors.length === 0)) {
                return this.$translate.instant(
                    "COMPANY_SETTING.BANK_ACCOUNT._DIRECTOR_INFO_MISSING_"
                );
            } else if (this.ownersMissing.length) {
                return this.businessOwners.length > 1 ?
                    this.$translate.instant(
                        "COMPANY_SETTING.BANK_ACCOUNT._MULTI_OWNER_INFO_MISSING_",
                        {
                            names: this.joinListWithCommasAndAmpersand(
                                this.ownersMissing.map(owner => owner.full_name)
                            )
                        }
                    ).replaceAll('&amp;', '&') :
                    this.$translate.instant(
                        "COMPANY_SETTING.BANK_ACCOUNT._OWNER_INFO_MISSING_"
                    );

            } else if (this.showDirectorsSection && this.directorsMissing.length) {
                return this.businessDirectors.length > 1 ?
                    this.$translate.instant(
                        "COMPANY_SETTING.BANK_ACCOUNT._MULTI_OWNER_INFO_MISSING_",
                        {
                            names: this.joinListWithCommasAndAmpersand(
                                this.directorsMissing.map(owner => owner.full_name)
                            )
                        }
                    ).replaceAll('&amp;', '&') :
                    this.$translate.instant(
                        "COMPANY_SETTING.BANK_ACCOUNT._DIRECTOR_INFO_MISSING_"
                    );

            }
        }

        joinListWithCommasAndAmpersand(list) {
            return list.reduce((previous, current, index, array) => index === array.length - 1 ?
                    previous + ' & ' + current : previous + ', ' + current);
        }

        onUploadAdditionalDocumentsClick() {
            this.ModalService.openUploadCompanyAdditionalDocumentsModal().then(() => {
                this.CompaniesManager.getCurrCompany(true);
            });
        }

        onStripeAgreementLinkClick(event) {
            if (this.isInAppBrowser) {
                event.preventDefault();
                this.MobileAppService.triggerAppEvent(this.MobileAppService.OUTBOUND.onStripeAgreementLinkClick, { linkUrl: "https://stripe.com/connect-account/legal" });
            }
        }

        isUpdateBusinessInfoBannerVisible() {
            return this.isPendingStripeFutureRequirements() && this.subtitle_text;
        }

        isMissingInfoBannerVisible() {
            return !this.hideMissingInfoBanner && this.missingDetailsMessage();
        }

        isFreeTxnFeesBannerVisible() {
            return !this.isUpdateBusinessInfoBannerVisible() && !this.isMissingInfoBannerVisible();
        }
    }

    Components.CompanySettingsBank = {
        controller: CompanySettingsBankComponent,
        controllerAs: 'companySettingsBankVm',
        name: 'hbCompanySettingsBank',
        templateUrl: 'angular/app/modules/core/features/settings/company/bank/company_settings_bank_template.html'
    };

}());
