(function () {
    'use strict';

    // @ngInject
    function StartFromFileWizardModalControllerCtor($scope, $injector, $modalInstance, $state,
                                                    AnalyticsService, UsersManager, WorkspaceFilesManager, CompaniesManager,
                                                    OnboardingService, WorkspaceFileService, PopupMessageService, AppStates,
                                                    fileType, workspace, _, EventsManager, EventService, Enums,
                                                    WorkspacesManager, FeaturesService,
                                                    TemplatesManager, FlowService, ReactLoaderService,
                                                    FeatureRestrictionService, StatsigService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'StartFromFileWizardModalController';

        this.$scope = $scope;
        this.$state = $state;
        this.AnalyticsService = AnalyticsService;
        this.$modalInstance = $modalInstance;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.UsersManager = UsersManager;
        this.OnboardingService = OnboardingService;
        this.WorkspaceFileService = WorkspaceFileService;
        this.PopupMessageService = PopupMessageService;
        this.AppStates = AppStates;
        this.AnalyticsService = AnalyticsService;
        this._ = _;
        this.EventsManager = EventsManager;
        this.EventService = EventService;
        this.Enums = Enums;
        this.FeaturesService = FeaturesService;
        this.TemplatesManager = TemplatesManager;
        this.FlowService = FlowService;
        this.FeatureRestrictionService = FeatureRestrictionService;

        this.user = this.UsersManager.getCurrUser();
        this.company = CompaniesManager.getCurrCompany();
        this.fileType = fileType;
        this.workspace = workspace;
        this.showBackdrop = fileType.show_backdrop || false;
        this.blankInvoiceTemplateId = fileType.blank_invoice_template_id || null;
        this.ReactLoaderService = ReactLoaderService;
        this.isSentTestFromTemplate = this.fileType.flow_source === 'send_test_template';
        this.newProjectName = this.isSentTestFromTemplate ? 'Test Project' : '';
        this.createFileTransKey = 'CREATE_FILE_WIZARD.SELECT_PROJECT._CREATE_FLOW_CTA_';
        this.StatsigService = StatsigService;

        this.StatsigService.getExperimentConfig('create_project_modal')
            .then(function (experiment) {
                this.sholdShowNewModal = experiment && experiment.get('show_new_modal', false);
                this.create_modal_text_variant = experiment && experiment.get('variant', 'none');
            }.bind(this))
            .finally(function finallyHandler() {

                this.AnalyticsService.trackPageView(
                    this,
                    'StartFromFileWizardModalView',
                    {
                        source: fileType.source || 'create file',
                        file_type: fileType.type,
                        create_project_modal: this.create_modal_text_variant
                    }
                );

                if ( this.fileType.type === 'flow') {
                    if (this.isSentTestFromTemplate) {
                        this.createFileTransKey = 'CREATE_FILE_WIZARD.SELECT_PROJECT._SENT_TEST_FROM_TEMPLATE_CTA_';
                    } else if(this.sholdShowNewModal) {
                        this.createFileTransKey = 'CREATE_FILE_WIZARD.SELECT_PROJECT._SAVE_FILE_CTA_';
                    }
                } else {
                    this.createFileTransKey = 'CREATE_FILE_WIZARD.SELECT_PROJECT._CREATE_FILE_CTA_'
                }

                this.title = this.sholdShowNewModal ? 'Where would you like to save your file?' : 'What project is this ' + (fileType.type === 'flow' ? 'smart file' : 'file')+ ' for?'

                this.steps = [{
                    title : 'Create New ',
                    errorMessage : '',
                    isValidAndSubmitted : true
                },
                    {
                        title : this.isSentTestFromTemplate
                            ? 'Pick a project and test your template'
                            : this.title,
                        errorMessage : '',
                        isValidAndSubmitted : true
                    }
                ];

                this._init();
            }.bind(this));


        WorkspacesManager.userHasAnyWorkspaces().then(function success(resp) {
            this.hasAnyWorkspaces = resp.data;
        }.bind(this));
    }

    Controllers.StartFromFileWizardModalController = Class(Controllers.BaseController, {
        constructor: StartFromFileWizardModalControllerCtor,

        _init: function _init() {

            if ( this.fileType.create_file_from_templates || this.fileType.create_file_from_legacy) {
                this.currentStep = 2;
                this.showPreviousStep = false;
                this.fileOriginModelId =  this.fileType.template_id;
                this.fileOriginType = this.Enums.createFileOriginType.template;
            } else {
                this.showPreviousStep = true;
                this.currentStep = 1;
            }
            if(this.fileType.projectListFilters){
                this.memberEmails = this.fileType.projectListFilters.participantsEmails;
            }
            if(this.fileType.clientId){
                this.clientId = this.fileType.clientId;
            }
            this.startFromNewProject = true;
            this.startFromProjectId = null;
            this.creatingFileInProgress = false;
            this.shouldShowSendTestFromTemplateAnimation = false;
            this.createFileFromWorkspace = !!this.workspace;

        },

        onComplete: function onComplete(fileOriginType, modelId) {
            this.fileOriginType = fileOriginType;
            this.fileOriginModelId = modelId;

            if(this.createFileFromWorkspace) {
                this.createFile();
                return;
            }

            this._switchStep(2);
        },
        
        onCancel: function onCancel() {
            this.dismissModal();
        },
        
        dismissModal: function dismissModal() {
            this.$modalInstance.dismiss();
        },

        _switchStep: function (stepNum) {
            this.currentStep = stepNum;
        },

        previous: function previous() {
            this._switchStep(this.currentStep - 1);
        },

        createFile: function createFile() {

            var templateId, recentFileId, promise;

            if (this.fileOriginType === this.Enums.createFileOriginType.template) {
                templateId = this.fileOriginModelId;
                if(this.blankInvoiceTemplateId) {
                    templateId = this.blankInvoiceTemplateId;
                }
            } else if(this.fileOriginType === this.Enums.createFileOriginType.file) {
                recentFileId = this.fileOriginModelId;
            }

            if (this.createFileFromWorkspace) {
                promise = this._create(this.workspace, recentFileId, templateId);
                this._handleCreateFilePromise(promise);
                return;
            }

            if (this.startFromNewProject) {
                const isBlockedAccess = this.FeatureRestrictionService.checkIfBlockedAccess();
                
                if (isBlockedAccess) {
                    this.$modalInstance.close();
                    return;
                }
                
                if (!this.newProjectName) {
                    // The new modal prevents submission with no 'New project' name
                    this.errorMessage = 'CREATE_FILE_WIZARD.SELECT_PROJECT._NEW_PROJECT_ERROR_';
                    this.AnalyticsService.track(
                        this,
                        'error: template modal new project name'
                    );
                    return;
                }
                var projectData =
                    this.EventService.createDefaultProject(
                        this.company,
                        this.newProjectName,
                        this.EventService.getDefaultProjectTypeLabel(this.company)
                    );
                projectData.workspace_member_id = this.clientId;
                if (this.fileType.type === 'flow') {
                    projectData.event_source = this.fileType.flow_source;
                    if (this.fileType.is_from_import_pdf_modal) {
                        this.$modalInstance.close(projectData.dataOnly());
                        return;
                    } else if (this.fileType.create_file_from_legacy) {
                        promise = this.TemplatesManager.createFlowFromLegacyWithProject(templateId, projectData.dataOnly());
                    } else {
                        promise = this.TemplatesManager.createFlowInstanceWithProject(templateId, projectData.dataOnly(), this.fileType.flow_source);
                    }
                } else {
                    promise = this.WorkspaceFileService.createFileWithProject(this.fileType.type, recentFileId, templateId, projectData.dataOnly());
                }
                this._handleCreateFilePromise(promise);
            } else {
                if (!this.startFromProjectId) {
                    this.errorMessage = 'CREATE_FILE_WIZARD.SELECT_PROJECT._EXISTING_PROJECT_ERROR_';
                    this.AnalyticsService.track(
                        this,
                        'error: template modal existing project name'
                    );
                    return;
                }
                var eventPromise = this.EventsManager.getEvent(this.startFromProjectId, false, true);
                eventPromise.then(function getEventSuccess(event) {
                    var workspace = event.workspaces[0];
                    if (this.fileType.is_from_import_pdf_modal) {
                        this.$modalInstance.close({ workspace_id: workspace._id });
                        return;
                    }
                    promise = this._create(workspace, recentFileId, templateId);
                    this._handleCreateFilePromise(promise);
                }.bind(this));
            }

            this.AnalyticsService.track(
                this,
                'click: create file in project ',
                {
                    source: this.fileType.source,
                    selection: (this.startFromNewProject ? 'new project' : 'existing project'),
                    create_project_modal: this.create_modal_text_variant
                }
            );

        },

        _create: function _create(workspace, recentFileId, templateId) {
            if (this.fileType.type === 'flow') {
                if(this.fileType.create_file_from_legacy) {
                    return this.TemplatesManager.createFlowFromLegacy(templateId, workspace._id);
                }
                return this.TemplatesManager.createFlowInstance(templateId, workspace._id, this.fileType.flow_source);
            }

            return this.WorkspaceFileService.createFile(this.fileType.type, workspace, recentFileId, templateId, this.user, false);
        },

        clearErrorMessage: function clearErrorMessage() {
            this.errorMessage = null;
        },

        _handleCreateFilePromise: function _handleCreateFilePromise(promise) {
            if (this.isSentTestFromTemplate) {
                this.shouldShowSendTestFromTemplateAnimation = true;
            } else {
                this.creatingFileInProgress = true;
            }

            var analyticsArgs = {
                origin: this.fileOriginType,
                file_type: this.fileType.type,
                source: this.fileType.source
            };

            if(!this.createFileFromWorkspace) {
                analyticsArgs.should_create_project = this.startFromNewProject;
            }

            promise.then(function(resp) {
                this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.create_workspace_file, analyticsArgs);
                if (['invoice','proposal','agreement'].indexOf(analyticsArgs.file_type)  !== -1){
                    this.AnalyticsService.reportEvent('BookableFileCreated');
                } else if (this.fileType.type === 'flow') {
                    if (this.isSentTestFromTemplate) {
                        setTimeout(function() {
                           this.FlowService.goToFlow(resp.data._id);
                           this.$modalInstance.close();
                        }.bind(this) ,2000);
                    } else {
                        this.FlowService.goToFlow(resp.data._id, undefined, undefined, this.fileType.builderQueryParams);
                    }
                }
                if (!this.isSentTestFromTemplate) {
                    this.$modalInstance.close(resp.data);
                }
            }.bind(this))
            .catch(function(error) {
                    this.shouldShowSendTestFromTemplateAnimation = false;
                    this.errorMessage = error.data.error_message;
                    this.AnalyticsService.trackError(
                    this.$scope,
                    this.AnalyticsService.analytics_events.create_workspace_file,
                    analyticsArgs
                );
            }.bind(this))
            .finally(function() {
                this.creatingFileInProgress = false;
            }.bind(this));
        },
    });
}());
