(function () {
    "use strict";

    // @ngInject
    function CompanySettingsControllerCtor($scope, $injector, AppStates, UsersManager, DeviceService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanySettingsController';
        this.isInAppBrowser = DeviceService.isInAppBrowser();
        this.user = UsersManager.getCurrUser();

    }

    Controllers.CompanySettingsController = Class(Controllers.BaseController, {
        constructor: CompanySettingsControllerCtor,
    });
}());
