(function () {
    'use strict';

    Directives.EmailIntegrationDirective = function EmailIntegrationDirective() {
        // @ngInject
        function EmailIntegrationDirectiveControllerCtor($scope, $injector, _, $document, $stateParams, $timeout, StatsigService,
                                                         UsersManager, UserService, PopupMessageService, UiPersistenceService, $window, ModalService, AnalyticsService, FlowsBetaUserTypeService) {
            this.constructor.$super.call(this, $scope, $injector);
            this.__objectType = 'EmailIntegrationDirectiveController';
            this.UsersManager = UsersManager;
            this.UserService = UserService;
            this.PopupMessageService = PopupMessageService;
            this.StatsigService = StatsigService;
            this.$document = $document;
            this.UiPersistenceService = UiPersistenceService;
            this.$window = $window;
            this.ModalService = ModalService;
            this.AnalyticsService = AnalyticsService;
            this._ = _;
            this.emailProvidersViewModel = [];
            this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
            this.selectedProviderType = null;
            this.hasGmailInegration = false;
            this.hasEmailIntergration = false;
            this.adddingGoogleIntegration = false;
            this.deleteingGoogleIntegration = false;
            this.deleteingEmailIntegration = false;
            this.providersListLoaded = false;
            this.isEmailDeliverabilityTestGateEnabled = false;
            this.integrationTypes = {
                MANDRILL: 'mandrill',
                GMAIL: 'gmail',
                NYLAS: 'nylas'
            };

            this.nylasIntegrationAllowed = this.userModel.isNylasEmailIntegrationEnabled();
            this.hightlightDropDown = false;
            if ($stateParams.googleIntegrationCreated) {
                $timeout(function timeout() {
                    this.hightlightDropDown = true;
                    var $element = angular.element('.email-integration');
                    // Ugly, but does the work :-\
                    var navHeight = angular.element('.first-level-navigation').height() + angular.element('.second-level-navigation').height() + 50;
                    $element.scrollParent().scrollTo(null, $element.offset().top - navHeight, 300);
                }.bind(this));
            }

            this.UsersManager.getEmailProviders(this.userModel)
                .then(function success(resp) {
                    this.buildViewModel(resp.data.providers_list, resp.data.selected_provider);
                }.bind(this))
                .catch(function fail(resp) {
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ACCOUNT.EMAIL_INTEGRATION.ERRORS._GETTING_AVAILABLE_EMAIL_PROVIDERS_');
                }.bind(this))
                .finally(function finallyHandler() {
                    this.providersListLoaded = true;
                }.bind(this));
        }

        var EmailIntegrationDirectiveController = Class(Controllers.BaseController, {
            constructor: EmailIntegrationDirectiveControllerCtor,
            buildViewModel: function buildViewModel(emailProvidersData, selectedProviderType) {
                this.emailProvidersViewModel.splice(0, this.emailProvidersViewModel.length);

                emailProvidersData.forEach(function iter(emailProvider) {
                    if (emailProvider.type !== this.integrationTypes.MANDRILL) {
                        emailProvider.onDelete = this.emailIntegrationDeleted.bind(this);
                    }

                    this.emailProvidersViewModel.push(emailProvider);
                }.bind(this));

                this.selectedProvider = this._.find(this.emailProvidersViewModel, {type: selectedProviderType});
                this.hasEmailIntergration = this.emailProvidersViewModel.length > 1;
                this.hasGmailInegration = this._.some(this.emailProvidersViewModel, {type: this.integrationTypes.GMAIL});

                this.UserService.getShouldShowEmailDeliverabilityTest({
                    providers_list: emailProvidersData,
                    selected_provider: selectedProviderType
                });
                

                switch (this.selectedProvider.name.toLowerCase()) {
                    case 'gmail':
                        this.activeProviderLogoImg = "https://res.cloudinary.com/honeybook/image/upload/q_100/v1622367176/app/logos/email-integration-google-icon.png";
                        break;
                    case 'yahoo':
                        this.activeProviderLogoImg = "https://res.cloudinary.com/honeybook/image/upload/q_100/v1622367176/app/logos/email-integration-yahoo-icon.png";
                        break;
                    case 'eas':
                    case 'outlook':
                        this.activeProviderLogoImg = "https://res.cloudinary.com/honeybook/image/upload/q_100/v1622367176/app/logos/email-integration-outlook-icon.png";
                        break;
                    case 'icloud':
                        this.activeProviderLogoImg = "https://res.cloudinary.com/honeybook/image/upload/q_100/v1622367176/app/logos/email-integration-icloud-icon.png";
                        break;
                    case 'aol':
                        this.activeProviderLogoImg = "https://res.cloudinary.com/honeybook/image/upload/q_100/v1622367176/app/logos/email-integration-aol-icon.png";
                        break;
                    default:
                        this.activeProviderLogoImg = null;
                }
            },

            setEmailProvider: function setEmailProvider(item, $event) {
                if (item.deleting) {
                    return;
                }
                var providerBeforeChange = this.selectedProvider;
                this.selectedProvider = item;
                this.UsersManager.setActiveEmailProvider(this.userModel, item.type)
                    .then(function success(resp) {
                        // nothing to do here if all is well.
                    }.bind(this))
                    .catch(function fail(resp) {
                        //revert
                        this.selectedProvider = providerBeforeChange;
                    }.bind(this))
                    .finally(function finallyHandler() {
                    }.bind(this));
            },

            addGoogleEmailIntegration: function addGoogleEmailIntegration($event) {
                if (!this.adddingGoogleIntegration) {
                    this.adddingGoogleIntegration = true;
                    this.UserService.connectToAGoogleAccount(null, this.integrationTypes.GMAIL);

                    //stop the dropdown from closing
                    $event.stopPropagation();
                    $event.preventDefault();
                }
            },

            addNylasEmailIntegration: function addNylasEmailIntegration($event) {
                this.ModalService.openSelectEmailProviderModal('email');
            },

            emailIntegrationDeleted: function emailIntegrationDeleted() {
                if (!this.deleteingEmailIntegration) {
                    this.analyticsArgs = {owner: this.userModel._id, provider_type: this.selectedProvider.type, provider_name: this.selectedProvider.name};

                    var promise =  this.PopupMessageService.showConfirmPromise(this.PopupMessageService.severityTypes.info, 'ACCOUNT.EMAIL_INTEGRATION._DISCONNECT_CONFIRM_')

                    promise.then(function() {
                        this.deleteingEmailIntegration = true;
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.delete_email_integration_confirmed, this.analyticsArgs);
                        this.UsersManager.deleteEmailProvider(this.userModel, this.selectedProvider.type)
                            .then(function success(resp) {
                                this.selectedProviderType = resp.data.selected_provider;
                                this.buildViewModel(resp.data.providers_list, this.selectedProviderType);
                                this.hightlightDropDown = false; //return the state to what is was before
                                this.UsersManager.forceFetchCurrUser();
                            }.bind(this))
                            .catch(function fail(resp) {
                                //TODO: add some error handling here
                            }.bind(this))
                            .finally(function finallyHandler() {
                                this.deleteingEmailIntegration = false;
                            }.bind(this));
                    }.bind(this), function() {
                        this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.delete_email_integration_canceled, this.analyticsArgs);
                    }.bind(this));
                }
            },

            shouldShowGmailRevokedBanner: function shouldShowGmailRevokedBanner() {
                var persistence = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.gmailRevoked, {});
                return this.userModel.gmail_integration_revoked_at && (!persistence[this.userModel.gmail_integration_revoked_at] || !persistence[this.userModel.gmail_integration_revoked_at].bannerDismissed);
            },

            onCloseGmailRevokedBanner: function onCloseGmailRevokedBanner($e) {
                $e.preventDefault();
                var persistence = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.gmailRevoked, {});
                persistence[this.userModel.gmail_integration_revoked_at] = persistence[this.userModel.gmail_integration_revoked_at] || {};
                persistence[this.userModel.gmail_integration_revoked_at].bannerDismissed = true;
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.gmailRevoked, persistence);
            },

            showEmailDeliverabilityTest: function showEmailDeliverabilityTest() {
                return this.UserService.showEmailDeliverabilityTest;
            }
        });

        return {
            restrict: 'E',
            templateUrl: 'angular/app/modules/core/features/settings/account/email_integration/email_integration_template.html',
            controller: EmailIntegrationDirectiveController,
            controllerAs: 'emailIntegrationVm',
            bindToController: true,
            scope: {
                userModel: '='
            }
        };
    };

}());

