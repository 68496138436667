
(function () {
    'use strict';

    class FlowsOnboardingWizardTemplatesController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope
                    ,$injector
                    ,UsersManager
                    ,ModalService
                    ,CompaniesManager
                    ,$translate
                    ,Enums
                    ,EventService
                    ,TemplatesRepositoryManager
                    ,TemplatesManager
                    ,AppStates
                    ,$state
                    ,UIUtils
                    ,$
                    ,$timeout
                    ,SelfOnboardingManager
                    ,FlowService
                    ,UiPersistenceService
                    ,AnalyticsService
                    ,DeviceService
                    ,OnboardingService
                    ,AbTestService
                    ,_
                    ,IntentService
                    ,CompanyService
                    ) {
            super($scope, $injector);
            this.__objectType = 'FlowsOnboardingWizardTemplatesController';

            this.$scope = $scope;
            this.UsersManager = UsersManager;
            this.CompaniesManager = CompaniesManager;
            this.ModalService = ModalService;
            this.$translate = $translate;
            this.Enums = Enums;
            this.EventService = EventService
            this.TemplatesRepositoryManager = TemplatesRepositoryManager;
            this.TemplatesManager = TemplatesManager;
            this.SelfOnboardingManager = SelfOnboardingManager;
            this.AppStates = AppStates;
            this.$state = $state;
            this.UIUtils = UIUtils;
            this.$ = $;
            this.$timeout = $timeout;
            this.FlowService = FlowService;
            this.UiPersistenceService = UiPersistenceService;
            this.AnalyticsService = AnalyticsService;
            this.DeviceService = DeviceService;
            this.OnboardingService = OnboardingService;
            this.AbTestService = AbTestService;
            this._ = _;
            this.MAX_ACTION_ITEMS = 3;
            this.USE_YOUR_OWN_CONTRAACT_TEMPLATE_ID = "63d802d6ec03df0334d6632f";
            this.USE_YOUR_OWN_CONTRAACT_INDEX_IN_TEMPLATES_ARRAY = 4;
            this.IntentService = IntentService;
            this.CompanyService = CompanyService;
            this.user = this.UsersManager.getCurrUser();
            this.company = this.CompaniesManager.getCurrCompany();
            $scope.$watch('$ctrl.selectedFlowTemplateId', (newVal) => {
                if (!newVal) {
                    this.isStepValid = false;
                }
            })

            this.onCloseReactModal = ()  => {
                this.$scope.$applyAsync(() => {
                    this.isShowQuickPreviewModal = false;
                });
            }

        }

        $onInit() {

            this.quickPreviewPrimaryCta = {
                text: "SELECT THIS TEMPLATE",
                action: (templateInPreview) => {
                    this.previewedFlowTemplate = templateInPreview;
                    const isSelected = this.isPreviewedTemplateSelected();
                    if (isSelected) {
                        this.$scope.$applyAsync(() => {
                            this.setSelectedFlow(null, null, null);
                        });
                    } else {
                        this.setSelectedFlow(this.previewedFlowTemplate._id, this.previewedFlowTemplate.header_image.cloudinary_public_id, this.previewedFlowTemplate.title);
                        this.updateCarouselPage();
                        this.onCloseReactModal();
                    }

                    this.AnalyticsService.trackClick(this, 'template quick preview select', {
                        action: 'click',
                        source: 'onboarding quick preview',
                        template_id: this.previewedFlowTemplate._id,
                        is_select : !isSelected
                    });
                 }
            };
            this.$scope.$on('submitOnboardingStep', this.onComplete.bind(this));
            this.$scope.$on('useYourOwnContract', this.onSelectYourOwn.bind(this));
            this.isMobile = this.DeviceService.nxSmallBreakpoint();

            this.title = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._TITLE_${this.isMobile ? 'MWEB_' : ''}`);
            this.subtitle = this.$translate.instant(`${this.stepTranslationMap[this.thisStepKey]}._SUB_TITLE_${this.isMobile ? 'MWEB_' : ''}`);

            this.isLoading = true;;
            this.previewedFlowTemplateId = null;
            this.currStepIndex = 0;
            this.currUIPersistedData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.flowsOnboarding) || {};

            this.isContractOnboarding = this.IntentService.shouldGetContractOnboarding();
            this.contractIntentOnboardingVariant = this.user.getExistingAbTestVariation(this.Enums.ABTests.contractIntentOnboardingV3);
            if (this.contractIntentOnboardingVariant === 'v_contract_templates') {
                this.isContractNewVariant = true;
                if (this.company.company_type_name === 'Photography') {
                    this.showTemplatesAndSelectOne('photo_contract');
                } else if (this.CompanyService.isNev(this.company)) {
                    this.showTemplatesAndSelectOne('nevs_contract');
                } else {
                    this.showTemplatesAndSelectOne('event_contract');
                }
            }  else if (this.contractIntentOnboardingVariant === 'control_original_templates' || this.isContractOnboarding) {
                this.isContractNewVariant = false;
                this.showTemplatesAndSelectOne('contract_intent');
            } else {
                this.showTemplatesAndSelectOne(this.currUIPersistedData[this.Enums.flowsOnboardingWizardSteps.services]);
            }

            // flow width on desktop
            this.listWidth = 0;

            const carouselItem = this.$('.flows_onboarding_wizard__templates__carousel__item');
            this.carouselItemWidth = carouselItem.outerWidth() + parseInt(carouselItem.css('margin-left'), 10);

            this.pageWidth = 0;
            this.numberOfPages = 0;
            this.flowsLength = 0;
            this.itemsPerPage = 0;


            if (this.isMobile) {
                this.initSwipeDetection();
            }

            this.isShowQuickPreviewModal = false;

        }

        showTemplatesAndSelectOne(serviceKey) {
            if (!serviceKey) {
                return;
            }

            this.showFlowTemplatesByServiceKey(serviceKey).then(() => {
                const selectedFlowTemplateId = this.currUIPersistedData[this.thisStepKey] && this.currUIPersistedData[this.thisStepKey].selectedFlowTemplateId;
                const selectedFlowTemplateImageUrl = this.currUIPersistedData[this.thisStepKey] && this.currUIPersistedData[this.thisStepKey].selectedFlowTemplateImageUrl;
                const selectedFlowTemplateTitle = this.currUIPersistedData[this.thisStepKey] && this.currUIPersistedData[this.thisStepKey].selectedFlowTemplateTitle;
                if (selectedFlowTemplateId) {
                    this.setSelectedFlow(selectedFlowTemplateId, selectedFlowTemplateImageUrl, selectedFlowTemplateTitle);
                    this.updateCarouselPage();
                } else {
                    if (this.flowTemplateList.length > 0) {
                        this.currStepIndex = 0;
                        const firstTemplate = this.flowTemplateList[0];
                        this.setSelectedFlow(firstTemplate._id, firstTemplate.header_image.cloudinary_public_id, firstTemplate.title);
                    }
                }

            });
        }


        isPreviewedTemplateSelected () {
            return this.previewedFlowTemplate && (this.previewedFlowTemplate._id === this.selectedFlowTemplateId);
        }

        initSwipeDetection() {
            var carouselElement = document.getElementById('js-flows-templates-carousel');
            var hammertime = new Hammer(carouselElement);

            hammertime.get('swipe').set({
                direction: Hammer.DIRECTION_HORIZONTAL,
                threshold: 1,
                velocity:0.3
            });

            hammertime.on("swipeleft swiperight", (event) => {
                switch (event.type) {
                    case 'swiperight':
                        this.$scope.$applyAsync(this.moveBack.bind(this));
                        break;
                    case 'swipeleft':
                        this.$scope.$applyAsync(this.moveNext.bind(this));
                        break;
                    default:
                        break;
                }
            });
        }


        showFlowTemplatesByServiceKey(serviceKey) {
            const NUMBER_OF_FIXED_ITEMS = 1;
            return this.SelfOnboardingManager.getOnboardingFlowTemplates(this.user, serviceKey).then(res => {
                const templates = res && res.data || [];
                templates.forEach(({ template: { flow_content } }) => {
                    flow_content.action_block_types = [...new Set(flow_content.action_block_types)];
                });

                const [prioritizedTemplate] = templates.splice(
                    templates.findIndex(template => template.system_attributes.includes(`${serviceKey}_first`)),
                    1
                );

                let blankTemplate, thirdBlankTemplate, lastBlankTemplate;
                if (this.isContractOnboarding) {
                    [blankTemplate] = templates.splice(
                        templates.findIndex(template => template.system_attributes.includes(`${serviceKey}_blank`)),
                        1
                    );
                    blankTemplate.is_blank = true;
                    blankTemplate.title = "Use your own contract";

                    if (this.isContractNewVariant) {
                        lastBlankTemplate = blankTemplate;
                    } else {
                        thirdBlankTemplate = blankTemplate;
                    }
                }

                templates.sort((a, b) => a.import_count < b.import_count ? 1 : -1);

                const serviceKeyToActionBlock = {
                    invoices: 'charge_container_block',
                    other: 'charge_container_block',
                    contracts: 'contract_block',
                    contract_intent: 'contract_block',
                    forms: 'contract_block',
                    mood_boards: 'service_selection_block',
                    services: 'service_selection_block',
                };

                let index = templates.findIndex(
                    template => template.template.flow_content.action_block_types.includes(serviceKeyToActionBlock[serviceKey])
                );
                index = Math.max(0, index)
                const [mostImportedRelevantTemplate] = templates.splice(index, 1);

                const firstTemplates = [prioritizedTemplate, mostImportedRelevantTemplate, thirdBlankTemplate].filter(Boolean);
                
                this.flowTemplateList = [...firstTemplates, ...this._.shuffle(templates), lastBlankTemplate].filter(Boolean);
                this.flowsLength = this.flowTemplateList.length + NUMBER_OF_FIXED_ITEMS;
                this.listWidth = this.carouselItemWidth * (this.flowsLength);

                this.AnalyticsService.trackPageView(
                    this,
                    this.AnalyticsService.analytics_events.onboarding_templates,
                    { 
                        count: this.flowTemplateList && this.flowTemplateList.length,
                        onboarding_abtest_name: this.onboardingAbTest && this.onboardingAbTest.name,
                        onboarding_abtest_variant: this.onboardingAbTest && this.onboardingAbTest.variant
                    }
                );

                this.$timeout(() => {
                    
                    this.pageWidth = this.$('#js-flows-templates-carousel').outerWidth();
                    this.itemsPerPage = Math.floor(this.pageWidth / this.carouselItemWidth) || 1;
                    this.numberOfPages = Math.ceil(this.flowsLength / this.itemsPerPage);
                    this.isLoading = false;

                    this.$timeout( () => {
                        this.isStepValid = true;
                    }, 1000);
                }, 0)

            })
        }

        isDisableNextButton() {
            return this.currStepIndex === this.numberOfPages - 1;
        }

        getActionLabel(action) {
            const blockTypes = {
                contract_block: 'Contract',

                // old invoices before recurring might return this
                invoice_container_block: 'Invoice',
                payment_schedule_block: 'Invoice',
                recurring_schedule_block: 'Recurring Invoice',
                charge_container_block: 'Pay',
                service_selection_block: 'Services',
                schedule_container_block: 'Scheduler'
            };

            return blockTypes[action];
        }

        getActionsLabels(actions){
            return actions.map(this.getActionLabel).join(', ')
        }

        $onChanges(changes) {
            if(changes.selectedServiceKey && changes.selectedServiceKey.currentValue) {
                this.showTemplatesAndSelectOne(changes.selectedServiceKey.currentValue);
            }
        }

        gotoFlowsTemplateGallery() {
            this.AnalyticsService.trackClick(this, 'visit template gallery');
            this.$state.go(this.AppStates.root_core_navigation_flows_template_gallery);
        }

        showQuickPreview(event, flowTemplate) {
            this.AnalyticsService.trackClick(this, 'quick preview', {name: flowTemplate.title, template_id: flowTemplate._id });
            this.previewedFlowTemplate = flowTemplate;
            this.isShowQuickPreviewModal = true;

            event.stopPropagation();
        }

        selectFlowTemplate(flowTemplate, index, analyticArg = {}) {
            const analyticsArgs =  Object.assign(analyticArg, {
                name: flowTemplate.title,
                template_id: flowTemplate._id,
                position: index,
                count: this.flowTemplateList.length
            });

            this.AnalyticsService.trackClick(
                this,
                this.AnalyticsService.analytics_events.toggle_setup_guide_template, 
                analyticsArgs
            );
            
            this.setSelectedFlow(flowTemplate._id, flowTemplate.header_image.cloudinary_public_id, flowTemplate.title, flowTemplate.is_blank);
        }

        setSelectedFlow(flowTemplateId, flowTemplateUrl, flowTemplateTitle, isBlank) {
            this.selectedFlowTemplateId = flowTemplateId;
            this.selectedFlowTemplateImageUrl = flowTemplateUrl;
            this.selectedFlowTemplateTitle = flowTemplateTitle;
            this.isStepValid = !!flowTemplateId;
            this.isBlankTemplate = isBlank;
        }

        updateCarouselPage() {      
            // when selecting template from the quick preview, we want to scroll the carousel to the related page
            const selectedTemplateIndex = this.flowTemplateList.findIndex(({ _id }) => _id === this.selectedFlowTemplateId);
            if (selectedTemplateIndex > -1) {
                this.currStepIndex = Math.floor(selectedTemplateIndex / (this.itemsPerPage || 1));
            } else {
                this.currStepIndex = 0;
            }
        }        

        isFlowTemplateSelected(flowTemplateId) {
            return flowTemplateId === this.selectedFlowTemplateId;
        }

        moveBack() {
            if (this.currStepIndex > 0) {
                this.AnalyticsService.trackClick(this, 'carousel slider', { type: 'left' }) ;
                this.currStepIndex--;
            }
        }

        moveNext() {
            if (this.currStepIndex < this.numberOfPages - 1) {
                this.AnalyticsService.trackClick(this, 'carousel slider', { type: 'right' }) ;
                this.currStepIndex++;
            }
        }

        getCarouselLeft() {
            const transformValue = `translateX(${-1 * (this.itemsPerPage * this.carouselItemWidth) * this.currStepIndex}px)`
            return transformValue;
        }

        onSubmitTemplateStep() {
            this.TemplatesRepositoryManager.importTemplates(this.company, {id: [this.selectedFlowTemplateId]})
                .then((data) => {
                    var projectData = this.EventService.createDefaultProject(this.company, this.user.first_name + '’s test project').dataOnly();
                    projectData.event_source = 'onboarding';
                    this.SelfOnboardingManager.updateOnboardingFlowTemplate(this.user, data.data.imported_templates[0]._id)
                        .then(() => {
                            var promise = this.TemplatesManager.createFlowInstanceWithProject(data.data.imported_templates[0]._id, projectData);
                            promise.then((resp) => {

                                this.flowInstanceId = resp.data._id;
                                const persistedData = { 
                                    flowInstanceId: this.flowInstanceId,
                                    selectedFlowTemplateId: this.selectedFlowTemplateId,
                                    selectedFlowTemplateImageUrl: this.selectedFlowTemplateImageUrl,
                                    selectedFlowTemplateTitle: this.selectedFlowTemplateTitle,
                                    isBlankTemplate: this.isBlankTemplate 
                                };
                                this.currUIPersistedData = Object.assign({}, this.currUIPersistedData, {[this.thisStepKey]: persistedData});
                                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.flowsOnboarding, this.currUIPersistedData);
                            });
                        });
                });
        }

        onComplete(event, currStep) {
            if (currStep.key === this.thisStepKey) {
                this.onSubmitTemplateStep();
            }
        }

        onSelectYourOwn(){
            const useYourOwnContractTemplate = this.flowTemplateList.find((template) => template._id === this.USE_YOUR_OWN_CONTRAACT_TEMPLATE_ID);
            this.selectFlowTemplate(useYourOwnContractTemplate,
                this.USE_YOUR_OWN_CONTRAACT_INDEX_IN_TEMPLATES_ARRAY,
                { choose_from_link: true}
            );
        }
    }

    Components.FlowsOnboardingWizardTemplates = {
        controller: FlowsOnboardingWizardTemplatesController,
        bindings: {
            currentStep: '<',
            selectedServiceKey: '<',
            selectedFlowTemplateId: '=',
            selectedFlowTemplateImageUrl: '=',
            selectedFlowTemplateTitle: '=',
            isBlankTemplate: '=',
            flowInstanceId: '=',
            thisStepKey: '@',
            isStepValid: '=',
            stepTranslationMap: '<',
            onboardingType: '<',
            onboardingAbTest: '<',
        },
        templateUrl: 'angular/app/modules/core/features/onboarding/flows_onboarding/flows_onboarding_wizard/steps/templates/flows_onboarding_wizard_templates.html',

    };
}());
