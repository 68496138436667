(function () {
    'use strict';

    Services.GlobalBannerService = class GlobalBannerService {
        // @ngInject
        constructor($rootScope, FeaturesService, UsersManager, StatsigService, Enums) {
            this.$rootScope = $rootScope;
            this.FeaturesService = FeaturesService;
            this.UsersManager = UsersManager;
            this.pathnamesToShowGlobalBanner = ['/app/home' , '/app/pipeline' , '/app/contacts', '/app/settings/company/subscription'];
            this.StatsigService = StatsigService;
            this.Enums = Enums;
            this.isGlobalBannerVisible = false;
            this.globalBannerExpVariant = 'control';
            
            this.setIsGlobalBannerVisible = (isVisible) => {
                this.isGlobalBannerVisible = isVisible;
                setTimeout(() => {
                    if (isVisible && !(window.scrollY > 0)) {
                        window.scrollTo({ top: 0 })
                    }
                }, 5);
            };
            
            if (this.UsersManager.getCurrUser().isVendor()){
                this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.globalBannerExp, 'variant', 'control').then(function (variant) {
                    this.globalBannerExpVariant = variant;
                    this.checkIfShouldShowBanner();
                }.bind(this));
            }

            const unsubscribe = this.$rootScope.$on('$stateChangeSuccess', () => {
                setTimeout(() => {
                    this.checkIfShouldShowBanner();
                }, 1);
            });

            this.$rootScope.$on('$destroy', () => {
                unsubscribe();
            });
        } 

        checkIfShouldShowBanner() {
            if (this.globalBannerExpVariant === 'all_pages' || this.pathnamesToShowGlobalBanner.includes(window.location.pathname)) { 
                this.shouldShowGlobalBanner = true;
            } else {
                this.shouldShowGlobalBanner = false;
                this.isGlobalBannerVisible = false;
            }
        }
    };
})();

