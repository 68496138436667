Directives.FacebookLoginDirective = function FacebookLoginDirective() {

    // @ngInject
    function FacebookLoginDirectiveControllerFunc($scope, $injector, ServiceLazyLoaderService, UsersManager, AnalyticsService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'FacebookLoginDirectiveController';

        this.FacebookService = ServiceLazyLoaderService.get('FacebookService');
        this.UsersManager = UsersManager;
        this.AnalyticsService = AnalyticsService;
    }

    var FacebookLoginDirectiveController = Class(Controllers.BaseController, {
        constructor: FacebookLoginDirectiveControllerFunc,

        login: function login() {
            var self = this;
            self.loggingIn = true;
            var facebookParams = self.FacebookService.loginToFacebook('email').then(
                function success(facebookParams) {
                    self.UsersManager.login_with_facebook(self.loginParams.user, facebookParams.auth_token).then(

                        function success(resp){
                            self.loginParams.loginSuccess(resp, true);
                            self.AnalyticsService.trackSuccess(self, self.AnalyticsService.analytics_events.login_with_facebook);
                        },

                        function error(resp){
                            self.loginParams.loginError(resp);
                            self.AnalyticsService.trackError(self, self.AnalyticsService.analytics_events.login_with_facebook, resp);
                            self.loggingIn = false;
                        }
                    );
                },
                function fail(resp) {
                    self.loginParams.loginError(resp);
                    self.AnalyticsService.trackError(self, self.AnalyticsService.analytics_events.login_with_facebook, resp);
                    self.loggingIn = false;
                }
            );
        }
    });

    return {
        templateUrl: 'angular/app/modules/common/ui_components/facebook_login/facebook_login_directive_template.html',
        controller: FacebookLoginDirectiveController,
        controllerAs: 'facebookVm',
        scope: {
            loginParams: "=loginParams",
            source: '@loginSource',
            permissions: "@requiredPermissions"
        },
        bindToController: true
    };
};