Directives.AddTeamMemberDirective = function AddTeamMemberDirective() {

    // @ngInject
    function AddTeamMemberDirectiveControllerFunc($scope, $injector, WorkspacesManager, PopupMessageService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'AddTeamMemberDirectiveControllerFunc';

        this.WorkspacesManager = WorkspacesManager;
        this.PopupMessageService = PopupMessageService;
        this.showAllMembersDropDown = false;

        this.workspace.on('success', this.loadModels, this);
    }

    var AddTeamMemberDirectiveController = Class(Controllers.BaseController, {
        constructor: AddTeamMemberDirectiveControllerFunc,

        loadModels: function loadModels() {
            this.workspaceMembersHash = {};
            this.teamMemberMappings = {};
            this.teamMembersCollection = [];
            this.shouldShowAdditionalTeamMembers = false;

            // map workspace members by id
            this.workspace.members.forEach(function processWorkspaceMember(member) {
                this.workspaceMembersHash[member._id] = member;
            }.bind(this));

            // add company owner
            if (this.currentUserId !== this.company.owner._id) {
                var ownerIsInWS = !!this.workspaceMembersHash[this.company.owner._id];
                this.teamMembersCollection.push({
                    member: this.company.owner,
                    isAdded: ownerIsInWS
                });
            }

            this.company.team_members.forEach(function processTeamMember(teamMember) {

                // dont add to the list if current user
                if (teamMember._id === this.currentUserId) {
                    return;
                }

                var isInWS = !!this.workspaceMembersHash[teamMember._id];
                this.teamMembersCollection.push({
                    member: teamMember,
                    isAdded: isInWS
                });

            }.bind(this));

            if (this.teamMembersCollection.length > 11) {
                this.shouldShowAdditionalTeamMembers = true;
                this.additionalTeamMembers = this.teamMembersCollection.slice(11);
            }

        },

        toggleTeamMember: function toggleTeamMember(memberMapping) {
            memberMapping.isLoading = true;

            var addedTeamMembers = [];
            var removedTeamMembers = [];
            if (memberMapping.isAdded) {
                removedTeamMembers.push(memberMapping.member._id);
            } else {
                addedTeamMembers.push(memberMapping.member._id);
            }

            this.WorkspacesManager
                .updateTeamMembersInWorkspace(this.workspace, addedTeamMembers, removedTeamMembers)
                .then(function success() {
                    memberMapping.isAdded = !memberMapping.isAdded;
                    this.loadModels();
                }.bind(this))
                .catch(function error(res) {
                    var message;
                    if (res && res.data && res.data.error_type) {
                        if (res.data.error_type === 'HBUserError') {
                            message = res.data.error_message;
                        } else {
                            this.$log.error(res.data.error_message);
                            message = 'ERRORS.SERVER_API._UNKNOWN_';
                        }
                    } else {
                        message = 'ERRORS.SERVER_API._UNEXPECTED_';
                    }

                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, message);
                }.bind(this))
                .finally(function saveMemberDone() {
                    memberMapping.isLoading = false;
                }.bind(this));
        },

        viewAllMembers: function viewAllMembers() {
            this.showAllMembersDropDown = !this.showAllMembersDropDown;
        },

        closeViewAllMembers: function closeViewAllMembers() {
            this.showAllMembersDropDown = false;
        }


    });

    return {
        scope: {
            workspace: '=',
            company: '=',
            currentUserId: '<'
        },
        templateUrl: 'angular/app/modules/core/features/add_user/add_team_member_to_project/add_team_member_to_project.html',
        controller: AddTeamMemberDirectiveController,
        controllerAs: 'addTeamMemberVm',
        bindToController: true
    };
};
