(function () {
    "use strict";

    // @ngInject
    function InviteControllerCtor($scope, $injector, $timeout, $stateParams, $q, Gon, _, $, $translate) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'InviteControllerCtor';
        this.$translate = $translate;
    }

    Controllers.InviteController = Class(Controllers.BaseController, {
            constructor: InviteControllerCtor
        }
    );
}());
