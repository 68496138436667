Directives.ProjectToolsDirective = function ProjectToolsDirective() {

    // @ngInject
    function ProjectToolsDirectiveControllerFunc($stateParams, $scope, $injector, $transclude, WorkspaceService,
                                                 UsersManager, FinanceAppService, CompaniesManager, StatsigService, Enums, FeaturesService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ProjectToolsDirectiveController';
        this.$transclude = $transclude;
        this.WorkspaceService = WorkspaceService;
        this.user = UsersManager.getCurrUser();
        this.company = CompaniesManager.getCurrCompany();
        this.shouldShowPicTimeTool = this.company.has_pictime_integration || this.company.isPhotographer();
        this.StatsigService = StatsigService;
        this.isNewNotesExp = FeaturesService.isNewNotesByUser(this.user);
        this.Enums = Enums;
        
        this.tagsEnabled = false;
        StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.tags).then(function (res) {
            this.tagsEnabled = res;
        }.bind(this));
    }

    var ProjectToolsDirectiveController = Class(Controllers.BaseController, {
        constructor: ProjectToolsDirectiveControllerFunc,

        isSlotFilled: function isSlotFilled(name) {
            return this.$transclude.isSlotFilled(name);
        }
    });

    return {
        scope: {
            showMoreTools: '=',
            eventName: '='
        },
        transclude: {
            'header': '?toolsHeader',
            'pipeline': "?pipelineComponent",
            'tags': '?tagsComponent',
            'team': '?addTeamMembersComponent',
            'tasks': '?tasksComponent',
            'showmore' : '?moreOptions',
            'automation': '?automationComponent',
            'workflow': '?workflowComponent',
            'notes': '?notes',
            'projects': '?relatedProjectsComponent',
            'leadSource': '?leadSourceComponent',
            'timeTracker': '?timeTrackerComponent',
            'scheduledSessions': '?scheduledSessionsComponent'
        },
        templateUrl: 'angular/app/modules/core/features/workspace/project_tools/project_tools_directive_template.html',
        controller: ProjectToolsDirectiveController,
        controllerAs: 'projectToolsVm',
        bindToController: true
    };
};
