(function () {
    "use strict";

    // @ngInject
    function InviteNavMobileControllerCtor($scope, UsersManager, $injector, AuthService, AppStates, moment,
                                           ReferralService, AnalyticsService, AbTestService, Enums, CompaniesManager) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'InviteNavMobileController';

        this.moment = moment;
        this.AuthService = AuthService;
        this.AppStates = AppStates;
        this.UsersManager = UsersManager;
        this.ReferralService = ReferralService;
        this.AnalyticsService = AnalyticsService;
        this.AbTestService = AbTestService;
        this.Enums = Enums;
        this.CompaniesManager = CompaniesManager;

        this.user = this.UsersManager.getCurrUser();
        this.company = this.CompaniesManager.getCurrCompany();

        AnalyticsService.trackPageView(this, 'referrals mobile navigation');

        this.navigationMenuItems = [
            {linkTitleKey: 'My Account', stateToGoto: AppStates.root_core_navigation_settings_account, icon: 'icon-hb-account'}
        ];

        // add routes for vendors only
        if(this.user.isVendor()) {
            this.navigationMenuItems.unshift({
                linkTitleKey: 'Public Profile', stateToGoto: AppStates.root_network_my_profile, icon: 'avatar-directive'
            });

            this.navigationMenuItems.push({
                linkTitleKey: 'Company InviteNav', stateToGoto: AppStates.root_core_navigation_settings_company_mobile, icon: 'icon-hb-settings-c'
            });
        }

        ////////////////////////////////////////////////////////
        // TODO (Alon): Remove from here
        // Need to use existing 'hb-refer-friend' directive,
        // but we need to rafactor it so it will fit here as
        // well. For now, extracted the logic from there
        ////////////////////////////////////////////////////////
        this.showReferAFriend = false;
        if (this.user.companyHasSubscription()) {
            this.ReferralService.getCampaignDetails(this.user).then(function success(campaignDetails) {
                if (campaignDetails && campaignDetails.campaign_uid) {
                    this.showReferAFriend = true;
                }
            }.bind(this));
        }
        ////////////////////////////////////////////////////////
        // END
        ////////////////////////////////////////////////////////

    }


    Controllers.InviteNavMobileController = Class(Controllers.BaseController, {

        constructor: InviteNavMobileControllerCtor
    });
}());
