(function () {
    'use strict';

    // @ngInject
    function RegexServiceCtor() {
        this.PATTERNS = {
            VALID_URL: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
            VALID_WEBSITE_URL: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
            URL_OPTIONAL_PROTOCOL_REGEX: /^((https?)?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|(?!www\.)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
            ONLY_NUMBERS: /^\d+$/,
            NO_DIGITS: /\D/g,
            VALID_CVV: /^[0-9]{3,4}$/,
            VALID_EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            VALID_PHONE: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
            VALID_SSN: /([0-9,\-,X,*]{3}){3}/,
            VALID_SSI: /^(\d{3}-\d{3}-\d{3})|(\d{9})$/,
            VALID_ZIP: /^\d{5}$|^\d{5}-\d{4}$/,
            VALID_CANADAIAN_POSTAL_CODE: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1}[\s-]*\d{1}[A-Z]{1}\d{1}$/,
            VALID_TAX_ID: /^([0-9]-?){9}$/,
            VALID_TAX_CA_ID: /^([0-9]-?){7,10}$/
        };
    }

    Services.RegexService = Class({
        constructor: RegexServiceCtor,

        isUrlValid: function isUrlValid(potentialUrl) {
            return this.PATTERNS.VALID_URL.test(potentialUrl);
        },

        isWebsiteUrlValid: function isWebsiteUrlValid(potentialUrl) {
            return this.PATTERNS.VALID_WEBSITE_URL.test(potentialUrl);
        },

        isCompanyWebsiteUrlValid: function isCompanyWebsiteUrlValid(potentialUrl) {
            return this.PATTERNS.URL_OPTIONAL_PROTOCOL_REGEX.test(potentialUrl);
        },

        isEmailValid: function isEmailValid(textToValidate) {
            return this.PATTERNS.VALID_EMAIL.test(textToValidate);
        },

        isPhoneValid: function isPhoneValid(textToValidate) {
            return this.PATTERNS.VALID_PHONE.test(textToValidate);
        },

        isPhoneLongEnoughValid: function isPhoneValid(textToValidate) {
            var defaultMin = 10;
            return textToValidate.replace(this.PATTERNS.NO_DIGITS, '').length >= defaultMin;
        },


    });
}());
