(function () {
    "use strict";

    // @ngInject
    function EventServiceCtor(moment, UsersManager, EventsManager, AppStates, ModalService, TimezoneService, _, CompanyService, FeaturesService,
                              Enums, jstz, AnalyticsService, WorkspaceService, OnboardingService, RepositoryService, AbTestService, PubSubService,
                              $state, $translate, ModelFactory, ClientPortalService, Constants, PopupMessageService, UiPersistenceService,
                              ReactModalService, CompaniesManager, FeatureRestrictionService) {
        this.moment = moment;
        this.UsersManager = UsersManager;
        this.AnalyticsService = AnalyticsService;
        this.EventsManager = EventsManager;
        this.WorkspaceService = WorkspaceService;
        this.UiPersistenceService = UiPersistenceService;
        this.AppStates = AppStates;
        this.ModalService = ModalService;
        this.Enums = Enums;
        this.jstz = jstz;
        this.$state = $state;
        this.OnboardingService = OnboardingService;
        this.TimezoneService = TimezoneService;
        this.RepositoryService = RepositoryService;
        this.AbTestService = AbTestService;
        this.CompanyService = CompanyService;
        this.CompaniesManager = CompaniesManager;
        this.FeaturesService = FeaturesService;
        this._ = _;
        this.$translate = $translate;
        this.ModelFactory = ModelFactory;
        this.ClientPortalService = ClientPortalService;
        this.Constants = Constants;
        this.PopupMessageService = PopupMessageService;
        this.ReactModalService = ReactModalService;
        this.FeatureRestrictionService = FeatureRestrictionService;
        PubSubService.ventMyBitchUp(this);
        this.user = this.UsersManager.getCurrUser();

        this.paymentsValueSum = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.totalTransactionAmount, {}).total_transactions;
    }

    Services.EventService = Class(function () {

        return {

            constructor: EventServiceCtor,

            eventLeadSourceEnum: [
                {key: 'vendor_referral', label: 'PROJECT.LEADSOURCE._VENDOR_REFERRAL_', open_text: true},
                {key: 'client_referral', label: 'PROJECT.LEADSOURCE._CLIENT_REFERRAL_', open_text: true},
                {key: 'personal_website', label: 'PROJECT.LEADSOURCE._PERSONAL_WEBSITE_', open_text: false},
                {key: 'google', label: 'PROJECT.LEADSOURCE._GOOGLE_', open_text: false},
                {key: 'facebook', label: 'PROJECT.LEADSOURCE._FACEBOOK_', open_text: false},
                {key: 'instagram', label: 'PROJECT.LEADSOURCE._INSTAGRAM_', open_text: false},
                {key: 'wedding_spot', label: 'PROJECT.LEADSOURCE._WEDDING_SPOT_', open_text: false},
                {key: 'the_knot', label: 'PROJECT.LEADSOURCE._THE_KNOT_', open_text: false},
                {key: 'wedding_wire', label: 'PROJECT.LEADSOURCE._WEDDING_WIRE_', open_text: false},
                {key: 'yelp', label: 'PROJECT.LEADSOURCE._YELP_', open_text: false},
                {key: 'other', label: 'PROJECT.LEADSOURCE._OTHER_', open_text: true},
                {key: 'unknown', label: "PROJECT.LEADSOURCE._UNKNOWN_", open_text: false}
            ],

            nonEventLeadSourceEnum: [
                {key: 'client_referral', label: 'PROJECT.LEADSOURCE._CLIENT_REFERRAL_', open_text: true},
                {key: 'industry_referral', label: 'PROJECT.LEADSOURCE._INDUSTRY_REFERRAL_', open_text: true},
                {key: 'personal_website', label: 'PROJECT.LEADSOURCE._PERSONAL_WEBSITE_', open_text: false},
                {key: 'instagram', label: 'PROJECT.LEADSOURCE._INSTAGRAM_', open_text: false},
                {key: 'facebook', label: 'PROJECT.LEADSOURCE._FACEBOOK_', open_text: false},
                {key: 'google', label: 'PROJECT.LEADSOURCE._GOOGLE_', open_text: false},
                {key: 'other', label: 'PROJECT.LEADSOURCE._OTHER_', open_text: true},
                {key: 'unknown', label: "PROJECT.LEADSOURCE._UNKNOWN_", open_text: false}
            ],

            getProjectsForClient: function getProjectsForClient() {
                var filterCompanyId = null;
                if (this.ClientPortalService.isInClientPortal()) {
                    filterCompanyId = this.ClientPortalService.getConfiguration().company_id;
                } else {
                    filterCompanyId = this.RepositoryService.storageGet(this.Constants.storage.SESSION_COMPANY_ID);
                }

                return this.EventsManager.getEventsSummary(filterCompanyId);
            },

            currUserHasMultipleProjects: function currUserHasMultipleProjects() {
                return this.EventsManager.getEventsMasterCollection().length > 1;
            },

            clearLastEvent: function clearLastEvent(user) {
                delete user.last_event_visited;
            },

            getProjectType: function getProjectType(company, projectTypeId) {
                return this._.find(company.project_types, function find(projectType) {
                    if (projectType._id === projectTypeId) {
                        return projectType;
                    }
                });
            },

            getProjectTypeByString: function getProjectTypeByString(company, projectTypeString) {
                return this._.find(company.project_types, function find(projectType) {
                    if (projectType.label === projectTypeString) {
                        return projectType;
                    }
                });
            },

            getSelectedEventLeadSource: function getSelectedEventLeadSource(event, leadSources) {

                var eventLeadSource = event && event.lead_source;
                var defaultLeadSource;
                if (eventLeadSource) {
                    defaultLeadSource = this._.find(leadSources, {label: "Other"});
                } else {
                    defaultLeadSource = this._.find(leadSources, {label: "Unknown"});
                }

                if (eventLeadSource) {
                    var leadSource = this._.find(leadSources, {label: event.lead_source});
                    return leadSource || defaultLeadSource;
                }

                return defaultLeadSource;
            },

            getTranslatedLeadSourcesEnum: function getTranslatedLeadSourcesEnum(leadSourceEnumList) {
                return leadSourceEnumList.map(function(leadSource) {
                    leadSource["label"] = this.$translate.instant(leadSource["label"]);
                    return leadSource;
                }.bind(this));
            },

            generateAnalyticsProps: function generateAnalyticsProps(event) {
                if (event) {

                    var creator, company, creatorName, creatorEmail, creatorId, creatorUserType, creatorHBUserType,
                        creatorCompanyId, creatorCompanyRole, creatorCompanyName, creatorCompanyType,
                        eventName, vendorsCount = 0, clientsCount = 0, membersCount = 0;
                    if (event.creator) {
                        creator = event.creator;
                        creatorName = creator.full_name;
                        creatorEmail = creator.email;
                        creatorId = creator._id;
                        creatorUserType = creator.system_user_type;
                        creatorCompanyRole = creator.company_role;
                        creatorHBUserType = Array.isArray(creator.hb_user_type) ? creator.hb_user_type[0] : '';

                        if (creator.company) {
                            company = creator.company;
                            creatorCompanyId = company._id;
                            creatorCompanyName = company.company_name;
                            creatorCompanyType = company.company_type_name;
                        }
                    }

                    var workspacesCount = event.workspaces ? event.workspaces.length : 0;

                    return {
                        event_type: event.event_type,
                        event_date: event.event_date,
                        event_workspaces_count: workspacesCount,
                        event_name: event.event_name,
                        event_budget: event.event_budget,
                        event_creator_id: creatorId,
                        event_creator_name: creatorName,
                        event_creator_email: creatorEmail,
                        event_creator_user_type: creatorUserType,
                        event_creator_hb_user_type: creatorHBUserType,
                        event_creator_company_id: creatorCompanyId,
                        event_creator_company_name: creatorCompanyName,
                        event_creator_company_type: creatorCompanyType,
                        event_creator_company_role: creatorCompanyRole
                    };
                }
            },

            getDefaultEvent: function getDefaultEvent() {
                var currUser = this.UsersManager.getCurrUser();
                var defaultEventId = null;
                var lastVisitedEventId = currUser.last_event_visited;
                var defaultEvent = null;

                if (lastVisitedEventId) {
                    defaultEvent = this.EventsManager.getEventFromCache(lastVisitedEventId);

                    if (!defaultEvent) {
                        this.clearLastEvent(currUser);
                    }
                }

                if (!defaultEvent) {
                    defaultEvent = this.EventsManager.getLatestEventFromCache();
                }

                if (defaultEvent) {
                    defaultEventId = defaultEvent._id;
                }

                return defaultEventId;
            },

            goToState: function goToState(to, params, options) {
                this.$state.go(to, params, options);
            },

            goToEvents: function goToEvents(eventsToShow) {
                var eventToGoTo = this.getDefaultEvent();

                // if on the same event, do nothing
                if (eventToGoTo && this.loadedEventId === eventToGoTo) {
                    return;
                }

                var eventDefaultState;

                // if we have some default event, show him
                if (eventToGoTo) {
                    eventDefaultState = this.getEventDefaultState(eventToGoTo);
                    this.goToState(eventDefaultState.name, eventDefaultState.params);
                } else if (eventsToShow && eventsToShow.length > 0) {

                    // in case we don't have a default event,
                    // check in Activity events
                    eventDefaultState = this.getEventDefaultState(this.eventsToShow[0]._id);
                    this.goToState(eventDefaultState.name, eventDefaultState.params);

                } else {
                    this.goToState(this.AppStates.root_core_navigation_emptyEvent, null, { location: false });
                }
            },

            getEventDefaultState: function getEventDefaultState(eventIdOrEvent, isEventLoaded) {
                var resultState;

                var event, eventId;

                if (isEventLoaded) {
                    event = eventIdOrEvent;
                    eventId = event._id;
                } else {
                    eventId = eventIdOrEvent;
                    event = this.EventsManager.getEvent(eventId, false);
                }

                var latestWs = null;

                var defaultTab = this.user.isVendor() ? this.Enums.workspaceTabTypes.files : this.Enums.workspaceTabTypes.feed;

                var persistence =  this.RepositoryService.persistentGet('event:' + eventId,
                    {tabToOpen: defaultTab, latestWsId: null});

                if (persistence.latestWsId) {
                    if (event && event.workspaces && event.workspaces.length > 0) {
                        latestWs = event.workspaces.find(function checkWS(ws) {
                            return ws._id === persistence.latestWsId;
                        });
                        if(!latestWs) {
                            latestWs = event.workspaces[event.workspaces.length - 1];
                        }
                    }
                } else if(event && event.workspaces && event.workspaces.length > 0) {
                    latestWs = event.workspaces[event.workspaces.length - 1];
                }

                var resultStateDefault = {
                    name: this.AppStates.root_core_navigation_event_overview,
                    params: {event_id: eventId}
                };


                if (latestWs) {
                    if(persistence.tabToOpen === this.Enums.workspaceTabTypes.feed){
                        resultState = {
                            name: this.AppStates.root_core_navigation_event_workspace_feed,
                            params: {event_id: eventId, workspace_id: latestWs._id}
                        };
                    } else if (persistence.tabToOpen === this.Enums.workspaceTabTypes.files) {
                        resultState = {
                            name: this.AppStates.root_core_navigation_event_workspace_files,
                            params: {event_id: eventId, workspace_id: latestWs._id}
                        };
                    } else if (persistence.tabToOpen === this.Enums.workspaceTabTypes.payments) {
                        resultState = {
                            name: this.AppStates.root_core_navigation_event_workspace_payments,
                            params: {event_id: eventId, workspace_id: latestWs._id}
                        };
                    } else {
                        //it's the feed and also the default
                        resultState = resultStateDefault;
                    }
                } else {
                    resultState = resultStateDefault;
                }

                return resultState;
            },

            registerEventLoadedCallback: function registerEventLoadedCallback(callback) {
                this.eventLoadedCallback = callback;
            },

            eventLoaded: function eventLoaded(eventId) {
                this.loadedEventId = eventId;
                if (this.eventLoadedCallback) {
                    this.eventLoadedCallback(eventId);
                }
            },

            eventUnloaded: function eventUnloaded(eventId) {
                if (this.loadedEventId && this.loadedEventId === eventId) {
                    this.loadedEventId = undefined;
                }
            },

            momentToRFC3339: function momentToRFC3339(moment) {
                return moment.format('YYYY-MM-DDTHH:mm:ssZ');
            },

            createEventCommandResolve: function createEventCommandResolve(config) {
                var currUser = this.UsersManager.getCurrUser();
                this.trialStartForProductLeads = false;

                return this.createEventCommandWrapper(config, currUser);
            },

            createEventCommand: function createEventCommand(config) {
                if (config && config.company) {
                    this.createEventCommandResolve(config);
                } else {
                    this.CompaniesManager.getCurrCompanyPromise().finally(function () {
                        this.createEventCommandResolve(config);
                    }.bind(this));
                }
            },

            triggerUserCreatedProject: function triggerUserCreatedProject() {
                this.trigger('userCreatedProject');
            },

            createEventCommandWrapper: function createEventCommandWrapper(config, currUser) {
                const isDisabled = this.FeatureRestrictionService.checkIfBlockedAccess({
                    source: 'new_top_nav',
                    actionType: 'click'
                });
                if (isDisabled) {
                    return;
                }
                
                var promise = null;

                if (!currUser.account.pricing_plan && currUser.isSubscriptionPaused()) {
                    return this.ModalService.openPausedOfferModal();
                } else if (currUser.notSelectedNextPlanCode() && (this.paymentsValueSum > 10000)){
                    return this.PopupMessageService.showAlertPromise(this.PopupMessageService.severityTypes.info, 'SETTINGS.SUBSCRIPTION.PASSED_10K._DESCRIPTION_', 'SETTINGS.SUBSCRIPTION.PASSED_10K._CTA_', 'SETTINGS.SUBSCRIPTION.PASSED_10K._TITLE_').then(function() {
                            this.ReactModalService.openUpgradePlanModal();
                    }.bind(this));
                } else if (currUser.shouldStartTrial()) {
                    return this.OnboardingService.openInProductIntakeForm();
                } else {
                    promise = this.ModalService.openCreateProjectModal(config);
                }

                promise.then(function success(response) {
                    this.OnboardingService.updateAtwsStage();
                    this.OnboardingService.onProjectCreated();
                    this.triggerUserCreatedProject();
                    return response;
                }.bind(this))
                    .catch(function fail(error) {
                    }.bind(this))
                    .finally(function finallyHandler() {
                    }.bind(this));
                return promise;
            },

            getInitialProjectImage: function getInitialProjectImage(company) {

                // try to get the default company image if exists
                var eventImage = company.getDefaultCoverImage();

                // if no default image, pick a random one from cover images
                if (!eventImage) {
                    eventImage = this.CompanyService.getRandomCompanyCoverImage(company);
                } else {
                    // this ensures that the image is fully displayed and not cropped
                    eventImage.position_w = eventImage.width;
                }

                return eventImage;
            },

            addWorkspaceToEventCommand: function addWorkspaceToEventCommand(eventId, workspaceType, source, analyticsArgs, analyticsScope) {
                analyticsArgs.event_id = eventId;
                analyticsArgs.workspace_type = workspaceType;
                analyticsArgs.source = source;

                var promise = this.EventsManager.addWorkspaceToEvent(eventId, workspaceType);
                promise.then(
                    function (resp) {
                        analyticsArgs.workspace_id = resp.data._id;
                        this.AnalyticsService.track(analyticsScope, this.AnalyticsService.analytics_events.new_workspace_created, analyticsArgs);
                        this.WorkspaceService.gotoNewWorkspace(resp.data._id, eventId, workspaceType);

                    }.bind(this),
                    function error(resp){
                        this.AnalyticsService.track(analyticsScope, this.AnalyticsService.analytics_events.new_workspace_created_error, analyticsArgs);
                    }.bind(this)
                );

                return promise;
            },


            hbEventToMoments: function hbEventToMoments(hbEventModel) {
                if (!hbEventModel) {
                    return null;
                }

                var result = {
                    momentEventTimeStart: null,
                    momentEventTimeEnd: null,
                    wholeDayEvent: false
                };

                var end_date = hbEventModel.event_date;
                var hasEndDate = hbEventModel.event_end_date && hbEventModel.event_end_date !== hbEventModel.event_date;
                if (hasEndDate) {
                    end_date = hbEventModel.event_end_date;
                }


                if (hbEventModel.event_time_start && hbEventModel.event_time_end) {
                    //we have the start and end date (we are good to go)
                    result.momentEventTimeStart = this.moment.tz(hbEventModel.event_date + ' ' + hbEventModel.event_time_start, 'YYYY-MM-DD hh:mm A', this.TimezoneService.getMomentTimezone(hbEventModel.event_timezone));
                    result.momentEventTimeEnd = this.moment.tz(end_date + ' ' + hbEventModel.event_time_end, 'YYYY-MM-DD hh:mm A', this.TimezoneService.getMomentTimezone(hbEventModel.event_timezone));
                } else if (hbEventModel.event_time_start && !hbEventModel.event_time_end) {
                    //we only have the start time... add 4 hours and set that as the end time (this is the honeybook default)
                    result.momentEventTimeStart = this.moment.tz(hbEventModel.event_date + ' ' + hbEventModel.event_time_start, 'YYYY-MM-DD hh:mm A', this.TimezoneService.getMomentTimezone(hbEventModel.event_timezone));
                    result.momentEventTimeEnd = this.moment(result.momentEventTimeStart).add(4, 'hours');
                } else {
                    // we dont have the start and the end time... make it a whole day event
                    result.wholeDayEvent = true;
                }

                if (result.momentEventTimeStart && result.momentEventTimeEnd) {
                    if (result.momentEventTimeEnd.isBefore(result.momentEventTimeStart)) {
                        // then this means the end time is on the next day (passed midnight) and since that in our model we
                        // do not save the day also for the end time assume that the user meant that.
                        result.momentEventTimeEnd.add(1, 'day');
                    }
                }

                return result;
            },

            getDefaultProjectTypeLabel: function getDefaultProjectTypeLabel(company) {
                if (company.default_project_type_label) {
                    return company.default_project_type_label;
                }
                var label = 'Wedding';
                if (company && company.isNonEventType()) {
                    label = 'Corporate';
                }
                return label;
            },

            createDefaultProject: function createDefaultProject(company, name, type) {
                var newProject = this.ModelFactory.newEventModel();
                newProject.event_name = name || "Untitled project";
                newProject.event_image = this.getInitialProjectImage(company);
                newProject.event_type = type || company.sortedProjectTypes()[0].label;
                var timezone = this.TimezoneService.getTimezoneForCompany(company);
                newProject.event_timezone = this.TimezoneService.translateToServer(timezone);
                return newProject;
            },

            isProjectBooked: function isProjectBooked(project) {
                var isBooked = false;
                project.workspaces.forEach(function (ws) {
                    if(ws.workspace_status_type === 'client') {
                        isBooked = true;
                    }
                });
                return isBooked;
            },
        };
    });
}());
