/**
 * The hb-example-flow component
 *
 * This component shows a mocked up honeybook flow
 *
 * Parameters:
 * ---
 * vendorName:    String | Any string expression
 * companyName:   String | Any string expression
 * logoId:        String | Cloudinary Public ID ? TODO: veify why needed
 * logoImage:     String | The default/uploaded logo image
 *
 */

(function () {
    'use strict';

    class ExampleFlowController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $injector,
            _,
            $,
            $sce,
            UIUtils,
            CompaniesManager,
            CloudinaryConfigService,
            IntentService,
            $translate,
            UsersManager
         ) {
            super($scope, $injector);
            this.__objectType = 'ExampleFlowController';

            this.$ = $;
            this.$sce = $sce;
            this.UIUtils = UIUtils;
            this.CompaniesManager = CompaniesManager;
            this.CloudinaryConfigService = CloudinaryConfigService;
            this.IntentService = IntentService;
            this.$translate = $translate;  
            this.UsersManager = UsersManager;
            this.user = this.UsersManager.getCurrUser();
            this.isContractOnboarding = this.IntentService.shouldGetContractOnboarding();    
            this.buttonText = this.$translate.instant(`EXAMPLE_FILE._BUTTON_TEXT_${this.isContractOnboarding ? 'CONTRACT_' : ''}`);    

            $scope.$watch('$ctrl.brandColor', function (newBrandColor, oldBrandColor) {
                if (newBrandColor && (newBrandColor !== oldBrandColor) && this.hasUserChangedBrandColor) {
                    const buttonColor = this.getButtonColor(this.getBrandColor());
                    Object.assign(this.fileHeaderButtonStyle, this.fileHeaderButtonStyle, buttonColor);
                }
            }.bind(this));
        }

        $onInit() {
            this.cloudName = this.CloudinaryConfigService.getCloudinaryCloudName();

            // Layout work
            // Original design | File dimensions: 340w x 400h = 1:1.17647
            const width                = parseFloat(this.width || '340');
            const height               = width * 1.17647;
            const fileHeaderHeight     = .1 * height;

            const logoSize             = .1412 * width; // @width = 340px :: logoWidth = 48 x 48
            const logoMargin           = logoSize / 2;  // @width = 340px :: logoMargin = 22

            this.blankLogoPhotoId = 'app/ui/example-file/your-logo';

            const logoPhoto = this.logoId || this.blankLogoPhotoId;
            this.logoPhotoSrc = this.generateCloudinarySrc(logoPhoto);

            const inPixels = pixelCount => `${(pixelCount).toString()}px`;

            this.fileHeaderButtonStyle = {
                'padding': `${inPixels(logoMargin / 5)} ${inPixels(logoMargin / 2)}`
            }

            const buttonColor = this.getButtonColor(this.getBrandColor());
            Object.assign(this.fileHeaderButtonStyle, this.fileHeaderButtonStyle, buttonColor);

            this.logoImageStyle = {
                'background-image': `url('${this.logoPhotoSrc}')`,
            };

        }

        getButtonColor(color) {
            return {
                'color': this.UIUtils.makeBlackOrWhiteForContrast(color),
                'background-color': color,
            };
        }

        getBrandColor() {
            const isInstantBranding = this.isShowInstantBrandingColor && this.instantBrandingColor;
            return isInstantBranding ? this.instantBrandingColor : this.brandColor;
        }

        trustSrc(src) {
            return this.$sce.trustAsResourceUrl(src);
        }

        onButtonClick() {
            this.onFakeButtonClick && this.onFakeButtonClick();
        }

        generateCloudinarySrc(publicId) {
            const img = this.$.cloudinary.image(publicId, {
                cloud_name: this.cloudName,
                secure: true,
                transformation: [
                    {
                        flags: 'lossy',
                        quality: 'auto',
                        fetch_format: 'auto'
                    }
                ]
            });

            return img[0].src;
        }

    }


    Components.ExampleFlow = {
        bindings: {
            vendorName: '<',
            companyName: '<',
            logoId: '<',
            logoImage: '<',
            brandColor: '<',
            onFakeButtonClick: '&',
            hasUserChangedBrandColor: '<',
            isShowInstantBrandingColor: '<',
            instantBrandingColor: '<'
        },
        controller: ExampleFlowController,
        templateUrl: 'angular/app/modules/common/ui_components/example_flow/example_flow_component.html',
    };

}());
