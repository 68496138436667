(function () {
    'use strict';

    // @ngInject
    function NavigationServiceCtor($state, AppStates, UiPersistenceService, ModalService, PretrialersService, $window, _,
                                   Enums, AbTestService, $q, StatsigService, $injector) {

        this.$state = $state;
        this.AppStates = AppStates;
        this.UiPersistenceService = UiPersistenceService;
        this.PretrialersService = PretrialersService;
        this.$window = $window;
        this.StatsigService = StatsigService;

        this.Enums = Enums;
        this.AbTestService = AbTestService;
        this.$q = $q;
        this._ = _;
        this.$injector = $injector;

        //////////////////////////////////////////
        // THIS FLAG IS DEPRECATED! WE NEED TO
        // CLEAN UP ALL LOCATIONS THAT USES THIS
        //////////////////////////////////////////
        this.showTopNav = true;
        this._backToState = null;
    }

    Services.NavigationService = Class({
        constructor: NavigationServiceCtor,

        isRouteActive: function isRouteActive(root) {
            return this.$state.current.name && this.$state.current.name.indexOf(root) !== -1;
        },

        goHistoryBack: function goHistoryBack(fallbackState, fallbackParams) {
            if (this.$state.previous) {
                this.$window.history.back();
            } else if (fallbackState) {
                this.$state.go(fallbackState, fallbackParams);
            } else if (this.PretrialersService.shouldShowCleanAtwsUI()) {
                this.PretrialersService.gotoLastWorkspace();
            } else {
                this.$state.go(this.AppStates.root_core_navigation_home);
            }
        },

        setBackToCurrentState : function setBackToCurrentState() {
            this._backToState = { name: this.$state.current.name, params: this.$state.params };
        },

        setBackToState : function setBackToState(state) {
            this._backToState = state;
        },

        clearBackToState: function clearBackToState() {
            this.setBackToState(null);
        },

        previousStateWas: function previousStateWas(stateName) {
            return this.$state.previous.route.name === stateName;
        },

        getBackToState : function getBackToState() {
            return this._backToState;
        },

        isNavSecondLevelNoTitle: function isNavSecondLevelNoTitle() {
            return this.$state.$current.data &&
                   this.$state.$current.data.secondLevelNavNoTitle;
        },

        isNavSecondLevelWithTitle: function isNavSecondLevelWithTitle() {
            return this.$state.$current.data &&
                   this.$state.$current.data.secondLevelNavWithTitle;
        },

        isNavSinglePage: function isNavSinglePage() {
            return this.$state.$current.data &&
                   this.$state.$current.data.singlePageNav;
        },

        isWithoutNavigation: function isWithoutNavigation() {
            return this.$state.$current.data &&
                this.$state.$current.data.noNavigation;
        },

        _isShowReactTopNav: function _isShowReactTopNav(user) {
            var defer = this.$q.defer();

            if (user.isTrialStartedAfterTopNav2023RolloutDate()) {
                defer.resolve(true);
            } 
            else if (user.isAtwsPreTrialVendor()) {
                this.StatsigService.getExperimentConfigValue(
                    'new_collaborators_experience',
                    'isNewExperience', false
                )
                .then(
                     function (isNewExperience) {
                         defer.resolve(isNewExperience);
                     }
                )
                .catch(
                    function () {
                        defer.resolve(false);
                    }
                );
            }
            else {
                this.StatsigService.getExperimentConfigValue(
                    this.Enums.StatsigExperiments.topNav2023,
                    'variant', 'control_original'
                )
                    .then(
                        function (variant) {
                            if (variant === 'variant_extended') {
                                defer.resolve(true);
                            } else if (!user.shouldStartTrial()) {
                                this.StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.rollout_topnav4all, false).then(function(value) {
                                    // to read it on the react side
                                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.rolloutTopNav4all, {isEnabled: value});
                                    defer.resolve(value);
                                }.bind(this)); 
                            } else {
                                defer.resolve(false);
                            }
                        }.bind(this)
                    )
                    .catch(
                        function () {
                            defer.resolve(false);
                        }
                    );
            }

            return defer.promise;
        },

        isShowReactTopNav: function isShowReactTopNav() {
            var UsersManager = this.$injector.get('UsersManager');
            var user = UsersManager.getCurrUser();
            var defer = this.$q.defer();
            if (!user) {
                user.on('success', function () {
                    this._isShowReactTopNav(user).then(function (value) {
                        defer.resolve(value);
                    }).catch(function () {
                        defer.reject();
                    });
                }, this);
            } else {
                this._isShowReactTopNav(user).then(function (value) {
                    defer.resolve(value);
                }).catch(function () {
                    defer.reject();
                });
            }
            
            return defer.promise;        
        },

        isHideMobileNav: function isHideMobileNav() {
            return this.$state.$current.data &&
                this.$state.$current.data.hideMobileNav === true;
        },

        isTopViewShadowDisabled: function isTopViewShadowDisabled() {
            return this.$state.$current.data &&
              this.$state.$current.data.disableTopViewShadow;
        },

        isNavCurrentPeople: function isNavCurrentPeople() {
            return false;
        },
        isNavCurrentContacts: function isNavCurrentContacts() {
            return this.$state.$current.includes[this.AppStates.root_core_navigation_contacts];
        },

        isNavSettingsMobile: function isNavSettingsMobile() {
            return this.$state.$current.includes[this.AppStates.root_core_navigation_settings_mobile];
        },

        isNavSecondLevelHidden: function isNavSecondLevelHidden() {
            return this.$state.$current.data &&
                this.$state.$current.data.hideSecondLevelNavigation;
        },

        registerPlusButtonCallback: function registerPlusButtonCallback(callback) {
            this.plusButtonCallback = callback;
        },

        unregisterPlusButtonCallback: function unregisterPlusButtonCallback() {
            this.plusButtonCallback = null;
        },

        onPlusButtonClickCallback: function onPlusButtonClickCallback() {
            this._.isFunction(this.plusButtonCallback) && this.plusButtonCallback();
        }
    });
}());
